import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { useSelector, useDispatch } from "react-redux";
import {
  FORM_INTERNAL_SX,
  strings,
  _INTERNAL_WIDTH_PX,
  CATEGORY_STATUSES,
} from "../../Constants";
import {
  resetRespFavorites,
  getCategoryAsync,
  getCategoriesAsync,
  selectRespGetCategory,
  selectRespGetCategories,
  deleteCategoryAsync,
  deleteFavoriteAsync,
  selectRespDeleteCategory,
  selectRespDeleteFavorite,
  getShareCategoryRequestsAsync,
  selectRespGetShareCategoryRequests,
  putShareCategoryRequestAsync,
  selectRespPutShareCategoryRequest,
  getAcceptedCategoryRequestsAsync,
  selectRespGetAcceptedCategoryRequests,
  deleteShareCategoryRequestAsync,
  selectRespDeleteShareCategoryRequest,
  selectStatusResponse,
} from "./favoriteSlice";

import Category from "./Category";
import SharedCategory from "./SharedCategory";
import Favorite from "./Favorite";
import CategoryShareRequest from "./CategoryShareRequests";
import LocalOffer from "@mui/icons-material/LocalOffer";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Badge from "@mui/material/Badge";
import { Alert } from "@mui/material";
import Loader from "../commons/Loader";

export default function Favorites(props) {
  const dispatch = useDispatch();

  const [shareExpanded, setShareExpanded] = React.useState(false);
  const [defaultExpanded, setDefaultExpanded] = React.useState(false);
  const [alert, setAlert] = useState(false);

  const respGetCategory = useSelector(selectRespGetCategory);
  const [favoriteList, setFavoriteList] = useState([]);

  const respGetCategories = useSelector(selectRespGetCategories);
  const [categories, setCategories] = useState([]);

  const respGetShareCategoryRequests = useSelector(
    selectRespGetShareCategoryRequests
  );

  const statusResponse = useSelector(selectStatusResponse);

  const respGetAcceptedCategoryRequests = useSelector(
    selectRespGetAcceptedCategoryRequests
  );
  const respPutShareCategoryRequest = useSelector(
    selectRespPutShareCategoryRequest
  );
  const respDeleteShareCategoryRequest = useSelector(
    selectRespDeleteShareCategoryRequest
  );
  const [categoryShareRequests, setCategoryShareRequests] = useState([]);
  const [categoryAcceptedRequests, setCategoryAcceptedRequests] = useState([]);

  const respDeleteFavorite = useSelector(selectRespDeleteFavorite);

  useEffect(() => {
    dispatch(resetRespFavorites());
    dispatch(getCategoryAsync(0));
    dispatch(getCategoriesAsync());
    dispatch(getShareCategoryRequestsAsync());
    dispatch(
      getAcceptedCategoryRequestsAsync({
        statoRichiesta: CATEGORY_STATUSES.ACCEPTED,
      })
    );
  }, []);

  useEffect(() => {
    dispatch(
      getAcceptedCategoryRequestsAsync({
        statoRichiesta: CATEGORY_STATUSES.ACCEPTED,
      })
    );
  }, [respDeleteShareCategoryRequest]);

  useEffect(() => {
    console.log(
      "INSIDE USEEFFECT OF RESP-GET-SHARE-CATEGORY-REQUESTS",
      respGetShareCategoryRequests
    );
    if (respGetShareCategoryRequests?.respdata?.success) {
      setCategoryShareRequests(respGetShareCategoryRequests?.respdata?.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [respGetShareCategoryRequests]);

  useEffect(() => {
    console.log(
      "INSIDE USEEFFECT OF RESP-GET-ACCEPTED-CATEGORY-REQUESTS",
      respGetAcceptedCategoryRequests
    );
    if (respGetAcceptedCategoryRequests?.respdata?.success) {
      setCategoryAcceptedRequests(
        respGetAcceptedCategoryRequests?.respdata?.data
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [respGetAcceptedCategoryRequests]);

  useEffect(() => {
    console.log(
      "INSIDE USEEFFECT OF RESP-PUT-SHARE-CATEGORY-REQUEST",
      respPutShareCategoryRequest
    );
    dispatch(getShareCategoryRequestsAsync());
    dispatch(
      getAcceptedCategoryRequestsAsync({
        statoRichiesta: CATEGORY_STATUSES.ACCEPTED,
      })
    );
    setShareExpanded(false);
  }, [respPutShareCategoryRequest]);

  useEffect(() => {
    if (respGetCategory?.respdata?.success) {
      setFavoriteList(respGetCategory?.respdata?.data?.preferiti);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [respGetCategory]);

  useEffect(() => {
    if (respGetCategories?.respdata?.success) {
      setCategories(respGetCategories?.respdata?.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [respGetCategories]);

  useEffect(() => {
    dispatch(getCategoryAsync(0));
    setDefaultExpanded(false);
  }, [respDeleteFavorite]);

  const handleDeleteFavorite = (userId, categoryId) => {
    console.log("INSIDE HANDLE-DELETE-FAVORITE");
    dispatch(resetRespFavorites());
    const payload = {
      userId: userId,
      categoria: categoryId,
    };
    dispatch(deleteFavoriteAsync(payload));
  };

  const handleDeleteShareCategory = (shareId) => {
    dispatch(deleteShareCategoryRequestAsync(shareId));
  };

  const handleAcceptShareCategory = (shareId) => {
    const payload = {
      id_CondivisioneCategoriaPreferiti: shareId,
      statoRichiesta: CATEGORY_STATUSES.ACCEPTED,
    };
    dispatch(putShareCategoryRequestAsync(payload));
  };

  const handleRejectShareCategory = (shareId) => {
    const payload = {
      id_CondivisioneCategoriaPreferiti: shareId,
      statoRichiesta: CATEGORY_STATUSES.REJECTED,
    };
    dispatch(putShareCategoryRequestAsync(payload));
  };

  const handleShareExpand = (panel) => (event, isExpanded) => {
    setShareExpanded(isExpanded ? panel : false);
  };

  const handleDefaultExpand = (panel) => (event, isExpanded) => {
    setDefaultExpanded(isExpanded ? panel : false);
  };

  const onRemoveCategory = (id) => {
    let filteredArr = categories.filter(
      (el) => el.id_CategoriaPreferiti !== id
    );
    setCategories(filteredArr);
  };

  const onSet = (value) => {
    setAlert(value);
  };

  return (
    <div>
      <Box component='form' sx={FORM_INTERNAL_SX}>
        {statusResponse === "loading" && <Loader />}
        <Box
          className='dontUseMuiStackHere'
          sx={{ p: 1, m: "0 auto", width: _INTERNAL_WIDTH_PX }}
        >
          {alert && (
            <Alert
              style={{ maxWidth: "92%" }}
              sx={"MESSAGES2_SX"}
              severity='info'
            >
              {strings.getLanguage() === "en"
                ? "NOTICE: It is not possible to remove Categories shared with other users."
                : "ATTENZIONE: Non è possibile rimuovere Categorie in codivisione con altri utenti."}
            </Alert>
          )}
          <h2 style={{ textAlign: "center" }}>{strings.favoritesManagement}</h2>
          <br />
          <>
            {
              <Accordion
                expanded={shareExpanded}
                onChange={handleShareExpand("panel-share-category-requests")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={"panel-share-category-requests"}
                  id={"panel-share-category-requests"}
                >
                  <Grid
                    container
                    rowSpacing={2}
                    columnSpacing={{ xs: 1, sm: 2, md: 2 }}
                  >
                    <Grid item xs={1}>
                      <Badge
                        badgeContent={categoryShareRequests?.length}
                        color='primary'
                      >
                        <LocalOffer />
                      </Badge>
                    </Grid>
                    <Grid item xs={10}>
                      {strings.getLanguage() !== "en"
                        ? "RICHIESTE DI CONDIVISIONE CATEGORIE"
                        : "CATEGORIES SHARE REQUESTS"}
                    </Grid>
                    <Grid item xs={1}>
                      &nbsp;
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid
                    container
                    rowSpacing={2}
                    columnSpacing={{ xs: 1, sm: 2, md: 2 }}
                  >
                    {categoryShareRequests?.map((categoryShareRequest) => {
                      return (
                        <CategoryShareRequest
                          share={categoryShareRequest}
                          handleRejectShareCategory={handleRejectShareCategory}
                          handleAcceptShareCategory={handleAcceptShareCategory}
                        />
                      );
                    })}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            }
            <hr />
            {categories?.map((o, i) => {
              return (
                <Category
                  category={o}
                  onRemoveCategory={onRemoveCategory}
                  onSet={onSet}
                />
              );
            })}
            <hr />
            {categoryAcceptedRequests?.map((category) => {
              return (
                <SharedCategory
                  category={category}
                  handleDeleteShareCategory={handleDeleteShareCategory}
                />
              );
            })}
            <hr />
            {/*
                            <Accordion expanded={defaultExpanded} onChange={handleDefaultExpand('panel-default-category')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={'panel-default-category'}
                                    id={'panel-default-category'}
                                >
                                    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                                        <Grid item xs={1}>
                                            <Badge badgeContent={favoriteList?.length} color="primary"><LocalOffer /></Badge>
                                        </Grid>
                                        <Grid item xs={10}>
                                            PREFERITI (NESSUNA CATEGORIA)
                                        </Grid>
                                        <Grid item xs={1}>
                                            &nbsp;
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {favoriteList?.map(
                                        (o, i) => {
                                            return (
                                                <>
                                                    <Favorite favorite={o.preferito} category={0} handleDeleteFavorite={handleDeleteFavorite} />
                                                </>
                                            )
                                        }
                                    )}
                                </AccordionDetails>
                            </Accordion>
                                    */}
          </>
        </Box>
      </Box>
    </div>
  );
}
