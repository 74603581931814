
import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Person from '@mui/icons-material/Person';
import CallMade from '@mui/icons-material/CallMade';
import CallReceived from '@mui/icons-material/CallReceived';
import LocalOffer from '@mui/icons-material/LocalOffer';
import Badge from '@mui/material/Badge';

import { CATEGORY_STATUSES } from '../../Constants';

import Favorite from './Favorite';

import {
    resetRespFavorites,
    deleteFavoriteAsync,
    selectRespDeleteFavorite,
    deleteCategoryAsync,
    selectRespDeleteCategory,
    getCategoryAsync,
    selectRespGetCategory,
    getShareCategoryAsync,
    selectRespGetShareCategory
} from './favoriteSlice';


export default function Category(props) {

    const dispatch = useDispatch();

    const respGetShareCategory = useSelector(selectRespGetShareCategory);

    const [expanded, setExpanded] = React.useState(false);

    const [categoryFavorites, setCategoryFavorites] = React.useState([]);
    const [favoritesCount, setFavoritesCount] = React.useState(props.category.num_Inseriti);

    useEffect(() => {
        if (respGetShareCategory?.respdata?.success && respGetShareCategory?.respdata?.data?.categoria?.id_CategoriaPreferiti === props.category.id_CategoriaPreferiti) {
            setCategoryFavorites(respGetShareCategory?.respdata?.data?.preferiti)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [respGetShareCategory]);

    const handleExpand = (panel) => (event, isExpanded) => {
        dispatch(getShareCategoryAsync(props.category.id_CondivisioneCategoriaPreferiti))
        setExpanded(isExpanded ? panel : false);
    };

    const handleLocalDeleteShareCategory = () => {
        props.handleDeleteShareCategory(props.category.id_CondivisioneCategoriaPreferiti);
    }

    return (
        <Accordion expanded={expanded} onChange={handleExpand('panel' + props.category.id_CategoriaPreferiti)}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={'panel' + props.category.id_CategoriaPreferiti}
                id={'panel' + props.category.id_CategoriaPreferiti}
            >
                <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                    <Grid item xs={6}>
                        <LocalOffer />&nbsp;
                        {props.category.nome}
                    </Grid>
                </Grid>
                <Grid item xs={3}>
                    <Badge badgeContent={favoritesCount} color="primary"><Person /></Badge>
                </Grid>
                <Grid item xs={3}>
                    <CallReceived />
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>
                    {categoryFavorites?.map((favorite) => {
                        return (
                            <Favorite favorite={favorite.preferito} category={props.category.id_CategoriaPreferiti} canDelete={false} />
                        );
                    })}
                    <Button onClick={handleLocalDeleteShareCategory}>Cancella</Button>
                </Typography>
            </AccordionDetails>
        </Accordion>
    )
}