import React, { useEffect, useState } from "react";

import Stack from "@mui/material/Stack";
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';


import { selectLoginData } from './../welcome/welcomeSlice';
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { COMMON_SX, dpLocale, ENLISTMENTS_STATUSES, FORM_INTERNAL_SX, strings, toSQLDate } from '../../Constants';
import ResponseAlertMessages from './../commons/ResponseAlertMessages';
import { getDomainNazioniAsync, selectRespDomainNazioni } from './../domain/domainSlice';

import { selectRespGetEnlistments, selectRespGetEnlistmentRequests, selectRespPutEnlistment, putEnlistmentAsync } from "./enlistmentSlice";
import { getJobsAsync, selectRespGetJobs } from '../job/jobSlice';

export default function EnlistmentEdit(props) {

    console.log("PROPS", props);
    const { id } = useParams();
    const { status } = useParams();
    const dispatch = useDispatch();
    const loggedUser = useSelector(selectLoginData);
    const [nome, setNome] = useState(null);
    const [job, setJob] = useState(null);
    const [descrizione, setDescrizione] = useState(null);
    const [email, setEmail] = useState(null);
    const [dataInizio, setDataInizio] = useState(null);
    const [dataFine, setDataFine] = useState(null);
    const [indirizzo, setIndirizzo] = useState(null);
    const [disabled, setDisabled] = useState(true);
    const [statoIngaggio, setStatoIngaggio] = useState(-1);

    const [nazionerif1, setNazioneRif1] = useState(-1);
    const [nazionerif2, setNazioneRif2] = useState(-1);
    const [nazionerif3, setNazioneRif3] = useState(-1);
    const [regionerif1, setRegioneRif1] = useState(0);
    const [regionerif2, setRegioneRif2] = useState(0);
    const [regionerif3, setRegioneRif3] = useState(0);
    const [listaNazioni, setListaNazioni] = useState([]);

    const dominioNazioniResp = useSelector(selectRespDomainNazioni);

    const respJobs = useSelector(selectRespGetJobs);
    const respEnlistmentRequests = useSelector(selectRespGetEnlistmentRequests);
    const respEnlistments = useSelector(selectRespGetEnlistments);
    const respPutEnlistment = useSelector(selectRespPutEnlistment);

    const navigate = useNavigate();

    useEffect(() => {
        //console.log('reset respJobs')
        //dispatch(resetRespJobs());
        //resetFields();
        //console.log(loggedUser);
        if (loggedUser) {
            dispatch(getJobsAsync());
            dispatch(getDomainNazioniAsync());
        }
    }, []);

    useEffect(() => {
        if (respEnlistmentRequests?.respdata?.success) {
            //console.log(profileResponse?.respdata)
            // setDisabled(false);

            var currentEnlistmentRequest = null;
            if (status === "sent") {
                currentEnlistmentRequest = respEnlistmentRequests?.respdata?.data?.find((o) => o.id_Ingaggio === parseInt(id));
                if (currentEnlistmentRequest) {
                    // console.log('found job');
                    setJob(currentEnlistmentRequest.id_Job)
                    setNome(currentEnlistmentRequest.nome);
                    setDescrizione(currentEnlistmentRequest.descrizione);
                    setStatoIngaggio(currentEnlistmentRequest.statoIngaggio);
                    setDataInizio(currentEnlistmentRequest.dataInizio);
                    setDataFine(currentEnlistmentRequest.dataFine);
                    setEmail(currentEnlistmentRequest.email);
                    setIndirizzo(currentEnlistmentRequest.indirizzo);
                    setListaNazioni(currentEnlistmentRequest.listaNazioni);
                    if (typeof currentEnlistmentRequest.listaNazioni[0] !== 'undefined') {
                        setNazioneRif1(currentEnlistmentRequest.listaNazioni[0].id_Nazione);
                        setRegioneRif1(currentEnlistmentRequest.listaNazioni[0].id_Regione);
                    }

                    if (typeof currentEnlistmentRequest.listaNazioni[1] !== 'undefined') {
                        setNazioneRif2(currentEnlistmentRequest.listaNazioni[1].id_Nazione);
                        setRegioneRif2(currentEnlistmentRequest.listaNazioni[1].id_Regione);
                    }

                    if (typeof currentEnlistmentRequest.listaNazioni[2] !== 'undefined') {
                        setNazioneRif3(currentEnlistmentRequest.listaNazioni[2].id_Nazione);
                        setRegioneRif3(currentEnlistmentRequest.listaNazioni[2].id_Regione);
                    }
                }
            } else if (status === "accepted") {
                currentEnlistmentRequest = respEnlistments?.respdata?.data?.find((o) => o.id_Ingaggio === parseInt(id));
                if (currentEnlistmentRequest) {
                    // console.log('found job');
                    setJob(currentEnlistmentRequest.id_Job)
                    setNome(currentEnlistmentRequest.nome);
                    setDescrizione(currentEnlistmentRequest.descrizione);
                    setStatoIngaggio(currentEnlistmentRequest.statoIngaggio);
                    setDataInizio(currentEnlistmentRequest.dataInizio);
                    setDataFine(currentEnlistmentRequest.dataFine);
                    setEmail(currentEnlistmentRequest.email);
                    setIndirizzo(currentEnlistmentRequest.indirizzo);
                    setListaNazioni(currentEnlistmentRequest.listaNazioni);
                    if (typeof currentEnlistmentRequest.listaNazioni[0] !== 'undefined') {
                        setNazioneRif1(currentEnlistmentRequest.listaNazioni[0].id_Nazione);
                        setRegioneRif1(currentEnlistmentRequest.listaNazioni[0].id_Regione);
                    }

                    if (typeof currentEnlistmentRequest.listaNazioni[1] !== 'undefined') {
                        setNazioneRif2(currentEnlistmentRequest.listaNazioni[1].id_Nazione);
                        setRegioneRif2(currentEnlistmentRequest.listaNazioni[1].id_Regione);
                    }

                    if (typeof currentEnlistmentRequest.listaNazioni[2] !== 'undefined') {
                        setNazioneRif3(currentEnlistmentRequest.listaNazioni[2].id_Nazione);
                        setRegioneRif3(currentEnlistmentRequest.listaNazioni[2].id_Regione);
                    }
                }
            }
        }
    }, [respEnlistmentRequests, respJobs]);

    const acceptRequest = (e) => {
        e.preventDefault();
        console.log("INSIDE ACCEPT-REQUEST");
        let obj = {
            id_Ingaggio: parseInt(id),
            statoIngaggio: ENLISTMENTS_STATUSES.ACCEPTED,
        };
        dispatch(putEnlistmentAsync(obj));
    };

    const rejectRequest = (e) => {
        e.preventDefault();
        console.log("INSIDE REJECT-REQUEST");
        let obj = {
            id_Ingaggio: parseInt(id),
            statoIngaggio: ENLISTMENTS_STATUSES.REJECTED,
        };
        dispatch(putEnlistmentAsync(obj));
    };

    const translateSelectNation = (value) => {
        if (strings.getLanguage() === "en") {
          if (value === "Cipro") {
            return "Cyprus";
          }
          if (value === "Belgio") {
            return "Belgium";
          }
          if (value === "Croazia") {
            return "Croatia";
          }
          if (value === "Danimarca") {
            return "Denmark";
          }
          if (value === "Finlandia") {
            return "Finland";
          }
          if (value === "Francia") {
            return "France";
          }
          if (value === "Germania") {
            return "Germany";
          }
          if (value === "Grecia") {
            return "Greece";
          }
          if (value === "Irlanda") {
            return "Ireland";
          }
          if (value === "Italia") {
            return "Italy";
          }
          if (value === "Lettonia") {
            return "Latvia";
          }
          if (value === "Lituania") {
            return "Lithuania";
          }
          if (value === "Lussemburgo") {
            return "Luxembourg";
          }
          if (value === "Paesi Bassi") {
            return "Netherlands";
          }
          if (value === "Polonia") {
            return "Poland";
          }
          if (value === "Portogallo") {
            return "Portugal";
          }
          if (value === "Repubblica Ceca") {
            return "Czech Republic";
          }
          if (value === "Slovacchia") {
            return "Slovakia";
          }
          if (value === "Spagna") {
            return "Spain";
          }
          if (value === "Svezia") {
            return "Sweden";
          }
          if (value === "Ungheria") {
            return "Hungary";
          }
        }
        return value;
      };
    

    return (
        <>
            <form
                component="form"
                sx={FORM_INTERNAL_SX}
            >
                <Stack
                    className="muiStackFix"
                    spacing={2}
                    direction="column"
                    sx={COMMON_SX}
                >
                    <br />
                    <br />
                    <h2>{id === 'new' ? strings.enlistmentCreation : strings.enlistmentManagement}</h2>
                    {id !== 'new' ? <h3>{strings.enlistmentDetails} n°{id}</h3> : ""}
                    <br />
                    <ResponseAlertMessages loadResponse={respEnlistmentRequests} response={respPutEnlistment}></ResponseAlertMessages>

                    <>

{ !disabled ?
                        <TextField

                            required
                            placeholder={strings.enlistmentStatusPlaceholder}
                            disabled={disabled}
                            label={strings.enlistmentStatus}
                            variant="standard"
                            value={statoIngaggio}
                            defaultValue={statoIngaggio}
                            onChange={(event) => setStatoIngaggio(event.target.value)}
                        />
: ""}
                        <TextField

                            required
                            placeholder={strings.enlistmentTitlePlaceholder}
                            disabled={disabled}
                            label={strings.enlistmentTitle}
                            variant="standard"
                            value={nome}
                            defaultValue={nome}
                            onChange={(event) => setNome(event.target.value)}
                        />

                        <TextField

                            required
                            placeholder={strings.enlistmentDescriptionPlaceholder}
                            disabled={disabled}
                            label={strings.enlistmentDescription}
                            variant="standard"
                            value={descrizione}
                            defaultValue={descrizione}
                            onChange={(event) => setDescrizione(event.target.value)}
                        />

                        <TextField

                            required
                            placeholder={strings.enlistmentEmailPlaceholder}
                            disabled={disabled}
                            label={strings.enlistmentEmail}
                            variant="standard"
                            value={email}
                            defaultValue={email}
                            onChange={(event) => setEmail(event.target.value)}
                        />

                        <TextField

                            required
                            placeholder={strings.addressPlaceholder}
                            disabled={disabled}
                            label={strings.address}
                            variant="standard"
                            value={indirizzo}
                            defaultValue={indirizzo}
                            onChange={(event) => setIndirizzo(event.target.value)}
                        />

                        <LocalizationProvider locale={dpLocale} dateAdapter={AdapterDateFns}>
                            <Stack direction="row" spacing={2}>
                                <DatePicker
                                    disabled={disabled}
                                    value={dataInizio}
                                    label={strings.startDate}
                                    minDate={new Date()}
                                    maxDate={dataFine ? dataFine : null}
                                    inputFormat={strings.getLanguage() === 'it' ? "dd/MM/yyyy" : "MM/dd/yyyy"}
                                    onChange={(val) => setDataInizio(val)}
                                    renderInput={(params) => (
                                        <TextField variant="standard" {...params} fullWidth />
                                    )}
                                />


                                <DatePicker
                                    disabled={disabled}
                                    value={dataFine}
                                    label={strings.endDate}
                                    minDate={dataInizio ? dataInizio : new Date()}
                                    inputFormat={strings.getLanguage() === 'it' ? "dd/MM/yyyy" : "MM/dd/yyyy"}
                                    onChange={(val) => setDataFine(val)}
                                    renderInput={(params) => (
                                        <TextField variant="standard" {...params} fullWidth />
                                    )}
                                />

                            </Stack>

                        </LocalizationProvider>

                        <FormControl variant="standard" fullWidth>
                            <InputLabel id="nazionerif1-utente-label">
                                {strings.nazRif} 1
                            </InputLabel>
                            <Select
                                required
                                variant="standard"
                                disabled={disabled}
                                labelId="nazionerif1-utente-label"
                                value={nazionerif1}
                                label={strings.nazRif + " 1"}
                                onChange={(event) => setNazioneRif1(event.target.value)}
                            >
                                <MenuItem value={-1}>{strings.selectAnOption}</MenuItem>
                                {dominioNazioniResp?.respdata?.data.map(
                                    (o, i) => {
                                        return <MenuItem value={o.id_Nazione}>{translateSelectNation(o.nome_Nazione)}</MenuItem>;
                                    }
                                )}
                            </Select>
                        </FormControl>
                        {dominioNazioniResp?.respdata?.data?.find((o) => o.id_Nazione === nazionerif1)?.regioni.length > 0 ?
                            <FormControl variant="standard" fullWidth>
                                <InputLabel id="regione1-utente-label">
                                    {strings.region}
                                </InputLabel>
                                <Select
                                    required
                                    variant="standard"
                                    disabled={disabled}
                                    labelId="regione1-utente-label"
                                    value={regionerif1}
                                    label={strings.region}
                                    onChange={(event) => setRegioneRif1(event.target.value)}
                                >
                                    {dominioNazioniResp?.respdata?.data?.find((o) => o.id_Nazione === nazionerif1).regioni.map(
                                        (o, i) => {
                                            return <MenuItem value={o.id_Regione} key={i}>{o.nome_Regione}</MenuItem>;
                                        }
                                    )}
                                </Select>
                            </FormControl>
                            : ""}

                        <FormControl variant="standard" fullWidth>
                            <InputLabel id="nazionerif2-utente-label">
                                {strings.nazRif} 2
                            </InputLabel>
                            <Select
                                variant="standard"
                                disabled={disabled}
                                labelId="nazionerif2-utente-label"
                                value={nazionerif2}
                                label={strings.nazRif + " 2"}
                                onChange={(event) => setNazioneRif2(event.target.value)}
                            >
                                <MenuItem value={-1}>{strings.selectAnOption}</MenuItem>
                                {dominioNazioniResp?.respdata?.data.map(
                                    (o, i) => {
                                        return <MenuItem value={o.id_Nazione} key={i}>{translateSelectNation(o.nome_Nazione)}</MenuItem>;
                                    }
                                )}
                            </Select>
                        </FormControl>

                        {dominioNazioniResp?.respdata?.data?.find((o) => o.id_Nazione === nazionerif2)?.regioni.length > 0 ?
                            <FormControl variant="standard" fullWidth>
                                <InputLabel id="regione2-utente-label">
                                    {strings.region}
                                </InputLabel>
                                <Select
                                    required
                                    variant="standard"
                                    disabled={disabled}
                                    labelId="regione2-utente-label"
                                    value={regionerif2}
                                    label={strings.region}
                                    onChange={(event) => setRegioneRif2(event.target.value)}
                                >
                                    {dominioNazioniResp?.respdata?.data?.find((o) => o.id_Nazione === nazionerif2).regioni.map(
                                        (o, i) => {
                                            return <MenuItem value={o.id_Regione} key={i}>{o.nome_Regione}</MenuItem>;
                                        }
                                    )}
                                </Select>
                            </FormControl>
                            : ""}

                        <FormControl variant="standard" fullWidth>
                            <InputLabel id="nazionerif3-utente-label">
                                {strings.nazRif} 3
                            </InputLabel>
                            <Select
                                required
                                variant="standard"
                                disabled={disabled}
                                labelId="nazionerif3-utente-label"
                                value={nazionerif3}
                                label={strings.nazRif + " 3"}
                                onChange={(event) => setNazioneRif3(event.target.value)}
                            >
                                <MenuItem value={-1}>{strings.selectAnOption}</MenuItem>
                                {dominioNazioniResp?.respdata?.data.map(
                                    (o, i) => {
                                        return <MenuItem value={o.id_Nazione} key={i}>{translateSelectNation(o.nome_Nazione)}</MenuItem>;
                                    }
                                )}
                            </Select>
                        </FormControl>

                        {dominioNazioniResp?.respdata?.data?.find((o) => o.id_Nazione === nazionerif3)?.regioni.length > 0 ?
                            <FormControl variant="standard" fullWidth>
                                <InputLabel id="regione3-utente-label">
                                    {strings.region}
                                </InputLabel>
                                <Select
                                    required
                                    variant="standard"
                                    disabled={disabled}
                                    labelId="regione3-utente-label"
                                    value={regionerif3}
                                    label={strings.region}
                                    onChange={(event) => setRegioneRif3(event.target.value)}
                                >
                                    {dominioNazioniResp?.respdata?.data?.find((o) => o.id_Nazione === nazionerif3).regioni.map(
                                        (o, i) => {
                                            return <MenuItem value={o.id_Regione} key={i}>{o.nome_Regione}</MenuItem>;
                                        }
                                    )}
                                </Select>
                            </FormControl>
                            : ""}

                        <br />
                        <br />
                        {
                            status === 'sent' ?
                                <Button type="submit" variant="contained" onClick={acceptRequest}>
                                    ACCETTA
                                </Button>
                                : <></>
                        }

                        <Button type="submit" variant="contained" onClick={rejectRequest}>
                            RIFIUTA
                        </Button>

                    </>
                    <br />
                    <r />
                </Stack>
            </form>
        </>
    );
}