/* eslint-disable react-hooks/exhaustive-deps */
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { isEqual, merge } from 'lodash';
import React, { useEffect, useState } from "react";
import InputMask from 'react-input-mask';
import { useDispatch, useSelector } from "react-redux";
import { DISMISS_TIME_MS, strings } from '../../Constants';
import ResponseAlertMessages from '../commons/ResponseAlertMessages';
import { ajaxError, ajaxOk, selectLoginData } from "../welcome/welcomeSlice";
import { MESSAGES2_SX, FORM_INTERNAL_SX, _INTERNAL_WIDTH_PX } from '../../Constants';
import { createStripeCardTokenAsync, deleteSubscriptionAsync, getPagamentiAsync, getPagamentiKeyAsync, getSubscriptionAsync, getSubscriptionListAsync, postPagamentiAsync, postSubscriptionAsync, resetRespPostPagamenti, resetRespPostSubscription, selectRespDeleteSubscription, selectRespPagamenti, selectRespPagamentiKey, selectRespPostPagamenti, selectRespPostSubscription, selectRespStripeCardToken, selectRespSubscription, selectRespSubscriptionList } from './../subscription/subscriptionSlice';

//https://stackoverflow.com/questions/39419237/what-is-mapdispatchtoprops
//https://newbedev.com/react-hook-useeffect-has-a-missing-dependency-dispatch
export default function PaymentMethodUpdate() {

  const images = require.context('../../../public/images', true);

  const dispatch = useDispatch();
  //const stableDispatch = useCallback(dispatch, []) //assuming that it doesn't need to change

  const loggedUser = useSelector(selectLoginData);
  const respPagamentiKey = useSelector(selectRespPagamentiKey);
  const respSubscriptionList = useSelector(selectRespSubscriptionList);
  const respSubscription = useSelector(selectRespSubscription);
  const respStripeCardToken = useSelector(selectRespStripeCardToken);
  const respPostPagamenti = useSelector(selectRespPostPagamenti);
  const respPostSubscription = useSelector(selectRespPostSubscription);
  const respPagamenti = useSelector(selectRespPagamenti);
  const respDeleteSubscription = useSelector(selectRespDeleteSubscription);

  const [pk, setPk] = useState("");
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [subscription, setSubscription] = useState(null);

  const [cardNumber, setCardNumber] = useState(null);
  const [expiry, setExpiry] = useState(null);
  const [cvc, setCvc] = useState(null);

  const [paymentData, setPaymentData] = useState(null);


  useEffect(() => {
    //console.log(loggedUser);
    if (loggedUser) {
      dispatch(getPagamentiAsync());
      dispatch(getPagamentiKeyAsync());
      dispatch(getSubscriptionAsync());
      dispatch(getSubscriptionListAsync());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUser]);

  useEffect(() => {
    //console.log(respPagamentiKey);
    if (respPagamentiKey?.respdata?.success) {
      setPk(respPagamentiKey?.respdata?.data?.key);
    }
  }, [respPagamentiKey]);


  useEffect(() => {
    //console.log(respPagamenti);
    if (respPagamenti?.respdata?.success) {
      setPaymentData(respPagamenti?.respdata?.data);
    }
  }, [respPagamenti]);


  useEffect(() => {
    //console.log(respSubscriptionList);
    if (respSubscriptionList?.respdata?.success) {
      setSubscriptionList(respSubscriptionList?.respdata?.data);
    }
  }, [respSubscriptionList]);


  useEffect(() => {
    if (respStripeCardToken?.respdata?.error) {
      dispatch(ajaxError(respStripeCardToken?.respdata?.error.message));
      setTimeout(() => dispatch(ajaxOk()), DISMISS_TIME_MS);
    }
    if (respStripeCardToken?.respdata?.id) {
      dispatch(postPagamentiAsync({
        token: respStripeCardToken?.respdata?.id
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [respStripeCardToken]);



 



  useEffect(() => {
    console.log(respPostSubscription);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [respPostSubscription]);

  useEffect(() => {
    //console.log(respSubscription);
    setTimeout(() => dispatch(resetRespPostSubscription()), DISMISS_TIME_MS);
    if (respSubscription?.respdata?.success) {
      if (subscriptionList.length > 0) {
        const sub = subscriptionList.find(s => s.id_Abbonamento === respSubscription?.respdata?.data?.id_Abbonamento);
        // console.log(sub);
        if (sub !== null && sub !== undefined) {
          const mergedSub = merge({}, sub, respSubscription?.respdata?.data);
          //console.log(mergedSub,sub,respSubscription?.respdata?.data);
          setSubscription(mergedSub);
          const subscriptionList2 = [];// cloneDeep(subscriptionList);
          subscriptionList.forEach((s, i) => {
            if (s.id_Abbonamento !== sub.id_Abbonamento) {
              subscriptionList2.push(s);
            }
          });
          if (!isEqual(subscriptionList2, subscriptionList)) {
            //console.log(subscriptionList,subscriptionList2);
            setSubscriptionList(subscriptionList2);
          }
          dispatch(resetRespPostSubscription());
        }
      }

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [respSubscription, subscriptionList]);




  const deleteThenPay = () => {
    dispatch(deleteSubscriptionAsync());
  };

  useEffect(() => {
    if (respDeleteSubscription?.respdata?.success) {
      _handlePayment();
    }
  }, [respDeleteSubscription])

  const skipDelete = true;
  const handlePayment = () => {
    if (subscription === null || skipDelete) {
      _handlePayment();
    } else {
      console.log('delete');
      deleteThenPay();
    }
  };

  const _handlePayment = () => {
    if (cardNumber !== null && expiry !== null && cvc !== null) {
      dispatch(createStripeCardTokenAsync({
        pk, cardNumber: cardNumber.replaceAll(" ", "").trim(), cardExpMonth: expiry.split('/')[0].trim(), cardExpYear: expiry.split('/')[1].trim(), cardCVC: cvc
      }))
    }
  };


  return (
    <div>
      <Box
        component="form"
        sx={FORM_INTERNAL_SX}
      >
        <Box
          className="dontUseMuiStackHere"
          sx={{ p: 1, m: "0 auto", width: _INTERNAL_WIDTH_PX }}
        >
          <h2 style={{ textAlign: "center" }}>
            {strings.changepayment}</h2>
         
          
            <>
              <br />
              <br />
              <br />
              <br />
              <ResponseAlertMessages showInfoHeader={false} loadResponse={null} response={respPostPagamenti}></ResponseAlertMessages>
              <ResponseAlertMessages showInfoHeader={false} loadResponse={null} response={respPostSubscription}></ResponseAlertMessages>
              <div style={{ textAlign: "center" }}>
                {paymentData !== null ?
                  <div className="subscriptionBox">
                    <h4>{strings.usePaymentData}</h4>
                    <div style={{ display: "flex", margin: "10px", alignItems: "center", justifyContent: "center" }}>
                      <img style={{ width: "40px", height: "auto", alignSelf: "end" }} src={images(`./${paymentData.brand}.png`).default} alt={paymentData.brand} />
                      **** **** **** {paymentData.last4} {paymentData.exp_month < 10 ? '0' + paymentData.exp_month : paymentData.exp_month}/{paymentData.exp_year}
                    </div>
                  </div>
                  : ""}
                {paymentData !== null ? <h4>{strings.Or}<br /></h4> : ""}
                <h4> {strings.insertCreditCardSafePaymentViaStripe}</h4>
                <br />

                <div className="subscriptionBox inactive">
                  <InputMask
                    mask="9999 9999 9999 9999"
                    value={cardNumber ? cardNumber : ""}
                    disabled={false}
                    maskChar=" "
                    onChange={(e) => { setCardNumber(e.target.value); }}
                  >
                    {() => <TextField label={strings.cardNumber} placeholder={strings.cardNumberPlaceholder} variant="standard" />}
                  </InputMask>


                  <Stack direction="row" spacing={2}>
                    <InputMask
                      mask="99/99"
                      value={expiry ? expiry : ""}
                      disabled={false}
                      maskChar=" "
                      onChange={(e) => { setExpiry(e.target.value); }}
                    >
                      {() => <TextField placeholder={strings.expiryPlaceholder} label={strings.cardExpiryDate} variant="standard" />}
                    </InputMask>

                    <InputMask
                      mask="999"
                      value={cvc ? cvc : ""}
                      disabled={false}
                      maskChar=" "
                      onChange={(e) => { setCvc(e.target.value); }}
                    >
                      {() => <TextField placeholder={strings.cvcPlaceholder} label={strings.cardCVC} variant="standard" />}
                    </InputMask>
                  </Stack>

                </div>

                {/*
                <CreditCardInput
                  customTextLabels={{
                    invalidCardNumber: strings.invalidCardNumber,
                    expiryError: {
                      invalidExpiryDate: strings.invalidExpiryDate,
                      monthOutOfRange: strings.monthOutOfRange,
                      yearOutOfRange: strings.yearOutOfRange,
                      dateOutOfRange: strings.dateOutOfRange
                    },
                    invalidCvc: strings.invalidCvc,
                    invalidZipCode: strings.invalidZipCode,
                    cardNumberPlaceholder: strings.cardNumberPlaceholder,
                    expiryPlaceholder: strings.expiryPlaceholder,
                    cvcPlaceholder: strings.cvcPlaceholder,
                    zipPlaceholder: strings.zipPlaceholder
                  }}
                  cardNumberInputProps={{ value: cardNumber, onChange: (e) => { setCardNumber(e.target.value); } }}
                  cardExpiryInputProps={{ value: expiry, onChange: (e) => { setExpiry(e.target.value); } }}
                  cardCVCInputProps={{ value: cvc, onChange: (e) => { setCvc(e.target.value); } }}
                  fieldClassName="inputCard"
                />
                */}
                <br />

                <br />
                <Button onClick={() => { handlePayment(); }} variant="contained">{strings.changepayment}</Button>
              </div>
            </>
          
          <br />
          <br />
        </Box>
      </Box>
    </div>
  );
}
