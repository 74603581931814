import React from 'react';
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import defaultAvatar from '../../default-avatar.png';
import './AvatarUploader.scss';
import jQuery from 'jquery';
import { BASE_API_URL } from './../../Constants';


const AvatarSimple = props => {
  const [fileB64, setFileB64] = useState("");

  useEffect(() => {
    if (props.b64) {
      setFileB64(props.b64);
    }
    if (props.userId) {
      if (typeof window.myCrewTimeoutsAvatar === "undefined") {
        window.myCrewTimeoutsAvatar = new Map();
      }
      window.myCrewTimeoutsAvatar.get(props.userId) && clearTimeout(window.myCrewTimeoutsAvatar.get(props.userId));
      window.myCrewTimeoutsAvatar.set(props.userId,
        setTimeout(() => {
          jQuery
            .ajax({
              type: "GET",
              url: BASE_API_URL + "/api/Profilo/RecuperaImmagineProfilo/" + props.userId,
              // dataType: "json",
              // contentType: "application/json; charset=utf-8",
              data: {
                base64: true
              },
            })
          .done((response) => {
            setFileB64(response);
          })
          .always((error) => { });
        }, 250));
    }
  }, [props]);





  return (
    <>
      <div className="avatarUploader">
        <Button>
          {
            props.foto ?
              <img alt='avatar' src={props.foto}></img>
              :
              fileB64 !== '' ?
                <img alt='avatar' src={fileB64}></img>
                :
                <>
                  {typeof props.avatarUrl !== 'undefined' ?

                    <img alt='avatar' src={props.avatarUrl}></img>
                    :
                    <img alt='avatar' src={defaultAvatar}></img>}
                </>
          }


        </Button>

      </div>

    </>
  );
};
export default AvatarSimple;