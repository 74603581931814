import React from 'react'
import { strings } from '../../Constants'
import parse from 'html-react-parser';


export const Privacy = (props) => {

    React.useEffect(() => {
        document.body.classList.add('no-ads');
    }, []);

    React.useEffect(() => () => {
        document.body.classList.remove('no-ads')
        console.log("privacy unmount")
    }, []);


    return (
        <div className='padded-20'>
{/*             <h1>{strings.privacyPolicyTitle}</h1>
 */}            <p>{parse(strings.privacyPolicyContent)}</p>
            {/*
            <p>{parse(strings.privacyPolicy)}</p>
            */}
        </div>
    )
}
