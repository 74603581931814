import jQuery from "jquery";
import { BASE_API_URL } from "../../Constants.js";

export function getDomainElement(element) {
  return new Promise((resolve) =>
    jQuery
      .ajax({
        type: "GET",
        url: BASE_API_URL + "/api/Dominio/"+element,
        beforeSend: function (xhr) {
          xhr.setRequestHeader("Accept-Language", localStorage.getItem("activeLanguage") === "it" ? "it-IT" : "en-EN"); // Imposta il valore "it-IT" per l'intestazione "Accept-Language"
        }
      })
      .done((response) => resolve({ respdata: response }))
      .always((error) => resolve({ error }))
  );
}


