/* eslint-disable react-hooks/exhaustive-deps */
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import it from "date-fns/locale/it";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectLoginData } from "../welcome/welcomeSlice";
import {
  COMMON_SX,
  FORM_INTERNAL_SX,
  toSQLDate,
  strings,
} from "./../../Constants";
import ResponseAlertMessages from "./../commons/ResponseAlertMessages";
import {
  getDatiCittadinanzaAsync,
  putDatiCittadinanzaAsync,
  selectRespDatiCittadinanza,
  selectRespSaveDatiCittadinanza,
} from "./profileSlice";

export default function Cittadinanza(props) {
  const dispatch = useDispatch();
  const loggedUser = useSelector(selectLoginData);
  const profileResponse = useSelector(selectRespDatiCittadinanza);
  const profileSaveResponse = useSelector(selectRespSaveDatiCittadinanza);
  const [disabled, setDisabled] = useState(true);
  const [
    id_MotivoRilascioPermessoSoggiorno,
    setId_MotivoRilascioPermessoSoggiorno,
  ] = useState(-1);
  const [cittadinanzaItaliana, setCittadinanzaItaliana] = useState(false);
  const [codicePermessoSoggiorno, setCodicePermessoSoggiorno] = useState(null);
  const [identificativoPermessoSoggiorno, setIdentificativoPermessoSoggiorno] =
    useState(null);
  const [cittàRilascioPermessoSoggiorno, setCittàRilascioPermessoSoggiorno] =
    useState(null);
  const [dataScadenzaPermessoSoggiorno, setDataScadenzaPermessoSoggiorno] =
    useState(null);

    
  useEffect(() => {
    resetFields();
    //console.log(loggedUser);
    if (loggedUser) {
      dispatch(getDatiCittadinanzaAsync());
    }
  }, []);

  useEffect(() => {
    if (profileSaveResponse != null) {
      setDisabled(false);
      //console.log(profileSaveResponse);
      if (profileSaveResponse.respdata?.success) {
        props.onTabNext();

        /*
        const url = profileStepMap.get(loggedUser.workflow);
        dispatch(resetRespSaveDatiCittadinanza());
        navigate(url, {replace: true});
        console.log('navigiated', url);
        */
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileSaveResponse]);

  const resetFields = () => {
    setDisabled(true);
    setId_MotivoRilascioPermessoSoggiorno(-1);
    setCittadinanzaItaliana(false);
    setCodicePermessoSoggiorno(null);
    setIdentificativoPermessoSoggiorno(null);
    setCittàRilascioPermessoSoggiorno(null);
    setDataScadenzaPermessoSoggiorno(null);
  };

  useEffect(() => {
    if (profileResponse?.respdata?.success) {
      //console.log(profileResponse?.respdata)
      setDisabled(false);
      setId_MotivoRilascioPermessoSoggiorno(
        profileResponse?.respdata.data.id_MotivoRilascioPermessoSoggiorno
      );
      setCittadinanzaItaliana(
        profileResponse?.respdata.data.cittadinanzaItaliana
      );
      setCodicePermessoSoggiorno(
        profileResponse?.respdata.data.codicePermessoSoggiorno
      );
      setIdentificativoPermessoSoggiorno(
        profileResponse?.respdata.data.identificativoPermessoSoggiorno
      );
      setCittàRilascioPermessoSoggiorno(
        profileResponse?.respdata.data.cittàRilascioPermessoSoggiorno
      );
      setDataScadenzaPermessoSoggiorno(
        profileResponse?.respdata.data.dataScadenzaPermessoSoggiorno
      );
    }
  }, [profileResponse]);

  const onSubmit = (e) => {
    e.preventDefault();
    if (!disabled) {
      setDisabled(true);
      //console.log(dataScadenzaPermessoSoggiorno);
      let dataPermessoSQL = toSQLDate(dataScadenzaPermessoSoggiorno);

      //console.log(dataPermessoSQL);
      dispatch(
        putDatiCittadinanzaAsync({
          id_MotivoRilascioPermessoSoggiorno,
          cittadinanzaItaliana,
          codicePermessoSoggiorno,
          identificativoPermessoSoggiorno,
          cittàRilascioPermessoSoggiorno,
          dataScadenzaPermessoSoggiorno: dataPermessoSQL,
        })
      );
    }
  };

  const translateMotivationToItalian = (motivation) => {
    if (strings.getLanguage() === "it") {
      switch (motivation) {
        case "Adoption":
          return "Adozione";
        case "Foster care":
          return "Affidamento";
        case "Residence card update (change of domicile, marital status, child entry, passport change, photo update)":
          return "Aggiornamento carta di soggiorno (cambio domicilio, stato civile, passaporto, foto, inserimento figli )";
        case "Update residence permit (change of domicile, marital status, insertion of children, passport change)":
          return "Aggiornamento permesso di soggiorno (cambio domicilio, stato civile, inserimento figli, cambio passaporto)";
        case "Waiting for employment":
          return "Attesa occupazione";
        case "Waiting to regain citizenship":
          return "Attesa riacquisto cittadinanza";
        case "Political asylum renewal":
          return "Asilo politico rinnovo";
        case "EU citizens residence card":
          return "Carta di soggiorno cittadini UE";
        case "Residence card for foreigners":
          return "Carta di soggiorno per stranieri";
        case "Residence permit conversion":
          return "Conversione permesso di soggiorno";
        case "Duplicate Residence Card":
          return "Duplicato Carta di soggiorno";
        case "Duplicate Residence Permit":
          return "Duplicazione Permesso di soggiorno";
        case "Family":
          return "Famiglia";
        case "Minor family 14 - 18 years old":
          return "Famiglia minore 14 - 18 anni";
        case "Self-employment":
          return "Lavoro autonomo";
        case "Subordinate work":
          return "Lavoro subordinato";
        case "Labor - special cases provided for in Article 27 Consolidated Immigration Act":
          return "Lavoro – casi particolari previsti dall’ art. 27 Testo Unico Immigrazione";
        case "Subordinate work - seasonal":
          return "Lavoro subordinato - stagionale";
        case "Mission":
          return "Missione";
        case "Religious reasons":
          return "Motivi religiosi";
        case "Elective residence":
          return "Residenza elettiva";
        case "Scientific research":
          return "Ricerca scientifica";
        case "Stateless status renewal":
          return "Status apolide rinnovo";
        case "Study":
          return "Studio";
        case "Internship":
          return "Tirocinio";
        case "Tourism":
          return "Turismo";
        default:
          return motivation;
      }
    }
    return motivation;
};

  return (
    <div>
      <ResponseAlertMessages
        loadResponse={profileResponse}
        response={profileSaveResponse}
      ></ResponseAlertMessages>

      <br />
      {profileResponse?.respdata ? (
        <form component='form' sx={FORM_INTERNAL_SX} onSubmit={onSubmit}>
          <Stack
            className='muiStackFix'
            spacing={2}
            direction='column'
            sx={COMMON_SX}
          >
            <FormControl component='fieldset'>
              <FormLabel component='legend'>
                {strings.getLanguage() !== "en"
                  ? "Cittadinanza Italiana"
                  : "Italian citizenship"}
              </FormLabel>
              <RadioGroup
                onChange={(event) =>
                  setCittadinanzaItaliana(event.target.value === "true")
                }
                value={cittadinanzaItaliana}
                row
                aria-label='gender'
                name='row-radio-buttons-group'
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label={strings.getLanguage() !== "en" ? "Si" : "Yes"}
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label='No'
                />
              </RadioGroup>
            </FormControl>

            {!cittadinanzaItaliana ? (
              <>
                <FormControl fullWidth>
                  <InputLabel id='mr-utente-label'>
                    {strings.getLanguage() !== "en"
                      ? "Motivo rilascio permesso di soggiorno"
                      : "Reason for issue of residence permit"}
                  </InputLabel>
                  <Select
                    variant='standard'
                    disabled={disabled}
                    labelId='mr-utente-label'
                    value={id_MotivoRilascioPermessoSoggiorno}
                    label='Motivo rilascio permesso di soggiorno'
                    onChange={(event) =>
                      setId_MotivoRilascioPermessoSoggiorno(event.target.value)
                    }
                  >
                    {profileResponse?.respdata?.data?.listaMotiviRichiestaPermessoSoggiorno?.map(
                      (o, i) => {
                        return (
                          <MenuItem
                            value={o.id_MotivoRilascioPermessoSoggiorno}
                          >
                            {translateMotivationToItalian(o.motivazione)}
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                </FormControl>

                <TextField
                  disabled={disabled}
                  label={
                    strings.getLanguage() !== "en"
                      ? strings.codicePermessoSoggiorno
                      : strings.codicePermessoSoggiornoEn
                  }
                  variant='standard'
                  value={codicePermessoSoggiorno}
                  defaultValue={codicePermessoSoggiorno}
                  onChange={(event) =>
                    setCodicePermessoSoggiorno(event.target.value)
                  }
                />

                <TextField
                  disabled={disabled}
                  label={
                    strings.getLanguage() !== "en"
                      ? "Identificativo Permesso di Soggiorno"
                      : "Residence Permit ID"
                  }
                  variant='standard'
                  value={identificativoPermessoSoggiorno}
                  defaultValue={identificativoPermessoSoggiorno}
                  onChange={(event) =>
                    setIdentificativoPermessoSoggiorno(event.target.value)
                  }
                />

                <TextField
                  disabled={disabled}
                  label={
                    strings.getLanguage() !== "en"
                      ? "Città di rilascio Permesso di Soggiorno"
                      : "City issuing Residence Permit"
                  }
                  variant='standard'
                  value={cittàRilascioPermessoSoggiorno}
                  defaultValue={cittàRilascioPermessoSoggiorno}
                  onChange={(event) =>
                    setCittàRilascioPermessoSoggiorno(event.target.value)
                  }
                />

                <LocalizationProvider locale={it} dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label={
                      strings.getLanguage() !== "en"
                        ? "Data scadenza Permesso di Soggiorno"
                        : "Residence permit expiry date"
                    }
                    disabled={disabled}
                    value={dataScadenzaPermessoSoggiorno >= new Date() ? dataScadenzaPermessoSoggiorno : null}
                    minDate={new Date()}
                    inputFormat='dd/MM/yyyy'
                    onChange={(val) => setDataScadenzaPermessoSoggiorno(val)}
                    renderInput={(params) => (
                      <TextField variant='standard' {...params} fullWidth />
                    )}
                  />
                </LocalizationProvider>
              </>
            ) : (
              ""
            )}

            <Button type='submit' disabled={disabled} variant='contained'>
              {strings.getLanguage() !== "en" ? "Salva" : "Save"}
            </Button>
          </Stack>
        </form>
      ) : (
        <div className='loaderDiv'>
          <CircularProgress></CircularProgress>
        </div>
      )}
    </div>
  );
}
