import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Switch from "@mui/material/Switch";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import Moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AvatarUploader from "../commons/AvatarUploader";
import { selectLoginData } from "../welcome/welcomeSlice";
import {
  BASE_API_URL,
  COMMON_SX,
  dpLocale,
  FORM_INTERNAL_SX,
  strings,
  toSQLDate,
} from "./../../Constants";
import FileUploader from "./../commons/FileUploader";
import ResponseAlertMessages from "./../commons/ResponseAlertMessages";
import {
  getDatiProfiloAsync,
  getFotoProfiloAsync,
  postDocumentoAsync,
  postFotoProfiloAsync,
  putDatiProfiloAsync,
  selectFotoProfilo,
  selectRespDatiDocumento,
  selectRespDatiFotoProfilo,
  selectRespDatiProfilo,
  selectRespSaveDatiProfilo,
} from "./profileSlice";
import { useParams } from "react-router";
import { recuperaFotoProfilo } from "./profileAPI";

export default function Profile(props) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const loggedUser = useSelector(selectLoginData);
  const profileResponse = useSelector(selectRespDatiProfilo);
  const profileSaveResponse = useSelector(selectRespSaveDatiProfilo);
  const documentoCaricatoResponse = useSelector(selectRespDatiDocumento);
  const fotoCaricataResponse = useSelector(selectFotoProfilo);
  const fotoSalvataProfilo = useSelector(selectRespDatiFotoProfilo);
  const [disabled, setDisabled] = useState(true);
  const [tipologiaUtente, setTipologiaUtente] = useState(-1);
  const [foto, setFoto] = useState(null);
  const [fotoSize, setFotoSize] = useState(0);
  const [nome, setNome] = useState(null);
  const [cognome, setCognome] = useState(null);
  const [telefono, setTelefono] = useState();
  const [email, setEmail] = useState(null);
  const [ragioneSociale, setRagioneSociale] = useState(null);
  const [codfisciva, setCodfisciva] = useState(null);
  const [pec, setPec] = useState(null);
  const [sdi, setSdi] = useState(null);
  const [dataNascita, setDataNascita] = useState(null);
  const [invoiceAddressRequired, setInvoiceAddressRequired] = useState(true);
  const [uploadAuth, setUploadAuth] = useState(false);

  useEffect(() => {
    resetFields();
    // console.log(loggedUser);
    if (loggedUser) {
      dispatch(getDatiProfiloAsync());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFotoProfilo = async () => {
    if (loggedUser?.id_Profilo !== undefined) {
      const res = await recuperaFotoProfilo();
      console.log(res);
      if (res.respdata) {
        setFoto(res.respdata);
      }
    }
  };

  useEffect(() => {
    getFotoProfilo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foto]);

  useEffect(() => {
    if (tipologiaUtente != null && tipologiaUtente === 0) {
      setRagioneSociale(null);
      setInvoiceAddressRequired(false);
    } else {
      setInvoiceAddressRequired(true);
    }
  }, [tipologiaUtente]);

  useEffect(() => {
    if (profileSaveResponse != null) {
      setDisabled(false);
    }
  }, [profileSaveResponse]);

  useEffect(() => {
    if (profileSaveResponse != null) {
      setDisabled(false);
      // console.log(profileSaveResponse);
      if (profileSaveResponse.respdata?.success) {
        props.onTabNext();
        /*  const url = profileStepMap.get((loggedUser.workflow -1));
         if (url){
           dispatch(resetRespSaveDatiProfilo());
           navigate(url, {replace: true});
           console.log('navigiated', url);
         }*/
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileSaveResponse]);

  const resetFields = () => {
    setDisabled(true);
    setTipologiaUtente(-1);
    setNome(null);
    setCognome(null);
    setTelefono(null);
    setEmail(null);
    setRagioneSociale(null);
    setCodfisciva(null);
    setPec(null);
    setSdi(null);
    setDataNascita(null);
  };

  useEffect(() => {
    if (profileResponse?.respdata?.success) {
      console.log("PROFILO RECUPERATO", profileResponse?.respdata);

      setUploadAuth(profileResponse?.respdata.data.consensoTrattamentoImmagini);
      setUploadedDocs(profileResponse?.respdata.data.listaDocumenti);
      setDisabled(false);
      setTipologiaUtente(profileResponse.respdata.data.tipologiaUtente);
      setNome(profileResponse.respdata.data.nome);
      setCognome(profileResponse.respdata.data.cognome);
      setTelefono(profileResponse.respdata.data.telefono);
      setEmail(profileResponse.respdata.data.email);
      setCodfisciva(profileResponse.respdata.data.codiceFiscalePIVA);
      setRagioneSociale(profileResponse.respdata.data.ragioneSociale);
      setPec(
        profileResponse.respdata.data.recapitoFatturaElettronica?.split("-")[0]
      );
      setSdi(
        profileResponse.respdata.data.recapitoFatturaElettronica?.split("-")[1]
      );
      if (
        Moment(profileResponse.respdata.data.dataNascita).isBefore(
          Moment().add(-100, "years")
        )
      ) {
        setDataNascita(Moment().add(-18, "years").toISOString());
      } else {
        setDataNascita(profileResponse.respdata.data.dataNascita);
      }
    }
  }, [profileResponse]);

  console.log((pec || "").concat("-", sdi || ""));

  const onSubmit = (e) => {
    console.log(telefono);
    e.preventDefault();
    if (!disabled) {
      setDisabled(true);
      let dataNascitaSQL = toSQLDate(dataNascita);
      dispatch(
        putDatiProfiloAsync({
          tipologiaUtente,
          nome,
          cognome,
          // In futuro stabilire come gestire i vari prefissi in base alla lingua
          telefono: telefono?.includes("+39")
            ? telefono
            : "+39".concat(telefono),
          email,
          ragioneSociale,
          codiceFiscalePIVA: codfisciva,
          recapitoFatturaElettronica: (pec || "").concat("-", sdi || ""),
          dataNascita: dataNascitaSQL,
          listaDocumenti: uploadedDocs,
          consensoTrattamentoImmagini: uploadAuth,
        })
      );
    }
  };

  const [uploadedDocs, setUploadedDocs] = useState([]);

  const uploadMultipartDoc = (files, tipoDocumento) => {
    dispatch(postDocumentoAsync({ files, tipoDocumento }));
  };

  useEffect(() => {
    if (documentoCaricatoResponse?.respdata?.success) {
      let newDocs = [];
      uploadedDocs.forEach((entry) => {
        if (
          entry.tipoDocumento !==
          documentoCaricatoResponse?.respdata.data[0].tipoDocumento
        ) {
          newDocs.push(entry);
        }
      });
      newDocs.push(documentoCaricatoResponse?.respdata.data[0]);
      setUploadedDocs(newDocs);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentoCaricatoResponse]);

  const uploadMultipartFoto = (files) => {
    dispatch(postFotoProfiloAsync({ files }));
    console.log(files);
    getFotoProfilo();
  };

  useEffect(() => {
    if (fotoCaricataResponse?.respdata?.success) {
      console.log("foto caricata", fotoCaricataResponse?.respdata);
    }
  }, [fotoCaricataResponse]);

  const checkLanguage = (type) => {
    switch (type) {
      case "Patente":
        if (strings.getLanguage() !== "en") return "Patente/Tessera Sanitaria";
        return "Driving License/Health-Ins. Card";
      case "Carta di identità":
        if (strings.getLanguage() !== "en") return "Carta di Identità";
        return "Identity Card";
      case "Passaporto":
        if (strings.getLanguage() !== "en") return "Passaporto";
        return "Passport";
      default:
        return type;
    }
  };

  return (
    <div>
      <ResponseAlertMessages
        loadResponse={profileResponse}
        response={profileSaveResponse}
      ></ResponseAlertMessages>

      <br />
      {profileResponse?.respdata ? (
        <form component="form" sx={FORM_INTERNAL_SX} onSubmit={onSubmit}>
          {/*JSON.stringify(profileResponse?.respdata.data.listaTipiDocumento)*/}
          <Stack
            className="muiStackFix"
            spacing={2}
            direction="column"
            sx={COMMON_SX}
          >
            <AvatarUploader
              handleRawFiles={uploadMultipartFoto}
              handleFile={(f, size) => {
                /*   console.log('filesize', size);
                   setFoto(f);
                   setFotoSize(size);*/
              }}
              foto={foto}
              //svgCode={foto}
            ></AvatarUploader>

            <FormControl variant="standard" fullWidth>
              <InputLabel id="tipologia-utente-label">
                {strings.userType}
              </InputLabel>
              <Select
                required
                variant="standard"
                disabled={disabled}
                labelId="tipologia-utente-label"
                value={tipologiaUtente}
                label={strings.userType}
                onChange={(event) => setTipologiaUtente(event.target.value)}
              >
                {profileResponse?.respdata?.data?.listaTipologieUtente.map(
                  (o, i) => {
                    return (
                      <MenuItem key={i} value={o.chiave}>
                        {strings.getLanguage() !== "en" &&
                        o.valore === strings.personaFisica
                          ? "Persona fisica"
                          : strings.getLanguage() !== "en" &&
                            o.valore === strings.personaGiuridica
                          ? "Persona giuridica"
                          : o.valore}
                      </MenuItem>
                    );
                  }
                )}
              </Select>
            </FormControl>

            <TextField
              required
              disabled={disabled}
              label={strings.name}
              variant="standard"
              value={nome}
              defaultValue={nome}
              onChange={(event) => setNome(event.target.value)}
            />

            <TextField
              required
              disabled={disabled}
              label={strings.surname}
              variant="standard"
              value={cognome}
              defaultValue={cognome}
              onChange={(event) => setCognome(event.target.value)}
            />

            <TextField
              required
              disabled={disabled}
              inputProps={{
                inputMode: "numeric",
                pattern: "^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$",
              }}
              label={strings.phone}
              variant="standard"
              value={telefono}
              defaultValue="+39"
              onChange={(event) => setTelefono(event.target.value)}
              onKeyPress={(event) => {
                if (event.key === " ") {
                  event.preventDefault(); 
                }
              }}
            />

            <TextField
              required
              disabled={disabled}
              type="email"
              label={strings.email}
              variant="standard"
              value={email}
              defaultValue={email}
              onChange={(event) => setEmail(event.target.value)}
            />

            {tipologiaUtente === 1 ? (
              <TextField
                disabled={disabled}
                label={strings.businessName}
                variant="standard"
                value={ragioneSociale}
                defaultValue={ragioneSociale}
                onChange={(event) => setRagioneSociale(event.target.value)}
              />
            ) : (
              ""
            )}

            <TextField
              required
              disabled={disabled}
              inputProps={{
                pattern:
                  "^([A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}|[0-9]{11})$",
              }}
              label={strings.fiscalCodeOrVatNumber}
              variant="standard"
              value={codfisciva}
              defaultValue={codfisciva}
              onChange={(event) => setCodfisciva(event.target.value.toUpperCase())}
            />

            <TextField
              disabled={disabled}
              required={invoiceAddressRequired}
              label={strings.pec}
              variant="standard"
              value={pec}
              defaultValue={pec}
              onChange={(event) => setPec(event.target.value)}
            />

            <TextField
              disabled={disabled}
              required={invoiceAddressRequired}
              label={strings.sdi}
              variant="standard"
              value={sdi}
              defaultValue={sdi}
              onChange={(event) => setSdi(event.target.value)}
            />

            <LocalizationProvider
              locale={dpLocale}
              dateAdapter={AdapterDateFns}
            >
              <DatePicker
                label={strings.birthDate}
                disabled={disabled}
                required
                value={dataNascita}
                maxDate={new Date()}
                inputFormat={
                  strings.getLanguage() === "it" ? "dd/MM/yyyy" : "MM/dd/yyyy"
                }
                onChange={(val) => setDataNascita(val)}
                renderInput={(params) => (
                  <TextField variant="standard" {...params} fullWidth />
                )}
              />
            </LocalizationProvider>

            <br />
            <Alert severity="info">
              <FormControlLabel
                labelPlacement="start"
                control={
                  <Switch
                    required
                    checked={uploadAuth}
                    onChange={(event) => {
                      setUploadAuth(event.target.checked);
                    }}
                  />
                }
                label={
                  strings.getLanguage() !== "en"
                    ? strings.allowImageUsage
                    : strings.allowImageUsageEng
                }
              />
            </Alert>
            {profileResponse?.respdata?.data?.listaTipiDocumento.map((o, i) => {
              if (
                o.valore === "Foto" ||
                o.valore === "Documento Generico" ||
                o.valore === "Tessera Sanitaria"
              )
                return <></>;
              return (
                <>
                  <Stack
                    key={i}
                    className="marginTopForm"
                    direction="row"
                    justifyContent="end"
                  >
                    <span className="inputSpan justifySelfStart">
                      {checkLanguage(o.valore)}
                    </span>
                    <FileUploader
                      disabled={!uploadAuth}
                      existingFileCount={
                        uploadedDocs.filter((f) => f.tipoDocumento === o.chiave)
                          .length
                      }
                      existingFile={uploadedDocs.find((f) => {
                        //   console.log(f,o);
                        return f.tipoDocumento === o.chiave;
                      })}
                      multiple={true}
                      title="Upload"
                      handleRawFiles={(files) => {
                        uploadMultipartDoc(files, o.chiave);
                      }}
                      //required={i === 0}
                    ></FileUploader>
                  </Stack>

                  <br />
                </>
              );
            })}

            <br />

            <Button type="submit" disabled={disabled} variant="contained">
              {strings.save}
            </Button>
          </Stack>
        </form>
      ) : (
        <div className="loaderDiv">
          <CircularProgress></CircularProgress>
        </div>
      )}
    </div>
  );
}
