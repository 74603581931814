import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { cloneDeep } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getDomainLingueAsync,
  getDomainRuoliAsync,
  selectRespDomainLingue,
  selectRespDomainLivelloLingue,
  selectRespDomainRuoli,
} from "../domain/domainSlice";
import { selectLoginData } from "../welcome/welcomeSlice";
import { COMMON_SX, FORM_INTERNAL_SX, strings } from "./../../Constants";
import ResponseAlertMessages from "./../commons/ResponseAlertMessages";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";

import { getDomainLivelloLingueAsync } from "./../domain/domainSlice";
import {
  getDatiProfessionaliAsync,
  putDatiProfessionaliAsync,
  selectRespDatiProfessionali,
  selectRespSaveDatiProfessionali,
} from "./profileSlice";

export default function Professionali(props) {
  const dispatch = useDispatch();
  const loggedUser = useSelector(selectLoginData);
  const profileResponse = useSelector(selectRespDatiProfessionali);
  const profileSaveResponse = useSelector(selectRespSaveDatiProfessionali);
  const dominioRuoliResp = useSelector(selectRespDomainRuoli);
  const dominioLingueResp = useSelector(selectRespDomainLingue);
  const dominioLivelloLingueResp = useSelector(selectRespDomainLivelloLingue);
  const [disabled, setDisabled] = useState(true);
  const [listaRuoli, setListaRuoli] = useState([]);
  const [listaDipartimentiDomain, setListaDipartimentiDomain] = useState([]);
  const [listaRuoliDomain, setListaRuoliDomain] = useState([]);
  const [esperienze, setEsperienze] = useState(null);
  const [listaLingue, setListaLingue] = useState([]);
  const [waitAsync, setWaitAsync] = useState(false);

  const goDispatch = useCallback(() => {
    dispatch(getDatiProfessionaliAsync());
    dispatch(getDomainLivelloLingueAsync());
    dispatch(getDomainLingueAsync());
    dispatch(getDomainRuoliAsync()).then(() => setWaitAsync(true));
  }, [dispatch]);

  useEffect(() => {
    resetFields();
    if (loggedUser) goDispatch();
  }, [goDispatch, loggedUser]);

  useEffect(() => {
    if (listaRuoli.length === 0 && dominioRuoliResp) addRoleIfEmpty();
  });

  useEffect(() => {
    if (profileSaveResponse != null) {
      setDisabled(false);
      if (profileSaveResponse.respdata?.success) {
        props.onTabNext();
      }
    }
  }, [profileSaveResponse]);

  const resetFields = () => {
    setDisabled(true);
    setListaRuoli([]);
    setEsperienze(null);
    setListaLingue([]);
  };

  const checkAndFillDomainRuoliData = useCallback(
    (listaRuoliOptional) => {
      let listaRuoli2 = cloneDeep(listaRuoliOptional);
      if (!listaRuoliOptional) {
        listaRuoli2 = cloneDeep(listaRuoli);
      }
      const listaDipartimentiDomain2 = [];
      const listaRuoliDomain2 = [];
      //setListaRuoli(profileResponse?.respdata?.data?.listaRuoli);
      listaRuoli2.forEach((role, idxRole) => {
        const listaDipartimenti = dominioRuoliResp?.respdata?.data?.find(
          (a) => a.id_Ambito === role.id_Ambito
        )?.listaDipartimenti;
        if (listaDipartimenti) {
          listaDipartimentiDomain2.push(listaDipartimenti);
          const listaRuoli = listaDipartimenti?.find(
            (a) => a.id_Dipartimento === role.id_Dipartimento
          )?.listaRuoli;
          listaRuoliDomain2.push(listaRuoli);
        }
      });
      setListaDipartimentiDomain(listaDipartimentiDomain2);
      setListaRuoliDomain(listaRuoliDomain2);
    },
    [dominioRuoliResp?.respdata?.data, listaRuoli]
  );

  const addRoleIfEmpty = useCallback(() => {
    if (
      profileResponse?.respdata?.data?.listaRuoli?.length > 0 ||
      listaRuoli?.length > 0
    )
      return;
    const listaRuoli2 = cloneDeep(listaRuoli);
    listaRuoli2.push({
      id_RuoloProfilo: 0,
      id_Ruolo: 0,
      id_Dipartimento: 0,
      id_Ambito: 0,
    });
    setListaRuoli(listaRuoli2);
    checkAndFillDomainRuoliData();
  }, [profileResponse?.respdata, checkAndFillDomainRuoliData, listaRuoli]);

  const setItems = useCallback(() => {
    //console.log(profileResponse?.respdata)
    setDisabled(false);
    setListaRuoli(profileResponse?.respdata?.data?.listaRuoli);
    setEsperienze(profileResponse?.respdata?.data?.esperienze);
    setListaLingue(profileResponse?.respdata?.data?.listaLingue);
    checkAndFillDomainRuoliData();
    addRoleIfEmpty();
  }, [profileResponse, checkAndFillDomainRuoliData, addRoleIfEmpty]);

  useEffect(() => {
    if (
      profileResponse?.respdata?.success &&
      dominioRuoliResp?.respdata?.success &&
      dominioLingueResp?.respdata?.success &&
      dominioLivelloLingueResp?.respdata?.success
    ) {
      setItems();
    }
  }, [
    profileResponse,
    dominioRuoliResp,
    dominioLingueResp,
    dominioLivelloLingueResp,
    waitAsync,
  ]);

  const addRole = useCallback(() => {
    const listaRuoli2 = cloneDeep(listaRuoli);
    listaRuoli2.push({
      id_RuoloProfilo: 0,
      id_Ruolo: 0,
      id_Dipartimento: 0,
      id_Ambito: 0,
    });
    setListaRuoli(listaRuoli2);
    checkAndFillDomainRuoliData();
  }, [listaRuoli, setListaRuoli, checkAndFillDomainRuoliData]);

  const addLingua = useCallback(() => {
    const listaLingue2 = cloneDeep(listaLingue);
    listaLingue2.push({
      id_LinguaProfilo: 0,
      livello: 0,
    });
    setListaLingue(listaLingue2);
  }, [listaLingue, setListaLingue]);

  const clearRoleAmbito = useCallback(
    (id_RuoloProfilo) => {
      let mappedRoles = listaRuoli.map((el) => {
        if (el.id_RuoloProfilo === id_RuoloProfilo)
          return {
            ...el,
            id_Ruolo: 0,
            id_Dipartimento: 0,
            id_Ambito: 0,
          };
        return el;
      });
      setListaRuoli(mappedRoles);
      checkAndFillDomainRuoliData(mappedRoles);
    },
    [listaRuoli, setListaRuoli, checkAndFillDomainRuoliData]
  );

  const clearRoleDepartment = useCallback(
    (id_RuoloProfilo) => {
      let mappedRoles = listaRuoli.map((el) => {
        if (el.id_RuoloProfilo === id_RuoloProfilo)
          return {
            ...el,
            id_Ruolo: 0,
            id_Dipartimento: 0,
          };
        return el;
      });
      setListaRuoli(mappedRoles);
      checkAndFillDomainRuoliData(mappedRoles);
    },
    [listaRuoli, setListaRuoli, checkAndFillDomainRuoliData]
  );

  const clearRoleRole = useCallback(
    (id_RuoloProfilo) => {
      let mappedRoles = listaRuoli.map((el) => {
        if (el.id_RuoloProfilo === id_RuoloProfilo)
          return {
            ...el,
            id_Ruolo: 0,
          };
        return el;
      });
      setListaRuoli(mappedRoles);
      checkAndFillDomainRuoliData(mappedRoles);
    },
    [listaRuoli, setListaRuoli, checkAndFillDomainRuoliData]
  );

  const clearRoleBtn = useCallback(
    (id_RuoloProfilo, idxOrig) => {
      const listaRuoli2 = cloneDeep(listaRuoli);
      let idx = idxOrig;
      if (id_RuoloProfilo > 0) {
        idx = listaRuoli2.findIndex(
          (o) => o.id_RuoloProfilo === id_RuoloProfilo
        );
      }
      listaRuoli2.splice(idx, 1);
      setListaRuoli(listaRuoli2);
      checkAndFillDomainRuoliData(listaRuoli2);
    },
    [listaRuoli, setListaRuoli, checkAndFillDomainRuoliData]
  );

  const clearLanguage = useCallback(
    (id_LinguaProfilo, idxOrig) => {
      const listaLingue2 = cloneDeep(listaLingue);
      let idx = idxOrig;
      if (id_LinguaProfilo > 0) {
        idx = listaLingue2.findIndex(
          (o) => o.id_LinguaProfilo === id_LinguaProfilo
        );
      }
      listaLingue2.splice(idx, 1);
      setListaLingue(listaLingue2);
      checkAndFillDomainRuoliData(listaLingue2);
    },
    [listaLingue, setListaLingue, checkAndFillDomainRuoliData]
  );

  const modifyRoleAmbito = useCallback(
    (id_RuoloProfilo, idxOrig, value) => {
      const listaRuoli2 = cloneDeep(listaRuoli);
      let idx = idxOrig;
      if (id_RuoloProfilo > 0) {
        idx = listaRuoli2.findIndex(
          (o) => o.id_RuoloProfilo === id_RuoloProfilo
        );
      }
      listaRuoli2[idx].id_Ambito = value;
      setListaRuoli(listaRuoli2);
      checkAndFillDomainRuoliData(listaRuoli2);
    },
    [listaRuoli, setListaRuoli, checkAndFillDomainRuoliData]
  );

  const modifyRoleDipartimento = useCallback(
    (id_RuoloProfilo, idxOrig, value) => {
      const listaRuoli2 = cloneDeep(listaRuoli);
      let idx = idxOrig;
      if (id_RuoloProfilo > 0) {
        idx = listaRuoli2.findIndex(
          (o) => o.id_RuoloProfilo === id_RuoloProfilo
        );
      }
      listaRuoli2[idx].id_Dipartimento = value;
      checkAndFillDomainRuoliData(listaRuoli2);
      setListaRuoli(listaRuoli2);
    },
    [listaRuoli, setListaRuoli, checkAndFillDomainRuoliData]
  );

  const modifyRoleRuolo = useCallback(
    (id_RuoloProfilo, idxOrig, value) => {
      const listaRuoli2 = cloneDeep(listaRuoli);
      let idx = idxOrig;
      if (id_RuoloProfilo > 0) {
        idx = listaRuoli2.findIndex(
          (o) => o.id_RuoloProfilo === id_RuoloProfilo
        );
      }
      listaRuoli2[idx].id_Ruolo = value;
      setListaRuoli(listaRuoli2);
    },
    [listaRuoli, setListaRuoli]
  );

  const modifyLingua = useCallback(
    (id_LinguaProfilo, idxOrig, value) => {
      const listaLingue2 = cloneDeep(listaLingue);
      let idx = idxOrig;
      /* if (id_RuoloProfilo > 0){
       idx = listaRuoli2.findIndex(o=>o.id_RuoloProfilo===id_RuoloProfilo);
   } */
      listaLingue2[idx].id_LinguaProfilo = value;
      setListaLingue(listaLingue2);
    },
    [listaLingue, setListaLingue]
  );

  const modifyLinguaLivello = useCallback(
    (id_LinguaProfilo, idxOrig, value) => {
      const listaLingue2 = cloneDeep(listaLingue);
      let idx = idxOrig;
      /* if (id_RuoloProfilo > 0){
       idx = listaRuoli2.findIndex(o=>o.id_RuoloProfilo===id_RuoloProfilo);
   } */
      listaLingue2[idx].livello = value;
      setListaLingue(listaLingue2);
    },
    [listaLingue, setListaLingue]
  );

  const onSubmit = (e) => {
    e.preventDefault();
    if (!disabled) {
      setDisabled(true);

      dispatch(
        putDatiProfessionaliAsync({
          listaRuoli,
          listaLingue,
          esperienze,
        })
      );
    }
  };

  const translateSelectLanguage = (value) => {
    if (strings.getLanguage() === "en") {
      if (value === "Italiano") {
        return "Italian";
      }
      if (value === "Inglese") {
        return "English";
      }
      if (value === "Francese") {
        return "French";
      }
      if (value === "Tedesco") {
        return "German";
      }
      if (value === "Spagnolo") {
        return "Spanish";
      }
      if (value === "Portoghese") {
        return "Portuguese";
      }
      if (value === "Russo") {
        return "Russian";
      }
      if (value === "Giapponese") {
        return "Japanese";
      }
      if (value === "Cinese") {
        return "Chinese";
      }
      if (value === "Arabo") {
        return "Arab";
      }
    }
    return value;
  };

  const translateSelectLevel = (value) => {
    if (strings.getLanguage() === "en") {
      if (value.includes("Elementare")) {
        return "A2 - Elementary";
      }
      if (value.includes("B1")) {
        return "B1 - Intermediate";
      }
      if (value.includes("B2")) {
        return "B2 - Upper Intermediate";
      }
      if (value.includes("C1")) {
        return "C1 - Advanced";
      }
      if (value.includes("C2")) {
        return "C2 - Mastery";
      }

    }
    return value;
  };

  return (
    <div>
      <ResponseAlertMessages
        loadResponse={profileResponse}
        response={profileSaveResponse}
      />

      <br />
      {profileResponse?.respdata ? (
        <form component='form' sx={FORM_INTERNAL_SX} onSubmit={onSubmit}>
          <Stack
            className='muiStackFix'
            Lingua
            spacing={2}
            direction='column'
            sx={COMMON_SX}
          >
            {dominioRuoliResp?.respdata?.data?.length &&
              listaRuoli.length > 0 &&
              listaRuoli.map((role, idxRole) => (
                <>
                  <br />
                  <h5>{strings.getLanguage() !== "en" ? "Ruolo" : "Role"}</h5>
                  <Autocomplete
                    disabled={disabled}
                    value={dominioRuoliResp?.respdata?.data.find(
                      (a) => a?.id_Ambito === role?.id_Ambito
                    )}
                    isOptionEqualToValue={(opt, value) =>
                      value?.id_Ambito === opt?.id_Ambito
                    }
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant='outlined'
                          label={option.nome}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    options={dominioRuoliResp?.respdata?.data}
                    getOptionLabel={(option) => option.testo}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        modifyRoleAmbito(
                          role.id_RuoloProfilo,
                          idxRole,
                          newValue.id_Ambito
                        );
                      } else {
                        if (role.id_Ambito !== 0)
                          clearRoleAmbito(role.id_RuoloProfilo);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        variant='standard'
                        label={strings.scope}
                        placeholder={strings.selectAScope}
                      />
                    )}
                  />

                  {/* DA CAPIRE PERCHE QUESTA CONDIZIONE STA FALLENDO SUL REFRESH */}

                  {listaDipartimentiDomain[idxRole]?.length > 0 ? (
                    <Autocomplete
                      disabled={disabled}
                      value={listaDipartimentiDomain[idxRole].find(
                        (a) => a?.id_Dipartimento === role?.id_Dipartimento
                      )}
                      isOptionEqualToValue={(opt, value) =>
                        value?.id_Dipartimento === opt?.id_Dipartimento
                      }
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            variant='outlined'
                            label={option.nome}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      options={listaDipartimentiDomain[idxRole]}
                      getOptionLabel={(option) => option.testo}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          modifyRoleDipartimento(
                            role.id_RuoloProfilo,
                            idxRole,
                            newValue.id_Dipartimento
                          );
                        } else {
                          if (role.id_Dipartimento !== 0)
                            clearRoleDepartment(role.id_RuoloProfilo);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          variant='standard'
                          label={strings.department}
                          placeholder={strings.selectADepartment}
                        />
                      )}
                    />
                  ) : (
                    ""
                  )}

                  {listaRuoliDomain[idxRole]?.length > 0 ? (
                    <Autocomplete
                      disabled={disabled}
                      value={listaRuoliDomain[idxRole].find(
                        (a) => a?.id_Ruolo === role?.id_Ruolo
                      )}
                      isOptionEqualToValue={(opt, value) =>
                        value?.id_Ruolo === opt?.id_Ruolo
                      }
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            variant='outlined'
                            label={option.nome}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      options={listaRuoliDomain[idxRole]}
                      getOptionLabel={(option) => option.testo}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          modifyRoleRuolo(
                            role.id_RuoloProfilo,
                            idxRole,
                            newValue.id_Ruolo
                          );
                        } else {
                          if (role.id_Dipartimento !== 0)
                            clearRoleRole(role.id_RuoloProfilo);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          variant='standard'
                          label={strings.role}
                          placeholder={strings.selectARole}
                        />
                      )}
                    ></Autocomplete>
                  ) : (
                    ""
                  )}
                </>
              ))}

            {listaRuoli.length < 3 ? (
              <>
                <Button
                  onClick={addRole}
                  disabled={disabled}
                  variant='contained'
                >
                  {strings.getLanguage() !== "en"
                    ? "Aggiungi ruolo"
                    : "Add role"}
                </Button>
              </>
            ) : (
              ""
            )}
            {listaRuoli?.length > 1 && (
              <Button
                onClick={() => clearRoleBtn(0, listaRuoli.length - 1)}
                disabled={disabled}
                variant='contained'
              >
                {strings.getLanguage() !== "en"
                  ? "Elimina ruolo"
                  : "Delete role"}
              </Button>
            )}

            <>
              <h5>
                {strings.getLanguage() !== "en"
                  ? "Ultime 3 esperienze lavorative"
                  : "Last 3 work experiences "}
              </h5>
              <TextField
                required
                disabled={disabled}
                multiline
                label={
                  strings.getLanguage() !== "en"
                    ? "Descrizione ultime 3 esperienze lavorative"
                    : "Descrpition of last 3 work experiences"
                }
                variant='standard'
                value={esperienze}
                defaultValue={esperienze}
                onChange={(event) => setEsperienze(event.target.value)}
              />
            </>

            {listaLingue.length > 0 &&
              listaLingue.map((lingua, idxLingua) => (
                <>
                  <br />
                  <h5>
                    {strings.getLanguage() !== "en" ? "Lingua" : "Language"}{" "}
                    {idxLingua + 1}
                  </h5>
                  <FormControl required variant='standard' fullWidth>
                    <InputLabel id='lingua-utente-label'>{strings.language}</InputLabel>
                    <Select
                      required
                      variant='standard'
                      disabled={disabled}
                      labelId='lingua-utente-label'
                      value={lingua.id_LinguaProfilo}
                      label='Lingua'
                      onChange={(event) =>
                        modifyLingua(
                          lingua.id_LinguaProflilo,
                          idxLingua,
                          event.target.value
                        )
                      }
                    >
                      {dominioLingueResp?.respdata?.data?.map((o, i) => {
                        return (
                          <MenuItem value={o.id_LinguaProfilo}>
                            {translateSelectLanguage(o.lingua)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>

                  <FormControl required variant='standard' fullWidth>
                    <InputLabel id='liv1-utente-label'>{strings.level}</InputLabel>
                    <Select
                      required
                      variant='standard'
                      disabled={disabled}
                      labelId='liv1-utente-label'
                      value={lingua.livello}
                      label='Livello'
                      onChange={(event) =>
                        modifyLinguaLivello(
                          lingua.id_LinguaProfilo,
                          idxLingua,
                          event.target.value
                        )
                      }
                    >
                      {dominioLivelloLingueResp?.respdata?.data?.map((o, i) => {
                        return <MenuItem value={o.chiave}>{translateSelectLevel(o.valore)}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </>
              ))}

            {listaLingue.length < 3 ? (
              <Button
                onClick={addLingua}
                disabled={disabled}
                variant='contained'
              >
                {strings.getLanguage() !== "en"
                  ? "Aggiungi lingua parlata"
                  : "Add spoken language"}
              </Button>
            ) : (
              ""
            )}

            <Button type='submit' disabled={disabled} variant='contained'>
              {strings.getLanguage() !== "en" ? "Salva" : "Save"}
            </Button>

            {listaLingue?.length > 1 && (
              <Button
                onClick={() => clearLanguage(0, listaLingue.length - 1)}
                disabled={disabled}
                variant='contained'
              >
                {strings.getLanguage() !== "en" ? "Elimina" : "Delete"}
              </Button>
            )}
          </Stack>
        </form>
      ) : (
        <div className='loaderDiv'>
          <CircularProgress />
        </div>
      )}
    </div>
  );
}
