import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getEnlistments, getEnlistmentRequests, postEnlistment, putEnlistment, postInviaMailIngaggio } from './enlistmentAPI';

const initialState = {
    respInviaMailIngaggio: null,
    respGetEnlistmentRequests: null,
    respGetIngaggiInviati: null,
    respGetEnlistments: null,
    respPostEnlistment: null,
    respPutEnlistment: null,
    status: 'idle'
};

export const getEnlistmentsAsync = createAsyncThunk(
    'enlistments/get',
    async (params) => {
        const response = await getEnlistments(params);
        return response;
    }
);


export const inviaMailIngaggioAccettatoAsync = createAsyncThunk(
    'enlistments/inviaMailIngaggioAccettato',
    async (params) => {
        const response = await postInviaMailIngaggio(params);
        return response;
    }
);

export const getEnlistmentRequestsAsync = createAsyncThunk(
    'enlistments/req/get',
    async (params) => {
        const response = await getEnlistmentRequests(params);
        return response;
    }
);

export const postEnlistmentAsync = createAsyncThunk(
    'enlistments/post',
    async (params) => {
        const response = await postEnlistment(params);
        return response;
    }
);

export const putEnlistmentAsync = createAsyncThunk(
    'enlistments/put',
    async (params) => {
        const response = await putEnlistment(params);
        return response;
    }
);

export const enlistmentSlice = createSlice({
    name: 'enlistment',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
        .addCase(getEnlistmentRequestsAsync.pending, (state) => {
            state.status = 'loading';
        })
        .addCase(getEnlistmentRequestsAsync.fulfilled, (state, action) => {
            state.status = 'idle';
            state.respGetEnlistmentRequests = action.payload;
        })
        .addCase(inviaMailIngaggioAccettatoAsync.pending, (state) => {
            state.status = 'loading';
        })
        .addCase(inviaMailIngaggioAccettatoAsync.fulfilled, (state, action) => {
            state.status = 'idle';
            state.respInviaMailIngaggio = action.payload;
        })
        .addCase(getEnlistmentsAsync.pending, (state) => {
            state.status = 'loading';
        })
        .addCase(getEnlistmentsAsync.fulfilled, (state, action) => {
            state.status = 'idle';
            state.respGetEnlistments = action.payload;
        })
        .addCase(postEnlistmentAsync.pending, (state) => {
            state.status = 'loading';
        })
        .addCase(postEnlistmentAsync.fulfilled, (state, action) => {
            state.status = 'idle';
            state.respPostEnlistment = action.payload;
        })
        .addCase(putEnlistmentAsync.pending, (state) => {
            state.status = 'loading';
        })
        .addCase(putEnlistmentAsync.fulfilled, (state, action) => {
            state.status = 'idle';
            state.respPutEnlistment = action.payload;
        })
    }
});

export const selectRespInviaMailIngaggio = (state) => state.enlistment.respInviaMailIngaggio;
export const selectRespGetEnlistmentRequests = (state) => state.enlistment.respGetEnlistmentRequests;
export const selectRespGetEnlistments = (state) => state.enlistment.respGetEnlistments;
export const selectRespPostEnlistment = (state) => state.enlistment.respPostEnlistment;
export const selectRespPutEnlistment = (state) => state.enlistment.respPutEnlistment;

export default enlistmentSlice.reducer;