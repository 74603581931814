import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import Modal from "@mui/material/Modal";
import { Link } from "react-router-dom";
import { dpLocale, strings, formatDate } from "../../Constants";
import SearchResult from "./../search/SearchResult";
import ResponseAlertMessages from "../commons/ResponseAlertMessages";

import { selectRespInviaRichiestaIngaggio } from "../employment/employmentSlice";
import {
  selectRespInviaMailIngaggio,
  inviaMailIngaggioAccettatoAsync,
} from "./enlistmentSlice";

export default function Enlistment(props) {
  const [dataInizio, setDataInizio] = useState(null);

  const [dataFine, setDataFine] = useState(null);
  const [showInviaMail, setShowInviaMail] = useState(false);
  const [email, setEmail] = useState(null);

  const [openProjectEmploymentModal, setOpenProjectEmploymentModal] =
    React.useState(false);
  const handleOpenProjectEmploymentModal = () =>
    setOpenProjectEmploymentModal(true);
  const handleCloseProjectEmploymentModal = () =>
    setOpenProjectEmploymentModal(false);

  const respInviaIngaggio = useSelector(selectRespInviaRichiestaIngaggio);
  const respMailIngaggio = useSelector(selectRespInviaMailIngaggio);
  /*
  useEffect(() => {
    if (respMailIngaggio.respdata.success) {
    }
  }, [respMailIngaggio]);
*/
  const dispatch = useDispatch();

  const inviaMailRepliogativa = () => {
    dispatch(
      inviaMailIngaggioAccettatoAsync({
        email,
        idIngaggio: props.enlistment.id_Ingaggio,
      })
    );
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "clamp(300px, 50%, 500px)",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const employmentRequest = () => {
    console.log("INSIDE EMPLOYMENT-REQUEST");
  };

  return (
    <>
      {props.showUser ? (
        <Box>
          <SearchResult result={props.user} />
          <hr />
          {props.user.listaIngaggiInviati?.map((item) => {
            return (
              <>
                <span
                  style={{ display: "block" }}
                  className='genericBox clickable'
                >
                  <h4>
                    {strings.startDate}: {formatDate(item.dataInizio)}
                  </h4>
                  <h4>
                    {strings.endDate} {formatDate(item.dataFine)}
                  </h4>
                  <Button onClick={handleOpenProjectEmploymentModal}>
                    {strings.getLanguage() === "en"
                      ? strings.modifyEngagement
                      : strings.modifyEngagementIta}
                  </Button>
                </span>
                <Modal
                  open={openProjectEmploymentModal}
                  onClose={handleCloseProjectEmploymentModal}
                  aria-labelledby='modal-modal-title'
                  aria-describedby='modal-modal-description'
                >
                  <Box sx={modalStyle}>
                    <h5 style={{ marginTop: "35px" }}>
                      {strings.getLanguage() === "en"
                        ? strings.periodoEngagement
                        : strings.periodoEngagementIta}
                    </h5>
                    <LocalizationProvider
                      locale={dpLocale}
                      dateAdapter={AdapterDateFns}
                    >
                      <Stack className='StackRow' direction='row' spacing={2}>
                        <DatePicker
                          value={item.dataInizio}
                          label={strings.startDate}
                          minDate={new Date()}
                          maxDate={item.dataFine ? item.dataFine : null}
                          inputFormat={
                            strings.getLanguage() === "it"
                              ? "dd/MM/yyyy"
                              : "MM/dd/yyyy"
                          }
                          onChange={(val) => setDataInizio(val)}
                          renderInput={(params) => (
                            <TextField
                              required
                              variant='standard'
                              {...params}
                              fullWidth
                            />
                          )}
                        />

                        <DatePicker
                          value={item.dataFine}
                          label={strings.endDate}
                          minDate={
                            item.dataInizio ? item.dataInizio : new Date()
                          }
                          inputFormat={
                            strings.getLanguage() === "it"
                              ? "dd/MM/yyyy"
                              : "MM/dd/yyyy"
                          }
                          onChange={(val) => setDataFine(val)}
                          renderInput={(params) => (
                            <TextField
                              required
                              variant='standard'
                              {...params}
                              fullWidth
                            />
                          )}
                        />
                      </Stack>
                    </LocalizationProvider>
                    <br />
                    <Button
                      sx={{
                        width: { sd: "100%", md: "100%" },
                      }}
                      type='button'
                      onClick={employmentRequest}
                      variant='contained'
                    >
                      {strings.getLanguage() === "en"
                        ? strings.modifyEngagement
                        : strings.modifyEngagementIta}
                    </Button>
                    <br />
                    <br />
                    <ResponseAlertMessages
                      response={respInviaIngaggio}
                    ></ResponseAlertMessages>
                  </Box>
                </Modal>
              </>
            );
          })}
        </Box>
      ) : (
        <Link
          to={
            "/enlistments/" + props.enlistment.id_Ingaggio + "/" + props.status
          }
        >
          <span style={{ display: "block" }} className='genericBox clickable'>
            <h4>
              {strings.manageEnlistment} n°{props.enlistment.id_Ingaggio}
            </h4>
            <h4>
              {strings.title}: {props.enlistment.nome}
            </h4>
            <br />
            <div>{strings.editEnlistment}</div>
          </span>
          {/*JSON.stringify(props.enlistment)*/}
          {props.enlistment.statoIngaggio === 2 && !showInviaMail ? (
            <Button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setShowInviaMail(true);
              }}
            >
              {strings.getLanguage() === "en"
                ? strings.summaryEmail
                : strings.emailRiepilogo}
            </Button>
          ) : (
            ""
          )}
        </Link>
      )}

      {showInviaMail ? (
        <>
          <TextField
            label='Email'
            type='email'
            variant='standard'
            autoComplete='email'
            onChange={(event) => setEmail(event.target.value)}
          />
          <Button
            style={{ float: "right" }}
            variant='contained'
            onClick={(e) => {
              inviaMailRepliogativa();
            }}
          >
            {strings.getLanguage() === "en"
              ? strings.summaryEmail
              : strings.emailRiepilogo}
          </Button>
          <br />
          <br />
          <br />
          <ResponseAlertMessages
            response={respMailIngaggio}
          ></ResponseAlertMessages>
        </>
      ) : (
        ""
      )}
    </>
  );
}
