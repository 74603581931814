/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import Alert from "@mui/material/Alert";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  MESSAGES2_SX,
  COMMON_SX,
  dpLocale,
  FORM_INTERNAL_SX,
  strings,
  toSQLDate,
} from "../../Constants";
import ResponseAlertMessages from "./../commons/ResponseAlertMessages";
import {
  getDomainNazioniAsync,
  selectRespDomainNazioni,
} from "./../domain/domainSlice";
import { selectLoginData } from "./../welcome/welcomeSlice";
import Enlistment from "./../enlistment/Enlistment";
import {
  getJobIngaggiAccettatiAsync,
  getJobIngaggiInviatiAsync,
  getJobsAsync,
  postJobAsync,
  putJobAsync,
  resetRespJobs,
  selectRespGetJobs,
  selectRespIngaggiAccettati,
  selectRespIngaggiInviati,
  selectRespPostJob,
  selectRespPutJob,
} from "./jobSlice";
import { ClosedCaptionDisabledOutlined } from "@mui/icons-material";

export default function JobEdit(props) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const loggedUser = useSelector(selectLoginData);
  const respJobs = useSelector(selectRespGetJobs);
  const respPostJob = useSelector(selectRespPostJob);
  const respPutJob = useSelector(selectRespPutJob);
  const respIngaggiInviati = useSelector(selectRespIngaggiInviati);
  const respIngaggiAccettati = useSelector(selectRespIngaggiAccettati);
  const [listaIngaggiInviati, setListaIngaggiInviati] = useState([]);
  const [listaIngaggiAccettati, setListaIngaggiAccettati] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [nome, setNome] = useState(null);
  const [descrizione, setDescrizione] = useState(null);
  const [dataInizio, setDataInizio] = useState(null);
  const [dataFine, setDataFine] = useState(null);
  const [email, setEmail] = useState(null);
  const [indirizzo, setIndirizzo] = useState(null);
  const [cnt_Accettati, setCntAccettati] = useState(null);
  const [cnt_Inviati, setCntInviati] = useState(null);
  const [id_Job, setIdJob] = useState(null);
  const [listaNazioni, setListaNazioni] = useState([]);
  const dominioNazioniResp = useSelector(selectRespDomainNazioni);
  const [nazionerif1, setNazioneRif1] = useState(-1);
  const [nazionerif2, setNazioneRif2] = useState(-1);
  const [nazionerif3, setNazioneRif3] = useState(-1);
  const [regionerif1, setRegioneRif1] = useState(0);
  const [regionerif2, setRegioneRif2] = useState(0);
  const [regionerif3, setRegioneRif3] = useState(0);

  const [showEmptyAcceptedListAlert, setShowEmptyAcceptedListAlert] =
    useState(false);
  const [showEmptySentListAlert, setShowEmptySentListAlert] = useState(false);

  const [expanded, setExpanded] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    //console.log('reset respJobs')
    dispatch(resetRespJobs());
    resetFields();
    //console.log(loggedUser);
    if (loggedUser) {
      dispatch(getJobsAsync());
      dispatch(getDomainNazioniAsync());
    }
  }, []);

  useEffect(() => {
    const resp = id === "new" ? respPostJob : respPutJob;
    if (resp != null) {
      setDisabled(false);
      if (resp?.respdata?.success) {
        navigate("/jobs/", { replace: true });
      }
    }
  }, [respPutJob, respPostJob]);

  const resetFields = () => {
    setDisabled(true);
    setNome(null);
    setDescrizione(null);
    setDataFine(null);
    setDataInizio(null);
    setEmail(null);
    setIndirizzo(null);
    setListaNazioni([]);
    setNazioneRif1(-1);
    setNazioneRif2(-1);
    setNazioneRif3(-1);
    setRegioneRif1(0);
    setRegioneRif2(0);
    setRegioneRif3(0);
    setListaIngaggiAccettati([]);
    setListaIngaggiInviati([]);
  };

  useEffect(() => {
    if (respJobs?.respdata?.success) {
      setDisabled(false);

      if (id === "new") {
      } else {
        //  console.log('finding job', id, respJobs?.respdata?.data);
        var job = respJobs?.respdata?.data?.find(
          (o) => o.id_Job === parseInt(id)
        );
        if (job) {
          // console.log('found job');
          setNome(job.nome);
          setDescrizione(job.descrizione);
          setDataInizio(job.dataInizio);
          setDataFine(job.dataFine);
          setEmail(job.email);
          setIndirizzo(job.indirizzo);
          setListaNazioni(job.listaNazioni);
          setIdJob(job.id_Job);
          setCntAccettati(job.cnt_Accettati);
          setCntInviati(job.cnt_Inviati);
          if (typeof job.listaNazioni[0] !== "undefined") {
            setNazioneRif1(job.listaNazioni[0].id_Nazione);
            setRegioneRif1(job.listaNazioni[0].id_Regione);
          }

          if (typeof job.listaNazioni[1] !== "undefined") {
            setNazioneRif2(job.listaNazioni[1].id_Nazione);
            setRegioneRif2(job.listaNazioni[1].id_Regione);
          }

          if (typeof job.listaNazioni[2] !== "undefined") {
            setNazioneRif3(job.listaNazioni[2].id_Nazione);
            setRegioneRif3(job.listaNazioni[2].id_Regione);
          }
        }
      }
    }
  }, [respJobs]);

  useEffect(() => {
    if (respIngaggiAccettati?.respdata?.success) {
      setListaIngaggiAccettati(respIngaggiAccettati?.respdata?.data?.ingaggi);
      if (respIngaggiAccettati?.respdata?.data?.ingaggi?.length > 0) {
        setShowEmptyAcceptedListAlert(false);
      } else {
        setShowEmptyAcceptedListAlert(true);
      }
    }
  }, [respIngaggiAccettati]);

  console.log(respIngaggiAccettati);

  useEffect(() => {
    if (respIngaggiInviati?.respdata?.success) {
      setListaIngaggiInviati(respIngaggiInviati?.respdata?.data?.ingaggi);
      if (respIngaggiInviati?.respdata?.data?.ingaggi?.length > 0) {
        setShowEmptySentListAlert(false);
      } else {
        setShowEmptySentListAlert(true);
      }
    }
  }, [respIngaggiInviati]);

  const loadEnlistments = (panel) => (event, isExpanded) => {
    if (isExpanded) {
      dispatch(
        getJobIngaggiInviatiAsync({
          job: parseInt(id),
          page: 0,
          per_page: 1000,
        })
      );
      dispatch(
        getJobIngaggiAccettatiAsync({
          job: parseInt(id),
          page: 0,
          per_page: 1000,
        })
      );
    }
    setExpanded(isExpanded ? panel : false);
  };

  const [nazRegError, setNazRegError] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    if (nazionerif1 === -1 || (nazionerif1 === 21 && regionerif1 === 0)) {
      setNazRegError(true);
      return;
    }
    setNazRegError(false);
    if (!disabled) {
      setDisabled(true);
      let dataInizioSQL = toSQLDate(dataInizio);
      let dataFineSQL = toSQLDate(dataFine);

      const listaNazioni2 = [];

      listaNazioni2.push({ id_Nazione: nazionerif1, id_Regione: regionerif1 });
      if (nazionerif2 !== -1) {
        listaNazioni2.push({
          id_Nazione: nazionerif2,
          id_Regione: regionerif2,
        });
      }
      if (nazionerif3 !== -1) {
        listaNazioni2.push({
          id_Nazione: nazionerif3,
          id_Regione: regionerif3,
        });
      } // console.log(username,password,email);

      const asyncFunct = id === "new" ? postJobAsync : putJobAsync;
      dispatch(
        asyncFunct({
          id_Job,
          cnt_Accettati,
          cnt_Inviati,
          nome,
          descrizione,
          dataInizio: dataInizioSQL,
          dataFine: dataFineSQL,
          email,
          indirizzo,
          listaNazioni: listaNazioni2,
        })
      );
    }
  };

  const resetForm = () => {
    setNome("");
    setDescrizione("");
    setDataFine("");
    setDataInizio("");
    setEmail("");
    setIndirizzo("");
    setListaNazioni([]);
    setNazioneRif1(-1);
    setNazioneRif2(-1);
    setNazioneRif3(-1);
    setRegioneRif1(0);
    setRegioneRif2(0);
    setRegioneRif3(0);
  };

  const translateSelectNation = (value) => {
    if (strings.getLanguage() === "en") {
      if (value === "Cipro") {
        return "Cyprus";
      }
      if (value === "Belgio") {
        return "Belgium";
      }
      if (value === "Croazia") {
        return "Croatia";
      }
      if (value === "Danimarca") {
        return "Denmark";
      }
      if (value === "Finlandia") {
        return "Finland";
      }
      if (value === "Francia") {
        return "France";
      }
      if (value === "Germania") {
        return "Germany";
      }
      if (value === "Grecia") {
        return "Greece";
      }
      if (value === "Irlanda") {
        return "Ireland";
      }
      if (value === "Italia") {
        return "Italy";
      }
      if (value === "Lettonia") {
        return "Latvia";
      }
      if (value === "Lituania") {
        return "Lithuania";
      }
      if (value === "Lussemburgo") {
        return "Luxembourg";
      }
      if (value === "Paesi Bassi") {
        return "Netherlands";
      }
      if (value === "Polonia") {
        return "Poland";
      }
      if (value === "Portogallo") {
        return "Portugal";
      }
      if (value === "Repubblica Ceca") {
        return "Czech Republic";
      }
      if (value === "Slovacchia") {
        return "Slovakia";
      }
      if (value === "Spagna") {
        return "Spain";
      }
      if (value === "Svezia") {
        return "Sweden";
      }
      if (value === "Ungheria") {
        return "Hungary";
      }
    }
    return value;
  };

  return (
    <>
      <form component='form' sx={FORM_INTERNAL_SX} onSubmit={onSubmit}>
        <Stack
          className='muiStackFix'
          spacing={2}
          direction='column'
          sx={COMMON_SX}
        >
          <br />
          <br />
          <h2>{id === "new" ? strings.jobCreation : strings.jobManagment}</h2>
          {id !== "new" ? (
            <h3>
              {strings.jobDetails} n°{id}
            </h3>
          ) : (
            ""
          )}
          <br />
          <ResponseAlertMessages
            loadResponse={respJobs}
            response={id === "new" ? respPostJob : respPutJob}
          ></ResponseAlertMessages>
          {nazRegError && (
            <Alert sx={MESSAGES2_SX} severity='error'>
              {
                "Impossibile proseguire senza inserire una Nazione o una Regione"
              }
            </Alert>
          )}

          {nome || id === "new" ? (
            <>
              <TextField
                required
                placeholder={strings.jobTitlePlaceholder}
                disabled={disabled}
                label={strings.jobTitle}
                variant='standard'
                value={nome}
                defaultValue={nome}
                onChange={(event) => setNome(event.target.value)}
              />

              <TextField
                required
                placeholder={strings.jobDescrPlaceholder}
                disabled={disabled}
                label={strings.jobDescr}
                variant='standard'
                value={descrizione}
                defaultValue={descrizione}
                onChange={(event) => setDescrizione(event.target.value)}
              />

              <TextField
                required
                placeholder={strings.addressPlaceholder}
                disabled={disabled}
                label={strings.address}
                variant='standard'
                value={indirizzo}
                defaultValue={indirizzo}
                onChange={(event) => setIndirizzo(event.target.value)}
              />

              <TextField
                required
                placeholder={strings.emailPlaceholder}
                disabled={disabled}
                label={strings.email}
                variant='standard'
                value={email}
                defaultValue={email}
                onChange={(event) => setEmail(event.target.value)}
              />

              <FormControl variant='standard' fullWidth>
                <InputLabel id='nazionerif1-utente-label'>
                  {strings.nazRif} 1
                </InputLabel>
                <Select
                  required
                  variant='standard'
                  disabled={disabled}
                  labelId='nazionerif1-utente-label'
                  value={nazionerif1}
                  label={strings.nazRif + " 1"}
                  onChange={(event) => setNazioneRif1(event.target.value)}
                >
                  <MenuItem value={-1}>{strings.selectAnOption}</MenuItem>
                  {dominioNazioniResp?.respdata?.data.map((o, i) => {
                    return (
                      <MenuItem value={o.id_Nazione}>{translateSelectNation(o.nome_Nazione)}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              {dominioNazioniResp?.respdata?.data?.find(
                (o) => o.id_Nazione === nazionerif1
              )?.regioni.length > 0 ? (
                <FormControl variant='standard' fullWidth>
                  <InputLabel id='regione1-utente-label'>
                    {strings.region}
                  </InputLabel>
                  <Select
                    variant='standard'
                    disabled={disabled}
                    labelId='regione1-utente-label'
                    value={regionerif1}
                    label={strings.region}
                    onChange={(event) => setRegioneRif1(event.target.value)}
                  >
                    {dominioNazioniResp?.respdata?.data
                      ?.find((o) => o.id_Nazione === nazionerif1)
                      .regioni.map((o, i) => {
                        return (
                          <MenuItem value={o.id_Regione}>
                            {o.nome_Regione}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              ) : (
                ""
              )}

              <FormControl variant='standard' fullWidth>
                <InputLabel id='nazionerif2-utente-label'>
                  {strings.nazRif} 2
                </InputLabel>
                <Select
                  variant='standard'
                  disabled={disabled}
                  labelId='nazionerif2-utente-label'
                  value={nazionerif2}
                  label={strings.nazRif + " 2"}
                  onChange={(event) => setNazioneRif2(event.target.value)}
                >
                  <MenuItem value={-1}>{strings.selectAnOption}</MenuItem>
                  {dominioNazioniResp?.respdata?.data.map((o, i) => {
                    return (
                      <MenuItem value={o.id_Nazione}>{translateSelectNation(o.nome_Nazione)}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              {dominioNazioniResp?.respdata?.data?.find(
                (o) => o.id_Nazione === nazionerif2
              )?.regioni.length > 0 ? (
                <FormControl variant='standard' fullWidth>
                  <InputLabel id='regione2-utente-label'>
                    {strings.region}
                  </InputLabel>
                  <Select
                    variant='standard'
                    disabled={disabled}
                    labelId='regione2-utente-label'
                    value={regionerif2}
                    label={strings.region}
                    onChange={(event) => setRegioneRif2(event.target.value)}
                  >
                    {dominioNazioniResp?.respdata?.data
                      ?.find((o) => o.id_Nazione === nazionerif2)
                      .regioni.map((o, i) => {
                        return (
                          <MenuItem value={o.id_Regione}>
                            {o.nome_Regione}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              ) : (
                ""
              )}

              <FormControl variant='standard' fullWidth>
                <InputLabel id='nazionerif3-utente-label'>
                  {strings.nazRif} 3
                </InputLabel>
                <Select
                  variant='standard'
                  disabled={disabled}
                  labelId='nazionerif3-utente-label'
                  value={nazionerif3}
                  label={strings.nazRif + " 3"}
                  onChange={(event) => setNazioneRif3(event.target.value)}
                >
                  <MenuItem value={-1}>{strings.selectAnOption}</MenuItem>
                  {dominioNazioniResp?.respdata?.data.map((o, i) => {
                    return (
                      <MenuItem value={o.id_Nazione}>{translateSelectNation(o.nome_Nazione)}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              {dominioNazioniResp?.respdata?.data?.find(
                (o) => o.id_Nazione === nazionerif3
              )?.regioni.length > 0 ? (
                <FormControl variant='standard' fullWidth>
                  <InputLabel id='regione3-utente-label'>
                    {strings.region}
                  </InputLabel>
                  <Select
                    variant='standard'
                    disabled={disabled}
                    labelId='regione3-utente-label'
                    value={regionerif3}
                    label={strings.region}
                    onChange={(event) => setRegioneRif3(event.target.value)}
                  >
                    {dominioNazioniResp?.respdata?.data
                      ?.find((o) => o.id_Nazione === nazionerif3)
                      .regioni.map((o, i) => {
                        return (
                          <MenuItem value={o.id_Regione}>
                            {o.nome_Regione}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              ) : (
                ""
              )}

              <LocalizationProvider
                locale={dpLocale}
                dateAdapter={AdapterDateFns}
              >
                <Stack direction='row' spacing={2}>
                  <DatePicker
                    disabled={disabled}
                    value={dataInizio}
                    label={strings.startDate}
                    minDate={new Date()}
                    maxDate={dataFine ? dataFine : null}
                    inputFormat={
                      strings.getLanguage() === "it"
                        ? "dd/MM/yyyy"
                        : "MM/dd/yyyy"
                    }
                    onChange={(val) => setDataInizio(val)}
                    renderInput={(params) => (
                      <TextField variant='standard' {...params} fullWidth />
                    )}
                  />

                  <DatePicker
                    disabled={disabled}
                    value={dataFine}
                    label={strings.endDate}
                    minDate={dataInizio ? dataInizio : new Date()}
                    inputFormat={
                      strings.getLanguage() === "it"
                        ? "dd/MM/yyyy"
                        : "MM/dd/yyyy"
                    }
                    onChange={(val) => setDataFine(val)}
                    renderInput={(params) => (
                      <TextField variant='standard' {...params} fullWidth />
                    )}
                  />
                </Stack>
              </LocalizationProvider>
              {id !== "new" ? (
                <>
                  <br />
                  <Accordion
                    expanded={expanded}
                    onChange={loadEnlistments("panel-ingaggi")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={"panel-ingaggi"}
                      id={"panel-ingaggi"}
                    >
                      <h3>{strings.situaIngaggi}</h3>
                    </AccordionSummary>
                    <AccordionDetails>
                      <h3 className='colorGray'>{strings.ingaggiAccettati}</h3>
                      {showEmptyAcceptedListAlert === false ? (
                        <></>
                      ) : (
                        <Alert
                          sx={MESSAGES2_SX}
                          severity='info'
                          style={{ width: "95%" }}
                        >
                          <h3 className='colorGray'>{strings.noEngagement}</h3>
                        </Alert>
                      )}
                      {listaIngaggiAccettati.map((o, i) => {
                        return (
                          <Enlistment user={o} i={i + 1} showUser={true} />
                        );
                      })}
                      <hr />
                      <h3 className='colorGray'>{strings.engagementsSent}</h3>
                      {showEmptySentListAlert === false ? (
                        <></>
                      ) : (
                        <Alert
                          sx={MESSAGES2_SX}
                          severity='info'
                          style={{ width: "95%" }}
                        >
                          <h3 className='colorGray'>
                            {strings.noEngagementSent}
                          </h3>
                        </Alert>
                      )}
                      {listaIngaggiInviati.map((o, i) => {
                        return (
                          <Enlistment user={o} i={i + 1} showUser={true} />
                        );
                        // return <IngaggioJob ingaggio={o}></IngaggioJob>
                      })}
                    </AccordionDetails>
                  </Accordion>
                </>
              ) : (
                ""
              )}
              <br />
              <br />
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  style={{ width: "45%" }}
                  type='submit'
                  disabled={disabled}
                  variant='contained'
                >
                  {strings.save}
                </Button>
                <Button
                  style={{ width: "45%" }}
                  onClick={() => resetForm()}
                  variant='outlined'
                >
                  Reset
                </Button>
              </div>
            </>
          ) : (
            <div className='loaderDiv'>
              <CircularProgress></CircularProgress>
            </div>
          )}

          <br />
          <br />
        </Stack>
      </form>
    </>
  );
}
