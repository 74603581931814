import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectLoginData } from "../welcome/welcomeSlice";
import { COMMON_SX, FORM_INTERNAL_SX, strings } from "./../../Constants";
import ResponseAlertMessages from "./../commons/ResponseAlertMessages";
import {
  getDatiPersonaliGuiridiciAsync,
  putDatiPersonaliGuiridiciAsync,
  selectRespDatiPersonaliGiuridici,
  selectRespSaveDatiPersonaliGiuridici,
} from "./profileSlice";

export default function DatiPersonaliGuiridici(props) {
  const dispatch = useDispatch();
  const loggedUser = useSelector(selectLoginData);
  const profileResponse = useSelector(selectRespDatiPersonaliGiuridici);
  const profileSaveResponse = useSelector(selectRespSaveDatiPersonaliGiuridici);
  const [disabled, setDisabled] = useState(true);
  const [cittaNascita, setCittaNascita] = useState(null);
  const [provinciaNascita, setProvinciaNascita] = useState(null);
  const [cittaResidenza, setCittaResidenza] = useState(null);
  const [indirizzoResidenza, setIndirizzoResidenza] = useState(null);
  const [provinciaResidenza, setProvinciaResidenza] = useState(null);
  const [capResidenza, setCapResidenza] = useState(null);
  const [cittaDomicilio, setCittaDomicilio] = useState(null);
  const [indirizzoDomicilio, setIndirizzoDomicilio] = useState(null);
  const [provinciaDomicilio, setProvinciaDomicilio] = useState(null);
  const [capDomicilio, setCapDomicilio] = useState(null);
  const [homeAddressSwitchChecked, setHomeAddressSwitchChecked] =
    useState(false);

  useEffect(() => {
    resetFields();
    //console.log(loggedUser);
    if (loggedUser) {
      dispatch(getDatiPersonaliGuiridiciAsync());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (profileSaveResponse != null) {
      setDisabled(false);
      //console.log(profileSaveResponse);
      if (profileSaveResponse.respdata?.success) {
        props.onTabNext();
        /*
        const url = profileStepMap.get(loggedUser.workflow);
        dispatch(resetRespSaveDatiPersonaliGiuridici());
        navigate(url, {replace: true});
        console.log('nprofileStepMapavigiated', url);*/
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileSaveResponse]);

  const resetFields = () => {
    setDisabled(true);
    setCittaNascita(null);
    setProvinciaNascita(null);
    setCittaResidenza(null);
    setIndirizzoResidenza(null);
    setProvinciaResidenza(null);
    setCapResidenza(null);
    setCittaDomicilio(null);
    setIndirizzoDomicilio(null);
    setCapDomicilio(null);
    setProvinciaDomicilio(null);
  };

  useEffect(() => {
    if (profileResponse?.respdata?.success) {
      //console.log(profileResponse?.respdata)
      setDisabled(false);
      setCittaNascita(profileResponse?.respdata.data.cittaNascita);
      setProvinciaNascita(profileResponse?.respdata.data.provinciaNascita);
      setCittaResidenza(profileResponse?.respdata.data.cittaResidenza);
      setIndirizzoResidenza(profileResponse?.respdata.data.indirizzoResidenza);
      setProvinciaResidenza(profileResponse?.respdata.data.provinciaResidenza);
      setCapResidenza(profileResponse?.respdata.data.capResidenza);
      setCittaDomicilio(profileResponse?.respdata.data.cittaDomicilio);
      setIndirizzoDomicilio(profileResponse?.respdata.data.indirizzoDomicilio);
      setProvinciaDomicilio(profileResponse?.respdata.data.provinciaDomicilio);
      setCapDomicilio(profileResponse?.respdata.data.capDomicilio);
    }
  }, [profileResponse]);

  useEffect(() => {
    if (homeAddressSwitchChecked === true) {
      setCittaDomicilio(cittaResidenza);
      setIndirizzoDomicilio(indirizzoResidenza);
      setProvinciaDomicilio(provinciaResidenza);
      setCapDomicilio(capResidenza);
    }
  }, [homeAddressSwitchChecked]);

  const handleHomeAddressSwitch = (event) => {
    event.preventDefault();
    setHomeAddressSwitchChecked(
      homeAddressSwitchChecked === false ? true : false
    );
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!disabled) {
      setDisabled(true);

      dispatch(
        putDatiPersonaliGuiridiciAsync({
          cittaNascita,
          provinciaNascita,
          cittaResidenza,
          indirizzoResidenza,
          provinciaResidenza,
          capResidenza,
          cittaDomicilio,
          indirizzoDomicilio,
          provinciaDomicilio,
          capDomicilio,
        })
      );
    }
  };

  const sliceProvinciaTo2Char = (input) => {
    return input.slice(0, 2).toUpperCase();
  };

  return (
    <div>
      <ResponseAlertMessages
        loadResponse={profileResponse}
        response={profileSaveResponse}
      ></ResponseAlertMessages>

      <br />
      {profileResponse?.respdata ? (
        <form component='form' sx={FORM_INTERNAL_SX} onSubmit={onSubmit}>
          <Stack
            className='muiStackFix'
            spacing={2}
            direction='column'
            sx={COMMON_SX}
          >
            <h6>{strings.birthPlace}</h6>

            <TextField
              disabled={disabled}
              label={strings.city}
              variant='standard'
              value={cittaNascita}
              defaultValue={cittaNascita}
              onChange={(event) => setCittaNascita(event.target.value)}
            />

            <TextField
              style={{ width: "50%" }}
              disabled={disabled}
              label={strings.district}
              variant='standard'
              value={provinciaNascita}
              defaultValue={provinciaNascita}
              onChange={(event) =>
                setProvinciaNascita(sliceProvinciaTo2Char(event.target.value))
              }
            />

            <h6>{strings.residence}</h6>

            <TextField
              disabled={disabled}
              label={strings.city}
              variant='standard'
              value={cittaResidenza}
              defaultValue={cittaResidenza}
              onChange={(event) => setCittaResidenza(event.target.value)}
            />

            <TextField
              disabled={disabled}
              label={strings.address}
              placeholder={strings.addressPlaceholder}
              variant='standard'
              value={indirizzoResidenza}
              defaultValue={indirizzoResidenza}
              onChange={(event) => setIndirizzoResidenza(event.target.value)}
            />

            <Stack spacing={2} direction='row'>
              <TextField
                style={{ width: "50%" }}
                disabled={disabled}
                label={strings.district}
                variant='standard'
                value={provinciaResidenza}
                defaultValue={provinciaResidenza}
                onChange={(event) =>
                  setProvinciaResidenza(
                    sliceProvinciaTo2Char(event.target.value)
                  )
                }
              />

              <TextField
                style={{ width: "50%" }}
                disabled={disabled}
                inputProps={{ inputMode: "numeric", pattern: "^[0-9]+$" }}
                label={strings.postalCode}
                variant='standard'
                value={capResidenza}
                defaultValue={capResidenza}
                onChange={(event) => setCapResidenza(event.target.value)}
              />
            </Stack>

            <FormControlLabel
              control={
                <Switch
                  onChange={handleHomeAddressSwitch}
                  checked={homeAddressSwitchChecked}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={
                strings.getLanguage() !== "en"
                  ? "Indirizzo di domicilio corrispondente alla residenza"
                  : "Domicile address corresponding to residence"
              }
            />

            {homeAddressSwitchChecked ? (
              ""
            ) : (
              <div>
                <h6>{strings.domicile}</h6>

                <TextField
                  disabled={homeAddressSwitchChecked}
                  label={strings.city}
                  placeholder={strings.cityPlaceholder}
                  variant='standard'
                  value={cittaDomicilio}
                  defaultValue={cittaDomicilio}
                  onChange={(event) => setCittaDomicilio(event.target.value)}
                />

                <TextField
                  disabled={homeAddressSwitchChecked}
                  label={strings.address}
                  placeholder={strings.addressPlaceholder}
                  variant='standard'
                  value={indirizzoDomicilio}
                  defaultValue={indirizzoDomicilio}
                  onChange={(event) =>
                    setIndirizzoDomicilio(event.target.value)
                  }
                />

                <Stack spacing={2} direction='row'>
                  <TextField
                    style={{ width: "50%" }}
                    disabled={homeAddressSwitchChecked}
                    label={strings.district}
                    variant='standard'
                    value={provinciaDomicilio}
                    defaultValue={provinciaDomicilio}
                    onChange={(event) =>
                      setProvinciaDomicilio(
                        sliceProvinciaTo2Char(event.target.value)
                      )
                    }
                  />

                  <TextField
                    style={{ width: "50%" }}
                    disabled={homeAddressSwitchChecked}
                    inputProps={{ inputMode: "numeric", pattern: "^[0-9]+$" }}
                    label={strings.postalCode}
                    variant='standard'
                    value={capDomicilio}
                    defaultValue={capDomicilio}
                    onChange={(event) => setCapDomicilio(event.target.value)}
                  />
                </Stack>
              </div>
            )}
            <Button type='submit' disabled={disabled} variant='contained'>
              {strings.save}
            </Button>
          </Stack>
        </form>
      ) : (
        <div className='loaderDiv'>
          <CircularProgress></CircularProgress>
        </div>
      )}
    </div>
  );
}
