import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { postInviaRichiestaIngaggio } from './employmentAPI';

const initialState = {
  respInviaRichiestaIngaggio: null,
  stauts: 'idle'
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const postInviaRichiestaIngaggioAsync = createAsyncThunk(
  'employment/post',
  async (params) => {
    const response = await postInviaRichiestaIngaggio(params);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);



export const employmentSlice  = createSlice({
  name: 'employment',
  initialState,
  // The `reduce:rs` field lets us define reducers and generate associated actions
  reducers: {
    resetRespInviaRichiestaIngaggio: (state) => {
       state.respInviaRichiestaIngaggio = null;
    } 

  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
   
      .addCase(postInviaRichiestaIngaggioAsync.pending, (state) => {
        state.status = 'loading';
      })

      .addCase(postInviaRichiestaIngaggioAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.respInviaRichiestaIngaggio = action.payload;
        //localStorage.setItem("respSearch", JSON.stringify(action.payload)); 
      })

      ;
      
      
  },
});

export const { resetRespInviaRichiestaIngaggio } = employmentSlice.actions;


export const selectRespInviaRichiestaIngaggio = (state) => state.employment.respInviaRichiestaIngaggio;


export default employmentSlice.reducer;
