import jQuery from "jquery";
import { BASE_API_URL } from "../../Constants.js";

export function doLogin(username, password) {
  return new Promise((resolve) =>
    jQuery
      .ajax({
        type: "POST",
        url: BASE_API_URL + "/api/Auth/login",
        // dataType: "json",
        contentType: "application/json; charset=utf-8",
        data: JSON.stringify({ username: username, password: password }),
      })
      .done((response) => resolve({ respdata: response }))
      .always((error) => resolve({ error }))
  );
}

export function getUserData() {
  return new Promise((resolve) =>
    jQuery
      .ajax({
        type: "GET",
        url: BASE_API_URL + "/api/Auth/getUserData",
        // dataType: "json",
        //contentType: "application/json; charset=utf-8",
       // data: JSON.stringify({ username: username, password: password }),
      })
      .done((response) => resolve({ respdata: response }))
      .always((error) => resolve({ error }))
  );
}

export function registration(username, password, email) {
  return new Promise((resolve) =>
    jQuery
      .ajax({
        type: "POST",
        url: BASE_API_URL + "/api/Accounts/registration",
        // dataType: "json",
        contentType: "application/json; charset=utf-8",
        data: JSON.stringify({
          userName: username,
          password: password,
          email: email,
        }),
      })
      .done((response) => resolve({ respdata: response }))
      .always((error) => resolve({ error }))
  );
}

export function changePassword(params) {
  return new Promise((resolve) =>
    jQuery
      .ajax({
        type: "PUT",
        url: BASE_API_URL + "/api/Accounts/changepassword",
        data: JSON.stringify(params),
        // dataType: "json",
        contentType: "application/json; charset=utf-8",
       // data: JSON.stringify({ username: username, password: password }),
      })
      .done((response) => resolve({ respdata: response }))
      .always((error) => resolve({ error }))
  );
}

export function deleteAccount(params) {
  return new Promise((resolve) =>
    jQuery
      .ajax({
        type: "DELETE",
        url: BASE_API_URL + "/api/Accounts/deleteaccount",
     //   data: JSON.stringify(params),
        // dataType: "json",
        contentType: "application/json; charset=utf-8",
       // data: JSON.stringify({ username: username, password: password }),
      })
      .done((response) => resolve({ respdata: response }))
      .always((error) => resolve({ error }))
  );
}

export function resetPassword(email) {
  return new Promise((resolve) =>
    jQuery
      .ajax({
        type: "PUT",
        url: BASE_API_URL + "/api/Accounts/resetpassword",
        // dataType: "json",
        contentType: "application/json; charset=utf-8",
        data: JSON.stringify({
          email
        }),
      })
      .done((response) => resolve({ respdata: response }))
      .always((error) => resolve({ error }))
  );
}
