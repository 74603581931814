import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getPreferiti,
  postPreferito,
  deletePreferito,
  getCategorie,
  postCategoria,
  deleteCategoria,
  postCondividiCategoria,
  getCategoria,
  getRichiesteCondivisioneCategoria,
  putRichiestaCondivisioneCategoria,
  deleteCategoriaCondivisa,
  getCategoriaCondivisa,
  postCondividiCategoriaEmail,
} from "./favoriteAPI";

const initialState = {
  respGetFavorites: null,
  respPostFavorite: null,
  respDeleteFavorite: null,
  respCategories: null,
  respPostCategory: null,
  respDeleteCategory: null,
  respPostShareCategory: null,
  respGetShareCategoryRequests: null,
  respGetShareCategoryRequestsByStatus: null,
  respPutShareCategoryRequest: null,
  respDeleteSharedCategory: null,
  respGetShareCategory: null,
  respPostCategoryShareEmail: null,
  status: "idle",
};

export const getFavoritesAsync = createAsyncThunk(
  "favorites/get",
  async (params) => {
    const response = await getPreferiti();
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const postFavoriteAsync = createAsyncThunk(
  "favorites/post",
  async (params) => {
    const response = await postPreferito(params);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const postCondividiCategoriaEmailAsync = createAsyncThunk(
  "favorites/shareEmail",
  async (params) => {
    const response = await postCondividiCategoriaEmail(params);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const deleteFavoriteAsync = createAsyncThunk(
  "favorites/delete",
  async (params) => {
    const response = await deletePreferito(params);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const getCategoriesAsync = createAsyncThunk(
  "categories/get",
  async (params) => {
    const response = await getCategorie();
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const getCategoryAsync = createAsyncThunk(
  "categories/getcategory",
  async (params) => {
    const response = await getCategoria(params);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const postCategoryAsync = createAsyncThunk(
  "categories/post",
  async (params) => {
    const response = await postCategoria(params);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const deleteCategoryAsync = createAsyncThunk(
  "categories/delete",
  async (params) => {
    const response = await deleteCategoria(params);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const getShareCategoryAsync = createAsyncThunk(
  "categories/share/get",
  async (params) => {
    const response = await getCategoriaCondivisa(params);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const postShareCategoryAsync = createAsyncThunk(
  "categories/share/post",
  async (params) => {
    const response = await postCondividiCategoria(params);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const getShareCategoryRequestsAsync = createAsyncThunk(
  "categories/share/getreq",
  async (params) => {
    const response = await getRichiesteCondivisioneCategoria(params);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const getAcceptedCategoryRequestsAsync = createAsyncThunk(
  "categories/share/getaccreq",
  async (params) => {
    const response = await getRichiesteCondivisioneCategoria(params);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const putShareCategoryRequestAsync = createAsyncThunk(
  "categories/share/putreq",
  async (params) => {
    const response = await putRichiestaCondivisioneCategoria(params);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const deleteShareCategoryRequestAsync = createAsyncThunk(
  "categories/share/delreq",
  async (params) => {
    const response = await deleteCategoriaCondivisa(params);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const favoriteSlice = createSlice({
  name: "favorite",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetRespFavorites: (state) => {
      state.respGetCategories = null;
      state.respGetCategory = null;
      state.respPostFavorite = null;
      state.respPutFavorite = null;
      state.respDeleteFavorite = null;
      state.respPostCategory = null;
      state.respPutCategory = null;
      state.respDeleteCategory = null;
      state.respGetShareCategoryRequests = null;
      state.respGetAcceptedCategoryRequests = null;
      state.respPutShareCategoryRequest = null;
      state.respDeleteShareCategoryRequest = null;
      state.respGetShareCategory = null;
    },
    resetRespShareCategoryEmail: (state) => {
      state.respPostCategoryShareEmail = null;
    },
  },

  extraReducers: (builder) => {
    builder

      .addCase(getFavoritesAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getFavoritesAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.respGetFavorites = action.payload;
      })

      .addCase(postFavoriteAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(postFavoriteAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.respPostFavorite = action.payload;
      })

      .addCase(deleteFavoriteAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteFavoriteAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.respDeleteFavorite = action.payload;
      })

      .addCase(getCategoriesAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCategoriesAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.respGetCategories = action.payload;
      })

      .addCase(getCategoryAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCategoryAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.respGetCategory = action.payload;
      })

      .addCase(postCategoryAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(postCategoryAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.respPostCategory = action.payload;
      })

      .addCase(postCondividiCategoriaEmailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(postCondividiCategoriaEmailAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.respPostCategoryShareEmail = action.payload;
      })

      .addCase(deleteCategoryAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteCategoryAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.respDeleteCategory = action.payload;
      })

      .addCase(postShareCategoryAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(postShareCategoryAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.respPostShareCategory = action.payload;
      })

      .addCase(getShareCategoryRequestsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getShareCategoryRequestsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.respGetShareCategoryRequests = action.payload;
      })

      .addCase(getAcceptedCategoryRequestsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAcceptedCategoryRequestsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.respGetAcceptedCategoryRequests = action.payload;
      })

      .addCase(putShareCategoryRequestAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(putShareCategoryRequestAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.respPutShareCategoryRequest = action.payload;
      })

      .addCase(deleteShareCategoryRequestAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteShareCategoryRequestAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.respDeleteShareCategoryRequest = action.payload;
      })

      .addCase(getShareCategoryAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getShareCategoryAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.respGetShareCategory = action.payload;
      });
  },
});

export const { resetRespFavorites, resetRespShareCategoryEmail } =
  favoriteSlice.actions;

export const selectRespGetFavorites = (state) =>
  state.favorite.respGetFavorites;
export const selectRespPostFavorite = (state) =>
  state.favorite.respPostFavorite;
export const selectRespDeleteFavorite = (state) =>
  state.favorite.respDeleteFavorite;
export const selectRespGetCategories = (state) =>
  state.favorite.respGetCategories;
export const selectRespGetCategory = (state) => state.favorite.respGetCategory;

export const selectStatusResponse = (state) => state.favorite.status;

export const selectRespPostCategory = (state) =>
  state.favorite.respPostCategory;
export const selectRespDeleteCategory = (state) =>
  state.favorite.respDeleteCategory;
export const selectRespPostShareCategory = (state) =>
  state.favorite.respPostShareCategory;
export const selectRespPostCategoryShareEmail = (state) =>
  state.favorite.respPostCategoryShareEmail;
export const selectRespGetShareCategory = (state) =>
  state.favorite.respGetShareCategory;
export const selectRespGetShareCategoryRequests = (state) =>
  state.favorite.respGetShareCategoryRequests;
export const selectRespGetAcceptedCategoryRequests = (state) =>
  state.favorite.respGetAcceptedCategoryRequests;
export const selectRespPutShareCategoryRequest = (state) =>
  state.favorite.respPutShareCategoryRequest;
export const selectRespDeleteShareCategoryRequest = (state) =>
  state.favorite.respDeleteShareCategoryRequest;

export default favoriteSlice.reducer;
