/* eslint-disable react-hooks/exhaustive-deps */
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import { isEqual, merge } from "lodash";
import React, { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import { useDispatch, useSelector } from "react-redux";
import { COMMON_SX, DISMISS_TIME_MS, strings } from "../../Constants";
import ResponseAlertMessages from "../commons/ResponseAlertMessages";
import { ajaxError, ajaxOk, selectLoginData } from "../welcome/welcomeSlice";
import {
  MESSAGES2_SX,
  FORM_INTERNAL_SX,
  _INTERNAL_WIDTH_PX,
} from "./../../Constants";
import Abbonamento from "./Abbonamento";
import {
  createStripeCardTokenAsync,
  deleteSubscriptionAsync,
  getPagamentiAsync,
  getPagamentiKeyAsync,
  getSubscriptionAsync,
  getSubscriptionListAsync,
  postPagamentiAsync,
  postSubscriptionAsync,
  resetRespPostPagamenti,
  resetRespPostSubscription,
  selectRespDeleteSubscription,
  selectRespPagamenti,
  selectRespPagamentiKey,
  selectRespPostPagamenti,
  selectRespPostSubscription,
  selectRespStripeCardToken,
  selectRespSubscription,
  selectRespSubscriptionList,
} from "./subscriptionSlice";
import {
  getDatiProfiloAsync,
  getDatiPersonaliGuiridiciAsync,
  selectRespDatiPersonaliGiuridici,
  selectRespDatiProfilo,
  postDatiFatturazioneAsync,
} from "../profile/profileSlice";
import { ButtonGroup } from "@mui/material";
import {
  validationSchemaPersonaFisica,
  validationSchemaPersonaFisicaNotRequired,
  validationSchemaPersonaFisicaRequired,
  validationSchemaPersonaGiuridicaNotRequired,
  validationSchemaPersonaGiuridicaRequired,
} from "./validationSchema";

//https://stackoverflow.com/questions/39419237/what-is-mapdispatchtoprops
//https://newbedev.com/react-hook-useeffect-has-a-missing-dependency-dispatch
export default function Subscription() {
  const images = require.context("../../../public/images", true);

  const dispatch = useDispatch();
  //const stableDispatch = useCallback(dispatch, []) //assuming that it doesn't need to change

  const loggedUser = useSelector(selectLoginData);
  const respPagamentiKey = useSelector(selectRespPagamentiKey);
  const respSubscriptionList = useSelector(selectRespSubscriptionList);
  const respSubscription = useSelector(selectRespSubscription);
  const respStripeCardToken = useSelector(selectRespStripeCardToken);
  const respPostPagamenti = useSelector(selectRespPostPagamenti);
  const respPostSubscription = useSelector(selectRespPostSubscription);
  const respPagamenti = useSelector(selectRespPagamenti);
  const respDeleteSubscription = useSelector(selectRespDeleteSubscription);
  const profileResponse = useSelector(selectRespDatiPersonaliGiuridici);
  const profileResponse2 = useSelector(selectRespDatiProfilo);

  const [pk, setPk] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [subscription, setSubscription] = useState(null);
  const [subscriptionToPurchase, setSubscriptionToPurchase] = useState(null);
  const [pagamento_automatico, setPagamentoAutomatico] = useState(true);
  const [tipologiaUtente, setTipologiaUtente] = useState(-1);
  const [cardNumber, setCardNumber] = useState(null);
  const [expiry, setExpiry] = useState(null);
  const [cvc, setCvc] = useState(null);
  const [formValues, setFormValues] = useState({});
  const [paymentData, setPaymentData] = useState(null);
  const [validationError, setValidationError] = useState("");

  useEffect(() => {
    setFormValues({
      ...formValues,
      sdi: formValues.recapitoFatturaElettronica?.split("-")[1],
      pec: formValues.recapitoFatturaElettronica?.split("-")[0],
    });
  }, [formValues.recapitoFatturaElettronica]);

  useEffect(() => {
    //console.log(loggedUser);
    if (loggedUser) {
      dispatch(getPagamentiAsync());
      dispatch(getPagamentiKeyAsync());
      dispatch(getSubscriptionAsync());
      dispatch(getSubscriptionListAsync());
      dispatch(getDatiPersonaliGuiridiciAsync());
      dispatch(getDatiProfiloAsync());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUser]);

  useEffect(() => {
    //console.log(respPagamentiKey);
    if (respPagamentiKey?.respdata?.success) {
      setPk(respPagamentiKey?.respdata?.data?.key);
    }
  }, [respPagamentiKey]);

  useEffect(() => {
    //console.log(respPagamenti);
    if (respPagamenti?.respdata?.success) {
      setPaymentData(respPagamenti?.respdata?.data);
    }
  }, [respPagamenti]);

  useEffect(() => {
    if (profileResponse?.respdata?.success) {
      //console.log(profileResponse?.respdata)
      setFormValues({
        ...formValues,
        cittaResidenza: profileResponse?.respdata.data.cittaResidenza,
        indirizzoResidenza: profileResponse?.respdata.data.indirizzoResidenza,
        capResidenza: profileResponse?.respdata.data.capResidenza,
      });
    }
  }, [profileResponse]);

  useEffect(() => {
    if (profileResponse2?.respdata?.success) {
      //console.log("PROFILO RECUPERATO", profileResponse2?.respdata);

      setTipologiaUtente(profileResponse2.respdata.data.tipologiaUtente);
      setFormValues({
        ...formValues,
        nome: profileResponse2.respdata.data.nome,
        cognome: profileResponse2.respdata.data.cognome,
        codfisciva: profileResponse2.respdata.data.codiceFiscalePIVA,
        ragioneSociale: profileResponse2.respdata.data.ragioneSociale,
        recapitoFatturaElettronica:
          profileResponse2.respdata.data.recapitoFatturaElettronica,
        tipologiaUtente,
      });
    }
  }, [profileResponse2]);

  useEffect(() => {
    //console.log(respSubscriptionList);
    if (respSubscriptionList?.respdata?.success) {
      setSubscriptionList(respSubscriptionList?.respdata?.data);
    }
  }, [respSubscriptionList]);

  useEffect(() => {
    if (respStripeCardToken?.respdata?.error) {
      dispatch(ajaxError(respStripeCardToken?.respdata?.error.message));
      setTimeout(() => dispatch(ajaxOk()), DISMISS_TIME_MS);
    }
    if (respStripeCardToken?.respdata?.id) {
      dispatch(
        postPagamentiAsync({
          token: respStripeCardToken?.respdata?.id,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [respStripeCardToken]);

  useEffect(() => {
    if (respPostPagamenti?.respdata?.success) {
      setTimeout(() => dispatch(resetRespPostPagamenti()), DISMISS_TIME_MS);
      dispatch(
        postSubscriptionAsync({
          id_Abbonamento: subscriptionToPurchase.id_Abbonamento,
          pagamento_automatico,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [respPostPagamenti]);

  useEffect(() => {
    if (subscriptionToPurchase === null) {
      dispatch(getSubscriptionAsync());
      dispatch(getSubscriptionListAsync());
    }
  }, [subscriptionToPurchase]);

  useEffect(() => {
    dispatch(getSubscriptionAsync());
    dispatch(getSubscriptionListAsync());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [respPostSubscription]);

  useEffect(() => {
    //console.log(respSubscription);
    setTimeout(() => dispatch(resetRespPostSubscription()), DISMISS_TIME_MS);
    if (respSubscription?.respdata?.success) {
      if (subscriptionList.length > 0) {
        const sub = subscriptionList.find(
          (s) =>
            s.id_Abbonamento ===
            respSubscription?.respdata?.data?.id_Abbonamento
        );
        // console.log(sub);
        if (sub !== null && sub !== undefined) {
          const mergedSub = merge({}, sub, respSubscription?.respdata?.data);
          //console.log(mergedSub,sub,respSubscription?.respdata?.data);
          setSubscription(mergedSub);
          const subscriptionList2 = []; // cloneDeep(subscriptionList);
          subscriptionList.forEach((s, i) => {
            if (s.id_Abbonamento !== sub.id_Abbonamento) {
              subscriptionList2.push(s);
            }
          });
          if (!isEqual(subscriptionList2, subscriptionList)) {
            //console.log(subscriptionList,subscriptionList2);
            setSubscriptionList(subscriptionList2);
          }
          dispatch(resetRespPostSubscription());
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [respSubscription, subscriptionList]);

  useEffect(() => {}, [formValues]);

  const handleAbbonamentoAttivaClick = (abbonamento) => {
    setSubscriptionToPurchase(abbonamento);
  };

  const deleteThenPay = () => {
    dispatch(deleteSubscriptionAsync());
  };

  useEffect(() => {
    if (respDeleteSubscription?.respdata?.success) {
      _handlePayment();
    }
  }, [respDeleteSubscription]);

  const skipDelete = true;
  const handlePayment = () => {
    if (subscription === null || skipDelete) {
      handleBillingInformation();
      _handlePayment();
    } else {
      //console.log("delete");
      deleteThenPay();
    }
  };

  const _handlePayment = () => {
    if (cardNumber !== null && expiry !== null && cvc !== null) {
      dispatch(
        createStripeCardTokenAsync({
          pk,
          cardNumber: cardNumber.replaceAll(" ", "").trim(),
          cardExpMonth: expiry.split("/")[0].trim(),
          cardExpYear: expiry.split("/")[1].trim(),
          cardCVC: cvc,
        })
      );
    } else {
      if (paymentData?.last4 !== null) {
        dispatch(
          postSubscriptionAsync({
            id_Abbonamento: subscriptionToPurchase.id_Abbonamento,
            pagamento_automatico,
          })
        );
      }
    }
  };

  const handleBillingInformation = () => {
    const {
      nome,
      cognome,
      indirizzoResidenza,
      cittaResidenza,
      codfisciva,
      sdi,
      pec,
      ragioneSociale,
      provinciaResidenza,
      nazioneResidenza,
    } = formValues;
    if (!disabled) {
      setDisabled(true);
      if (profileResponse2?.respdata?.data?.tipologiaUtente === 0) {
        dispatch(
          postDatiFatturazioneAsync({
            intestazioneFatturazione: nome + " " + cognome,
            indirizzoFatturazione: indirizzoResidenza,
            cittaFatturazione: cittaResidenza,
            provinciaFatturazione: provinciaResidenza,
            nazioneFatturazione: nazioneResidenza,
            privacfFatturazione: codfisciva,
            sdiFatturazione: sdi,
            pecFatturazione: pec,
          })
        );
      } else {
        dispatch(
          postDatiFatturazioneAsync({
            intestazioneFatturazione: ragioneSociale,
            indirizzoFatturazione: indirizzoResidenza,
            cittaFatturazione: cittaResidenza,
            provinciaFatturazione: provinciaResidenza,
            nazioneFatturazione: nazioneResidenza,
            privacfFatturazione: codfisciva,
            sdiFatturazione: sdi,
            pecFatturazione: pec,
          })
        );
      }
    }
  };

  const sliceTo2CharUpper = (input) => {
    return input.slice(0, 2).toUpperCase();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]:
        name === "nazioneResidenza" || name === "provinciaResidenza"
          ? sliceTo2CharUpper(value)
          : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let schema = null;
    const { sdi, pec } = formValues;
    if (tipologiaUtente === 0) {
      if (sdi === "" && pec === "") {
        schema = validationSchemaPersonaFisicaRequired;
      } else {
        schema = validationSchemaPersonaFisicaNotRequired;
      }
      schema
        .validate(formValues)
        .then((validData) => {
          setValidationError("");
          handleBillingInformation();
        })
        .catch((error) => {
          setValidationError(error.message);
        });
    } else {
      if (sdi === "" && pec === "") {
        schema = validationSchemaPersonaGiuridicaRequired;
      } else {
        schema = validationSchemaPersonaGiuridicaNotRequired;
      }
      schema
        .validate(formValues)
        .then((validData) => {
          setValidationError("");
          handleBillingInformation();
        })
        .catch((error) => {
          setValidationError(error.message);
        });
    }
  };

  const [color, setColor] = useState(
    profileResponse2?.respdata?.data?.tipologiaUtente !== 0
  );

  console.log("we", profileResponse2);

  const handleRender = (value) => {
    const { nome, cognome, ragioneSociale } = profileResponse2.respdata.data;
    setColor(value);
    //Se clicco su Persona Fisica...
    if (value) {
      setFormValues({
        ...formValues,
        nome: nome ? nome : "",
        cognome: cognome ? cognome : "",
        ragioneSociale: "",
      });
      setTipologiaUtente(0);
    } else {
      setFormValues({
        ...formValues,
        nome: "",
        cognome: "",
        ragioneSociale: ragioneSociale ? ragioneSociale : "",
      });
      setTipologiaUtente(1);
    }
  };

  return (
    <div>
      <Box component='form' sx={FORM_INTERNAL_SX}>
        <Box
          className='dontUseMuiStackHere'
          sx={{ p: 1, m: "0 auto", width: _INTERNAL_WIDTH_PX }}
        >
          <h2 style={{ textAlign: "center" }}>
            {subscriptionToPurchase !== null ? (
              <span
                style={{ cursor: "pointer" }}
                title={strings.back}
                onClick={() => setSubscriptionToPurchase(null)}
              >
                <ChevronLeftIcon />
                {/*<span > {strings.back}</span>*/}{" "}
              </span>
            ) : (
              ""
            )}
            {strings.subscriptionManagement}
          </h2>
          {subscriptionToPurchase === null ? (
            <>
              <Alert
                sx={MESSAGES2_SX}
                severity='success'
                style={{ width: "95%" }}
              >
                <h3>{strings.yourActualSubscription}</h3>
              </Alert>
              <br />
              {subscription !== null ? (
                <>
                  <Abbonamento actual={true} abbonamento={subscription} />
                  <br />
                </>
              ) : (
                <div>{strings.noSubscriptionChooseOne}</div>
              )}
              <br />
              <hr />
              <Alert sx={MESSAGES2_SX} severity='info' style={{ width: "95%" }}>
                <h3 className='colorGray'>{strings.otherSubscriptions}</h3>
              </Alert>
              <br />
              <Grid
                container
                rowSpacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 2 }}
              >
                {subscriptionList?.map((o, i) => {
                  return (
                    <Grid item xs={12} sm={12} md={12}>
                      <Abbonamento
                        onAttivaClick={handleAbbonamentoAttivaClick}
                        abbonamento={o}
                        i={i + 1}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </>
          ) : (
            <>
              <br />
              <br />
              <Abbonamento abbonamento={subscriptionToPurchase} recap={true} />
              <br />
              <br />
              <ResponseAlertMessages
                showInfoHeader={false}
                loadResponse={null}
                response={respPostPagamenti}
              ></ResponseAlertMessages>
              <ResponseAlertMessages
                showInfoHeader={false}
                loadResponse={null}
                response={respPostSubscription}
              ></ResponseAlertMessages>
              <div style={{ textAlign: "center" }}>
                <div
                  style={{ marginBottom: "40px" }}
                  className='subscriptionBox inactive'
                >
                  <h4 style={{ marginBottom: "30px" }}>
                    {strings.datiFatturazione}
                  </h4>
                  <form component='form' sx={FORM_INTERNAL_SX}>
                    <Stack
                      className='muiStackFix'
                      spacing={2}
                      direction='column'
                      sx={COMMON_SX}
                    >
                      {validationError.length > 0 && (
                        <Alert severity='error'>{validationError}</Alert>
                      )}

                      <div>
                        <ButtonGroup
                          disableElevation
                          size='small'
                          variant='contained'
                          aria-label='Disabled elevation buttons'
                        >
                          <Button
                            color={color ? "secondary" : ""}
                            onClick={() => handleRender(true)}
                          >
                            Persona Fisica
                          </Button>
                          <Button
                            color={color ? "" : "secondary"}
                            onClick={() => handleRender(false)}
                          >
                            Persona Giuridica
                          </Button>
                        </ButtonGroup>
                      </div>

                      {tipologiaUtente === 0 ? (
                        <>
                          <TextField
                            disabled={disabled}
                            label={strings.name}
                            variant='standard'
                            value={formValues.nome}
                            defaultValue={formValues.nome}
                            name='nome'
                            onChange={handleChange}
                          />

                          <TextField
                            disabled={disabled}
                            label={strings.surname}
                            variant='standard'
                            value={formValues.cognome}
                            defaultValue={formValues.cognome}
                            name='cognome'
                            onChange={handleChange}
                          />
                        </>
                      ) : (
                        <>
                          <TextField
                            disabled={disabled}
                            label={strings.businessName}
                            variant='standard'
                            value={formValues.ragioneSociale}
                            defaultValue={formValues.ragioneSociale}
                            name='ragioneSociale'
                            onChange={handleChange}
                          />
                        </>
                      )}

                      <TextField
                        disabled={disabled}
                        label={strings.fiscalCodeOrVatNumber}
                        variant='standard'
                        value={formValues.codfisciva}
                        defaultValue={formValues.codfisciva}
                        name='codfisciva'
                        onChange={handleChange}
                      />
                      <TextField
                        disabled={disabled}
                        label={strings.pec}
                        variant='standard'
                        value={formValues.pec}
                        defaultValue={formValues.pec}
                        name='pec'
                        onChange={handleChange}
                      />
                      <TextField
                        disabled={disabled}
                        label={strings.sdi}
                        variant='standard'
                        value={formValues.sdi}
                        defaultValue={formValues.sdi}
                        name='sdi'
                        onChange={handleChange}
                      />
                      <TextField
                        disabled={disabled}
                        label={strings.address}
                        variant='standard'
                        value={formValues.indirizzoResidenza}
                        defaultValue={formValues.indirizzoResidenza}
                        name='indirizzoResidenza'
                        onChange={handleChange}
                      />
                      <TextField
                        disabled={disabled}
                        label={strings.city}
                        variant='standard'
                        value={formValues.cittaResidenza}
                        defaultValue={formValues.cittaResidenza}
                        name='cittaResidenza'
                        onChange={handleChange}
                      />
                      <TextField
                        disabled={disabled}
                        label={strings.provincia}
                        variant='standard'
                        value={formValues.provinciaResidenza}
                        defaultValue={formValues.provinciaResidenza}
                        name='provinciaResidenza'
                        onChange={handleChange}
                      />
                      <TextField
                        disabled={disabled}
                        label={strings.nazione}
                        variant='standard'
                        value={formValues.nazioneResidenza}
                        defaultValue={formValues.nazioneResidenza}
                        name='nazioneResidenza'
                        onChange={handleChange}
                      />

                      {disabled ? (
                        <Button
                          style={{ width: "50%", alignSelf: "center" }}
                          variant='contained'
                          onClick={() => setDisabled(false)}
                        >
                          {strings.modifica}
                        </Button>
                      ) : (
                        <Button
                          style={{ width: "50%", alignSelf: "center" }}
                          variant='contained'
                          onClick={handleSubmit}
                        >
                          {strings.conferma}
                        </Button>
                      )}
                    </Stack>
                  </form>
                </div>
                {paymentData !== null ? (
                  <div className='subscriptionBox'>
                    <h4>{strings.usePaymentData}</h4>
                    <div
                      style={{
                        display: "flex",
                        margin: "10px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        style={{
                          width: "40px",
                          height: "auto",
                          alignSelf: "end",
                        }}
                        src={images(`./${paymentData.brand}.png`).default}
                        alt={paymentData.brand}
                      />
                      **** **** **** {paymentData.last4}{" "}
                      {paymentData.exp_month < 10
                        ? "0" + paymentData.exp_month
                        : paymentData.exp_month}
                      /{paymentData.exp_year}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {paymentData !== null ? (
                  <h4>
                    {strings.Or}
                    <br />
                  </h4>
                ) : (
                  ""
                )}
                <h4> {strings.insertCreditCardSafePaymentViaStripe}</h4>
                <br />

                <div className='subscriptionBox inactive'>
                  <InputMask
                    mask='9999 9999 9999 9999'
                    value={cardNumber ? cardNumber : ""}
                    disabled={false}
                    maskChar=' '
                    onChange={(e) => {
                      setCardNumber(e.target.value);
                    }}
                  >
                    {() => (
                      <TextField
                        label={strings.cardNumber}
                        placeholder={strings.cardNumberPlaceholder}
                        variant='standard'
                      />
                    )}
                  </InputMask>

                  <Stack direction='row' spacing={2}>
                    <InputMask
                      mask='99/99'
                      value={expiry ? expiry : ""}
                      disabled={false}
                      maskChar=' '
                      onChange={(e) => {
                        setExpiry(e.target.value);
                      }}
                    >
                      {() => (
                        <TextField
                          placeholder={strings.expiryPlaceholder}
                          label={strings.cardExpiryDate}
                          variant='standard'
                        />
                      )}
                    </InputMask>

                    <InputMask
                      mask='999'
                      value={cvc ? cvc : ""}
                      disabled={false}
                      maskChar=' '
                      onChange={(e) => {
                        setCvc(e.target.value);
                      }}
                    >
                      {() => (
                        <TextField
                          placeholder={strings.cvcPlaceholder}
                          label={strings.cardCVC}
                          variant='standard'
                        />
                      )}
                    </InputMask>
                  </Stack>
                </div>

                {/*
                <CreditCardInput
                  customTextLabels={{
                    invalidCardNumber: strings.invalidCardNumber,
                    expiryError: {
                      invalidExpiryDate: strings.invalidExpiryDate,
                      monthOutOfRange: strings.monthOutOfRange,
                      yearOutOfRange: strings.yearOutOfRange,
                      dateOutOfRange: strings.dateOutOfRange
                    },
                    invalidCvc: strings.invalidCvc,
                    invalidZipCode: strings.invalidZipCode,
                    cardNumberPlaceholder: strings.cardNumberPlaceholder,
                    expiryPlaceholder: strings.expiryPlaceholder,
                    cvcPlaceholder: strings.cvcPlaceholder,
                    zipPlaceholder: strings.zipPlaceholder
                  }}
                  cardNumberInputProps={{ value: cardNumber, onChange: (e) => { setCardNumber(e.target.value); } }}
                  cardExpiryInputProps={{ value: expiry, onChange: (e) => { setExpiry(e.target.value); } }}
                  cardCVCInputProps={{ value: cvc, onChange: (e) => { setCvc(e.target.value); } }}
                  fieldClassName="inputCard"
                />
                */}
                <br />
                <br />
                <FormControlLabel
                  labelPlacement='start'
                  control={
                    <Switch
                      checked={pagamento_automatico}
                      onChange={(event) => {
                        setPagamentoAutomatico(event.target.checked);
                      }}
                    />
                  }
                  label={strings.autoRenewal}
                />

                <br />
                <br />
                <Button
                  onClick={() => {
                    handlePayment();
                  }}
                  variant='contained'
                >
                  {strings.activate}
                </Button>
              </div>
            </>
          )}
          <br />
          <br />
        </Box>
      </Box>
    </div>
  );
}
