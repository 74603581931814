import React from 'react';
import {useState} from 'react';
import  Button  from '@mui/material/Button';

const FileUploader = props => {
  const hiddenFileInput = React.useRef(null);
  const [size, setSize] = useState(0);
  
  const handleClick = event => {
    hiddenFileInput.current.click();
  };
  const handleChange = event => {
    const fileUploaded = event.target.files[0];
    console.log(event.target.files);
    if (props.handleRawFiles){
    props.handleRawFiles(Array.from(event.target.files));
    }
    //console.log(fileUploaded);
    setSize(fileUploaded.size/1024);
    if (fileUploaded) {
        const reader = new FileReader();
        reader.onload = _handleReaderLoaded;
        reader.readAsBinaryString(fileUploaded);
      }
  };

  const _handleReaderLoaded = (readerEvt) => {
    let binaryString = readerEvt.target.result;
    if (props.handleFile) {
    props.handleFile(btoa(binaryString), size);
    }
  }


  return (
    <>
      <Button onClick={handleClick} disabled={props.disabled}>
        {props.existingFile ? props.existingFile.dimensioniDocumento > 0 ? "✔ ("+props.existingFileCount+")" : "Errore" : props.title}
      </Button>
      <input type="file"
             multiple={props.multiple}
             ref={hiddenFileInput}
             onChange={handleChange}
             style={{display:'none'}} 
      /> 
    </>
  );
};
export default FileUploader;