import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ResponseAlertMessages from "../commons/ResponseAlertMessages";
import { selectLoginData } from "../welcome/welcomeSlice";
import { COMMON_SX, FORM_INTERNAL_SX } from "./../../Constants";
import {
  getDatiFiscaliAsync,
  putDatiFiscaliAsync,
  selectRespDatiFiscali,
  selectRespSaveDatiFiscali,
} from "./profileSlice";

export default function Fiscali(props) {
  const dispatch = useDispatch();
  const loggedUser = useSelector(selectLoginData);
  const profileResponse = useSelector(selectRespDatiFiscali);
  const profileSaveResponse = useSelector(selectRespSaveDatiFiscali);
  const [disabled, setDisabled] = useState(true);
  const [imponibileFiscale, setImponibileFiscale] = useState(0);

  useEffect(() => {
    resetFields();
    // console.log(loggedUser);
    if (loggedUser) {
      dispatch(getDatiFiscaliAsync());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (profileSaveResponse != null) {
      setDisabled(false);
      //console.log(profileSaveResponse);
      if (profileSaveResponse.respdata?.success) {
        props.onTabNext();
        /*
        const url = profileStepMap.get(loggedUser.workflow);
        dispatch(resetRespSaveDatiFiscali());
        navigate(url, {replace: true});
        console.log('navigiated', url);*/
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileSaveResponse]);

  const resetFields = () => {
    setDisabled(true);
    setImponibileFiscale(0);
  };

  useEffect(() => {
    if (profileResponse?.respdata?.success) {
      //console.log(profileResponse?.respdata)
      setDisabled(false);
      setImponibileFiscale(profileResponse?.respdata?.data?.imponibileFiscale);
    }
  }, [profileResponse]);

  const onSubmit = (e) => {
    e.preventDefault();
    if (!disabled) {
      setDisabled(true);

      dispatch(
        putDatiFiscaliAsync({
          imponibileFiscale: parseInt(imponibileFiscale),
        })
      );
    }
  };

  return (
    <div>
      <ResponseAlertMessages
        loadResponse={profileResponse}
        response={profileSaveResponse}
      ></ResponseAlertMessages>

      <br />
      {profileResponse?.respdata ? (
        <form component='form' sx={FORM_INTERNAL_SX} onSubmit={onSubmit}>
          <Stack
            className='muiStackFix'
            spacing={2}
            direction='column'
            sx={COMMON_SX}
          >
            <TextField
              required
              type='number'
              placeholder='50'
              disabled={disabled}
              label={
                localStorage.getItem("activeLanguage") !== "en"
                  ? "Imponibile fiscale"
                  : "Taxable income"
              }
              variant='standard'
              value={imponibileFiscale}
              defaultValue={imponibileFiscale}
              onChange={(event) => setImponibileFiscale(event.target.value)}
            />

            <Button type='submit' disabled={disabled} variant='contained'>
              {localStorage.getItem("activeLanguage") !== "en"
                ? "Salva"
                : "Save"}
            </Button>
          </Stack>
        </form>
      ) : (
        <div className='loaderDiv'>
          <CircularProgress></CircularProgress>
        </div>
      )}
    </div>
  );
}
