import React from 'react';
import  Alert from '@mui/material/Alert';
import {COMMON_SX} from '../../Constants';

export default function ResponseAlertMessages(props){

    const [hasMessage,setHasMessage] = React.useState(false);

    React.useEffect(() => {

        if ((props.loadResponse && props.loadResponse?.respdata?.success===false) || (props.response &&
        props.response?.error &&
        props.response?.error?.responseJSON) || ( props.response?.respdata?.success === false )
        || ( props.response?.respdata?.success === true )){
            setHasMessage(true);
            
        document.querySelectorAll(".responseMessages")
        .forEach((element)=>{element.scrollIntoView({ behavior: "smooth", block: "start" });
        })
        
        }

    },[props]);

    return (
        <div className="responseMessages">
            { hasMessage && props.showInfoHeader === true ?
            <>
              <Alert sx={COMMON_SX} severity="info">L'operazione corrente ha prodotto dei messaggi</Alert>
              <br />
            </>
             : ""}

        {props.loadResponse && props.loadResponse?.respdata?.success===false ?
          <> <Alert sx={COMMON_SX} severity="error">
           {props.loadResponse.respdata.message}{" "}
         </Alert>
         <br/>
         </>
        : ""}

        {props.response &&
        props.response.error &&
        props.response.error.responseJSON &&
        !props.response?.error?.responseJSON?.modelValidationError ? (
          <>
            <Alert sx={COMMON_SX} severity="error">
             {props.response.error.responseJSON.data.title}{" "}
          </Alert>
          <br/>
          </>
        
        ) : (
          ""
        )}
  
        {props.response?.error?.responseJSON?.modelValidationError?.map(
          (o, i) => (
            <div>
              <Alert sx={COMMON_SX} severity="error">
                {o}{" "}
              </Alert>
              <br />
            </div>
          )
        )}
  
        {props.response?.respdata?.success === false ? (
          <>
          <Alert sx={COMMON_SX} severity="error">
            {props.response.respdata.message}{" "}
          </Alert>
          <br/>
        </>
          ) : (
          ""
        )}

        {props.response?.respdata?.success === true && props.response.respdata.message ? (
         <>
         <Alert sx={COMMON_SX} severity="success">
            {props.response.respdata.message}{" "}
          </Alert>
          <br/>
         </> 
        ) : (
          ""
        )}
    </div>);
}