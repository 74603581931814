/* eslint-disable react-hooks/exhaustive-deps */
import Box from "@mui/material/Box";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FORM_INTERNAL_SX, strings, _INTERNAL_WIDTH_PX } from "../../Constants";
import { selectLoginData } from "../welcome/welcomeSlice";
import {
  getEnlistmentRequestsAsync,
  getEnlistmentsAsync,
  selectRespGetEnlistmentRequests,
  selectRespGetEnlistments,
} from "../enlistment/enlistmentSlice";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useState } from "react";
import Enlistment from "../enlistment/Enlistment";

//https://stackoverflow.com/questions/39419237/what-is-mapdispatchtoprops
//https://newbedev.com/react-hook-useeffect-has-a-missing-dependency-dispatch
export default function Enlistments() {
  const dispatch = useDispatch();
  //const stableDispatch = useCallback(dispatch, []) //assuming that it doesn't need to change

  const loggedUser = useSelector(selectLoginData);

  const respEnlistments = useSelector(selectRespGetEnlistments);
  const respEnlistmentRequests = useSelector(selectRespGetEnlistmentRequests);
  const [enlistmentList, setEnlistmentList] = useState([]);
  const [enlistmentRequestList, setEnlistmentRequestList] = useState([]);

  useEffect(() => {
    if (loggedUser) {
      dispatch(getEnlistmentsAsync());
      dispatch(getEnlistmentRequestsAsync());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUser]);

  useEffect(() => {
    console.log("INGAGGI", respEnlistments);
    if (respEnlistments?.respdata?.success) {
      setEnlistmentList(respEnlistments.respdata.data);
    }
    console.log(enlistmentList.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [respEnlistments]);

  useEffect(() => {
    console.log("RICHIESTE INGAGGIO", respEnlistmentRequests);
    if (respEnlistmentRequests?.respdata?.success) {
      setEnlistmentRequestList(respEnlistmentRequests.respdata.data);
    }
    console.log(enlistmentRequestList.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [respEnlistmentRequests]);

  return (
    <div>
      <Box component='form' sx={FORM_INTERNAL_SX}>
        <Box
          className='dontUseMuiStackHere'
          sx={{ p: 1, m: "0 auto", width: _INTERNAL_WIDTH_PX }}
        >
          <h2 style={{ textAlign: "center" }}>
            {strings.enlistmentManagement}
          </h2>
          <br />
          <>
            <h3
              className='colorGray'
              style={{ textAlign: "center", marginBottom: "15px" }}
            >
              {strings.getLanguage() === "en"
                ? strings.engagement
                : strings.engagementIta}
            </h3>
            {enlistmentRequestList?.length > 0 ? (
              <Grid
                container
                rowSpacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 2 }}
              >
                {enlistmentRequestList?.map((o, i) => {
                  return (
                    <Grid item xs={12} sm={12} md={12} key={i}>
                      <Enlistment
                        enlistment={o}
                        i={i + 1}
                        showUser={false}
                        status={"sent"}
                      ></Enlistment>
                    </Grid>
                  );
                })}
              </Grid>
            ) : (
              <h5 className='colorGray' style={{ textAlign: "center" }}>
                {strings.getLanguage() === "en"
                  ? strings.noEmployments
                  : strings.noIngaggi}
              </h5>
            )}

            <hr />
            <h3
              className='colorGray'
              style={{ textAlign: "center", marginBottom: "15px" }}
            >
              {strings.getLanguage() === "en"
                ? strings.engagementAccepted
                : strings.engagementAcceptedIta}
            </h3>
            {enlistmentList?.length > 0 ? (
              <Grid
                container
                rowSpacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 2 }}
              >
                {enlistmentList?.map((o, i) => {
                  return (
                    <Grid item xs={12} sm={12} md={12} key={i}>
                      <Enlistment
                        enlistment={o}
                        i={i + 1}
                        showUser={false}
                        status={"accepted"}
                      ></Enlistment>
                    </Grid>
                  );
                })}
              </Grid>
            ) : (
              <h5 className='colorGray' style={{ textAlign: "center" }}>
                {strings.noEngagement}
              </h5>
            )}
          </>
          <br />
          <br />
        </Box>
      </Box>
    </div>
  );
}
