import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import LocalOffer from "@mui/icons-material/LocalOffer";

export default function CategoryShareRequest(props) {
  const handleLocalRejectShareCategory = () => {
    props.handleRejectShareCategory(
      props.share.id_CondivisioneCategoriaPreferiti
    );
  };

  const handleLocalAcceptShareCategory = () => {
    props.handleAcceptShareCategory(
      props.share.id_CondivisioneCategoriaPreferiti
    );
  };

  return (
    <>
      <Box
        sx={{ width: "100%", bgcolor: "background.paper" }}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <List>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <LocalOffer />
              </ListItemIcon>
              <ListItemText
                primary={
                  props.share.nome +
                  " (" +
                  props.share.nomeCreatore +
                  " " +
                  props.share.cognomeCreatore +
                  ")"
                }
              />
              <Button onClick={handleLocalRejectShareCategory}>Rifiuta</Button>
              <Button onClick={handleLocalAcceptShareCategory}>Accetta</Button>
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </>
  );
}
