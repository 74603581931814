import FullCalendar from "@fullcalendar/react"; // must go before plugins
import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";

import { cloneDeep } from "lodash";
import Moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectLoginData } from "../welcome/welcomeSlice";
import {
  COMMON_SX,
  dpLocale,
  fcLocale,
  FORM_INTERNAL_SX,
  strings,
  toSQLDate,
} from "./../../Constants";
import ResponseAlertMessages from "./../commons/ResponseAlertMessages";
import {
  getDatiDisponibilitaAsync,
  putDatiDisponibilitaAsync,
  selectRespDatiDisponibilita,
  selectRespSaveDatiDisponibilita,
} from "./profileSlice";
import {
  getDomainNazioniAsync,
  selectRespDomainNazioni,
} from "../domain/domainSlice";
import { Autocomplete, Typography } from "@mui/material";

export default function Disponibilita(props) {
  const dispatch = useDispatch();
  const loggedUser = useSelector(selectLoginData);
  const disponibilitaResponse = useSelector(selectRespDatiDisponibilita);
  const disponibilitaSaveResponse = useSelector(
    selectRespSaveDatiDisponibilita
  );
  const dominioNazioniResp = useSelector(selectRespDomainNazioni);
  const [disabled, setDisabled] = useState(true);
  const [nazionerif1, setNazioneRif1] = useState(-1);
  const [nazionerif2, setNazioneRif2] = useState(-1);
  const [nazionerif3, setNazioneRif3] = useState(-1);
  const [regionerif1, setRegioneRif1] = useState(0);
  const [regionerif2, setRegioneRif2] = useState(0);
  const [regionerif3, setRegioneRif3] = useState(0);
  const [dataScadenzaGreenPass, setDataScadenzaGreenPass] = useState(
    new Date()
  );
  const [greenPass, setGreenPass] = useState(false);
  const [listaDisponibilita, setListaDisponibilita] = useState([]);
  const [disponibilitaEvents, setDisponibilitaEvents] = useState([]);
  const dateStart = Moment(new Date()).format("YYYY-MM-DD");
  const dateEnd = Moment(new Date())
    .endOf("month")
    .add(1, "days")
    .format("YYYY-MM-DD");

  useEffect(() => {
    resetFields();
    //console.log(loggedUser);
    if (loggedUser) {
      dispatch(getDatiDisponibilitaAsync());
      dispatch(getDomainNazioniAsync());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (disponibilitaSaveResponse != null) {
      setDisabled(false);
      //console.log(disponibilitaSaveResponse);
      if (disponibilitaSaveResponse.respdata?.success) {
        props.onTabNext();
        /*  const url = profileStepMap.get((loggedUser.workflow -1));
          if (url){
            dispatch(resetRespSaveDatiDisponibilita());
            navigate(url, {replace: true});
            console.log('navigiated', url);
          }*/
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disponibilitaSaveResponse]);

  const resetFields = () => {
    setDisabled(true);
    setNazioneRif1(-1);
    setNazioneRif2(-1);
    setNazioneRif3(-1);
    setRegioneRif1(0);
    setRegioneRif2(0);
    setRegioneRif3(0);
    setDataScadenzaGreenPass(new Date());
    setGreenPass(false);
    setListaDisponibilita([]);
  };

  useEffect(() => {
    if (disponibilitaResponse?.respdata?.success) {
      setDisabled(false);
      if (
        typeof disponibilitaResponse.respdata.data.listaNazioni[0] !==
        "undefined"
      ) {
        setNazioneRif1(
          disponibilitaResponse.respdata.data.listaNazioni[0].id_Nazione
        );
        setRegioneRif1(
          disponibilitaResponse.respdata.data.listaNazioni[0].id_Regione
        );
      }

      if (
        typeof disponibilitaResponse.respdata.data.listaNazioni[1] !==
        "undefined"
      ) {
        setNazioneRif2(
          disponibilitaResponse.respdata.data.listaNazioni[1].id_Nazione
        );
        setRegioneRif2(
          disponibilitaResponse.respdata.data.listaNazioni[1].id_Regione
        );
      }

      if (
        typeof disponibilitaResponse.respdata.data.listaNazioni[2] !==
        "undefined"
      ) {
        setNazioneRif3(
          disponibilitaResponse.respdata.data.listaNazioni[2].id_Nazione
        );
        setRegioneRif3(
          disponibilitaResponse.respdata.data.listaNazioni[2].id_Regione
        );
      }
      setDataScadenzaGreenPass(
        disponibilitaResponse.respdata.data.dataScadenzaGreenPass
      );
      setGreenPass(disponibilitaResponse.respdata.data.greenPass);
      let disponibilitaEvents2 = [];
      const arrDisp = disponibilitaResponse.respdata.data.listaDisponibilita;
      const arrDisp2 = [];
      let date = Moment(dateStart);
      while (date.isBefore(Moment(dateEnd))) {
        const sqlDate = date.format("YYYY-MM-DD");
        const index = arrDisp.findIndex((o) => {
          let gg2 = "";
          if (typeof o.giorno !== "undefined") {
            gg2 = o.giorno;
            if (gg2.indexOf("T") > 0) {
              gg2 = gg2.substring(0, gg2.indexOf("T"));
            }
          }
          return sqlDate === gg2;
        });
        if (index !== -1) {
          arrDisp2.push(arrDisp[index]);
        } else {
          arrDisp2.push({
            giorno: sqlDate,
            disponibile: true,
            modificabile: true,
            id_DisponibilitaProfilo: 0,
          });
        }
        date.add(1, "days");
      }
      // console.log(arrDisp2);
      arrDisp2.forEach((v, i) => {
        if (v.disponibile) {
          disponibilitaEvents2.push({
            modificabile: v.modificabile,
            disponibile: true,
            backgroundColor: v.modificabile ? "green" : "#0091D9",
            title: "",
            start: v.giorno,
            allDay: true,
          });
        } else {
          disponibilitaEvents2.push({
            modificabile: v.modificabile,
            disponibile: false,
            borderColor: "red",
            backgroundColor: "red",
            title: "",
            start: v.giorno,
            allDay: true,
          });
        }
      });
      setListaDisponibilita(arrDisp2);

      //console.log(disponibilitaEvents2);
      setDisponibilitaEvents(disponibilitaEvents2);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disponibilitaResponse]);

  const handleEventClick = (info) => {
    handleDateClick({ dateStr: Moment(info.event.start).format("YYYY-MM-DD") });
  };

  const handleDateClick = (info) => {
    if (typeof info.dateStr !== "undefined") {
      //console.log(info.dateStr);
      let gg1 = info.dateStr;
      let gg2 = null;
      let listaDisponibilita2 = cloneDeep(listaDisponibilita);
      // console.log(listaDisponibilita2);
      const index = listaDisponibilita2.findIndex((o) => {
        // console.log(gg1);
        if (gg1.indexOf("T") > 0) {
          gg1 = gg1.substring(0, gg1.indexOf("T"));
        }
        if (typeof o.giorno !== "undefined") {
          gg2 = o.giorno;
          if (gg2.indexOf("T") > 0) {
            gg2 = gg2.substring(0, gg2.indexOf("T"));
          }
        }
        return gg1 === gg2;
      });
      if (index !== -1) {
        //tpggling
        //  console.log('existing', listaDisponibilita2[index].modificabile);
        if (
          listaDisponibilita2[index].disponibile === false ||
          listaDisponibilita2[index].modificabile
        ) {
          listaDisponibilita2[index].disponibile =
            !listaDisponibilita2[index].disponibile;
        }
      } else {
        const obj = {
          giorno: gg1,
          disponibile: true,
          modificabile: true,
          id_DisponibilitaProfilo: 0,
        };
        //console.log(listaDisponibilita2);
        listaDisponibilita2.push(obj);
      }
      let disponibilitaEvents2 = [];
      listaDisponibilita2.forEach((v, i) => {
        if (v.disponibile) {
          // disponibilitaEvents2.push({ title: 'disponibile', start: v.giorno, allDay: true });
          disponibilitaEvents2.push({
            modificabile: v.modificabile,
            disponibile: true,
            backgroundColor: v.modificabile ? "green" : "red",
            title: "",
            start: v.giorno,
            allDay: true,
          });
        } else {
          disponibilitaEvents2.push({
            modificabile: v.modificabile,
            disponibile: false,
            borderColor: "red",
            backgroundColor: "red",
            title: "",
            start: v.giorno,
            allDay: true,
          });
        }
      });
      //console.log(disponibilitaEvents2);
      setListaDisponibilita(listaDisponibilita2);
      setDisponibilitaEvents(disponibilitaEvents2);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!disabled) {
      setDisabled(true);

      //console.log(dataScadenzaGreenPass);
      let dataScadenzaGreenPassSQL = toSQLDate(dataScadenzaGreenPass);
      // console.log(dataScadenzaGreenPassSQL);

      const listaNazioni = [];

      listaNazioni.push({ id_Nazione: nazionerif1, id_Regione: regionerif1 });
      if (nazionerif2 !== -1) {
        listaNazioni.push({ id_Nazione: nazionerif2, id_Regione: regionerif2 });
      }
      if (nazionerif3 !== -1) {
        listaNazioni.push({ id_Nazione: nazionerif3, id_Regione: regionerif3 });
      } // console.log(username,password,email);

      const listaDisponibilita2 = [];
      const listaDisponibilitaClone = cloneDeep(listaDisponibilita);
      //  console.log(listaDisponibilitaClone);
      console.log(disponibilitaEvents);
      disponibilitaEvents.forEach((de, i) => {
        let le = listaDisponibilitaClone.find((o) => o.giorno === de.start);
        if (le !== null) {
          le.disponibile = de.disponibile;
        } else {
          le = {
            giorno: de.start,
            disponibile: de.disponibile,
            modificabile: de.modificabile,
            id_DisponibilitaProfilo: 0,
          };
        }
        if (
          listaDisponibilita2.findIndex((o) => o.giorno === le.giorno) === -1
        ) {
          if (le.disponibile === false) {
            listaDisponibilita2.push(le);
          }
        }
      });

      /* listaDisponibilitaClone.forEach((old, i) => {
         if (listaDisponibilita2.findIndex((o) => o.giorno === old.giorno) === -1) {
           old.disponibile = false;
           listaDisponibilita2.push(old);
         }
       });*/

      let obj = {
        greenPass,
        listaNazioni,
        listaDisponibilita: listaDisponibilita2,
      };
      if (greenPass) {
        obj.dataScadenzaGreenPass = dataScadenzaGreenPassSQL;
      }

      dispatch(putDatiDisponibilitaAsync(obj));
    }
  };

  const translateSelectNation = (value) => {
    if (strings.getLanguage() === "en") {
      if (value === "Cipro") {
        return "Cyprus";
      }
      if (value === "Belgio") {
        return "Belgium";
      }
      if (value === "Croazia") {
        return "Croatia";
      }
      if (value === "Danimarca") {
        return "Denmark";
      }
      if (value === "Finlandia") {
        return "Finland";
      }
      if (value === "Francia") {
        return "France";
      }
      if (value === "Germania") {
        return "Germany";
      }
      if (value === "Grecia") {
        return "Greece";
      }
      if (value === "Irlanda") {
        return "Ireland";
      }
      if (value === "Italia") {
        return "Italy";
      }
      if (value === "Lettonia") {
        return "Latvia";
      }
      if (value === "Lituania") {
        return "Lithuania";
      }
      if (value === "Lussemburgo") {
        return "Luxembourg";
      }
      if (value === "Paesi Bassi") {
        return "Netherlands";
      }
      if (value === "Polonia") {
        return "Poland";
      }
      if (value === "Portogallo") {
        return "Portugal";
      }
      if (value === "Repubblica Ceca") {
        return "Czech Republic";
      }
      if (value === "Slovacchia") {
        return "Slovakia";
      }
      if (value === "Spagna") {
        return "Spain";
      }
      if (value === "Svezia") {
        return "Sweden";
      }
      if (value === "Ungheria") {
        return "Hungary";
      }
    }
    return value;
  };

  return (
    <div>
      <ResponseAlertMessages
        loadResponse={disponibilitaResponse}
        response={disponibilitaSaveResponse}
      ></ResponseAlertMessages>

      <br />
      <Typography align={"center"}>
        <h3>{strings.availability}</h3>
      </Typography>
      <Alert severity='info'>{strings.calendarTip}</Alert>
      <hr />
      {disponibilitaEvents.length > 0 ? (
        <FullCalendar
          width={600}
          validRange={{ start: dateStart, end: dateEnd }}
          height={600}
          dateClick={handleDateClick}
          eventClick={handleEventClick}
          events={disponibilitaEvents}
          locale={fcLocale}
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView='dayGridMonth'
        />
      ) : (
        ""
      )}

      {disponibilitaEvents.length === 0 ? (
        <FullCalendar
          width={600}
          validRange={{ start: dateStart, end: dateEnd }}
          height={600}
          dateClick={handleDateClick}
          eventClick={handleEventClick}
          locale={fcLocale}
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView='dayGridMonth'
        />
      ) : (
        ""
      )}
      <br />
      <Typography align={"center"}>
        <h3>{strings.workingArea}</h3>
      </Typography>

      {disponibilitaResponse?.respdata ? (
        <form component='form' sx={FORM_INTERNAL_SX} onSubmit={onSubmit}>
          <Stack
            className='muiStackFix'
            spacing={2}
            direction='column'
            sx={COMMON_SX}
          >
            <FormControl required variant='standard' fullWidth>
              <InputLabel id='nazionerif1-utente-label'>
                {strings.nazRif} 1
              </InputLabel>
              <Select
                required
                variant='standard'
                disabled={disabled}
                labelId='nazionerif1-utente-label'
                value={nazionerif1}
                label={strings.nazRif + " 1"}
                onChange={(event) => setNazioneRif1(event.target.value)}
              >
                <MenuItem value={-1}>{strings.selectAnOption}</MenuItem>
                {dominioNazioniResp?.respdata?.data.map((o, i) => {
                  return (
                    <MenuItem value={o.id_Nazione}>{translateSelectNation(o.nome_Nazione)}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            {dominioNazioniResp?.respdata?.data?.find(
              (o) => o.id_Nazione === nazionerif1
            )?.regioni.length > 0 ? (
              <Autocomplete
                options={
                  dominioNazioniResp?.respdata?.data?.find(
                    (o) => o.id_Nazione === nazionerif1
                  )?.regioni
                }
                getOptionLabel={(option) => option.nome_Regione}
                value={dominioNazioniResp?.respdata?.data
                  ?.find((o) => o.id_Nazione === nazionerif1)
                  ?.regioni.find((o) => o.id_Regione === regionerif1)}
                onChange={(event, newValue) => {
                  setRegioneRif1(newValue ? newValue.id_Regione : -1);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    variant='standard'
                    fullWidth
                    label={strings.region}
                    disabled={disabled}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            ) : (
              ""
            )}

            <Autocomplete
              options={dominioNazioniResp?.respdata?.data}
              getOptionLabel={(option) => translateSelectNation(option.nome_Nazione)}
              value={dominioNazioniResp?.respdata?.data.find(
                (o) => o.id_Nazione === nazionerif2
              )}
              onChange={(event, newValue) => {
                setNazioneRif2(newValue ? newValue.id_Nazione : -1);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='standard'
                  fullWidth
                  label={strings.nazRif + " 2"}
                  disabled={disabled}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />

            {dominioNazioniResp?.respdata?.data?.find(
              (o) => o.id_Nazione === nazionerif2
            )?.regioni.length > 0 ? (
              <Autocomplete
                options={
                  dominioNazioniResp?.respdata?.data?.find(
                    (o) => o.id_Nazione === nazionerif2
                  )?.regioni
                }
                getOptionLabel={(option) => option.nome_Regione}
                value={dominioNazioniResp?.respdata?.data
                  ?.find((o) => o.id_Nazione === nazionerif2)
                  ?.regioni.find((o) => o.id_Regione === regionerif2)}
                onChange={(event, newValue) => {
                  setRegioneRif2(newValue ? newValue.id_Regione : -1);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='standard'
                    fullWidth
                    label={strings.region}
                    disabled={disabled}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            ) : (
              ""
            )}

            <Autocomplete
              options={dominioNazioniResp?.respdata?.data}
              getOptionLabel={(option) => translateSelectNation(option.nome_Nazione)}
              value={dominioNazioniResp?.respdata?.data.find(
                (o) => o.id_Nazione === nazionerif3
              )}
              onChange={(event, newValue) => {
                setNazioneRif3(newValue ? newValue.id_Nazione : -1);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='standard'
                  fullWidth
                  label={strings.nazRif + " 3"}
                  disabled={disabled}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />

            {dominioNazioniResp?.respdata?.data?.find(
              (o) => o.id_Nazione === nazionerif3
            )?.regioni.length > 0 ? (
              <Autocomplete
                options={
                  dominioNazioniResp?.respdata?.data?.find(
                    (o) => o.id_Nazione === nazionerif3
                  )?.regioni
                }
                getOptionLabel={(option) => option.nome_Regione}
                value={dominioNazioniResp?.respdata?.data
                  ?.find((o) => o.id_Nazione === nazionerif3)
                  ?.regioni.find((o) => o.id_Regione === regionerif3)}
                onChange={(event, newValue) => {
                  setRegioneRif3(newValue ? newValue.id_Regione : -1);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='standard'
                    fullWidth
                    label={strings.region}
                    disabled={disabled}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            ) : (
              ""
            )}

            <FormControlLabel
              style={{ display: "none" }}
              labelPlacement='start'
              control={
                <Switch
                  checked={greenPass}
                  onChange={(event) => {
                    setGreenPass(event.target.checked);
                  }}
                />
              }
              label='Green Pass'
            />

            {greenPass ? (
              <>
                <h5>Data scadenza green pass</h5>
                <LocalizationProvider
                  locale={dpLocale}
                  dateAdapter={AdapterDateFns}
                >
                  <DatePicker
                    label={strings.expireDateGp}
                    disabled={disabled}
                    value={dataScadenzaGreenPass}
                    minDate={new Date()}
                    inputFormat={
                      strings.getLanguage() === "it"
                        ? "dd/MM/yyyy"
                        : "MM/dd/yyyy"
                    }
                    onChange={(val) => setDataScadenzaGreenPass(val)}
                    renderInput={(params) => (
                      <TextField variant='standard' {...params} fullWidth />
                    )}
                  />
                </LocalizationProvider>
              </>
            ) : (
              ""
            )}

            <Button type='submit' disabled={disabled} variant='contained'>
              {strings.save}
            </Button>
          </Stack>
        </form>
      ) : (
        <div className='loaderDiv'>
          <CircularProgress></CircularProgress>
        </div>
      )}
    </div>
  );
}
