import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { IconButton } from "@mui/material";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../logo-bb.png";
import {
  FORM_INTERNAL_SX,
  MESSAGES2_SX,
  STACK_SX,
  strings,
} from "./../../Constants";
import {
  doLoginAsync,
  selectLoginData,
  selectResponseLogin,
} from "./welcomeSlice";

export default function Login() {
  const dispatch = useDispatch();
  const response = useSelector(selectResponseLogin);
  const loginData = useSelector(selectLoginData);
  const navigate = useNavigate();
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    // console.log(loginData);
    if (loginData?.username) {
      navigate("/home", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginData]);

  useEffect(() => {
    //console.log(loginData);
    setDisabled(false);
  }, [response]);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!disabled) {
      setDisabled(true);
      // console.log(username,password,email);
      dispatch(doLoginAsync({ username, password }));
    }
  };

  return (
    <div>
      <br />
      <div>
        {response?.respdata?.success ? (
          <Alert sx={MESSAGES2_SX} severity='success'>
            {"Login avvenuto"}{" "}
          </Alert>
        ) : (
          ""
        )}

        {response?.respdata?.success === false ? (
          <Alert sx={MESSAGES2_SX} severity='info'>
            {response?.respdata?.message}{" "}
          </Alert>
        ) : (
          ""
        )}

        {response && response.error && response.error.responseJSON ? (
          <Alert sx={MESSAGES2_SX} severity='error'>
            {response.error.responseJSON.data.title}{" "}
          </Alert>
        ) : (
          ""
        )}

        {response && response.error && response.error.responseJSON
          ? response.error.responseJSON.modelValidationError.map((o, i) => (
              <div>
                <br />
                <Alert sx={MESSAGES2_SX} severity='error'>
                  {o}{" "}
                </Alert>
              </div>
            ))
          : ""}
      </div>
      <br />
      <form component='form' sx={FORM_INTERNAL_SX} onSubmit={onSubmit}>
        <Stack spacing={2} direction='column' sx={STACK_SX}>
          <img
            alt='logo'
            src={logo}
            style={{ margin: "0 auto", width: "60%", height: "auto" }}
          />

          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <h3 className='colorPrimary'>{strings.welcomeMessage}</h3>
          </Box>

          <TextField
            required
            label='Username'
            variant='standard'
            defaultValue=''
            onChange={(event) => setUsername(event.target.value)}
          />
          <TextField
            required
            label='Password'
            variant='standard'
            type={showPassword ? "text" : "password"}
            autoComplete='current-password'
            onChange={(event) => setPassword(event.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                    onMouseDown={handleMouseDownPassword}
                    edge='end'
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <br />
          <Link className='colorGray fw600' to='/forgot-password'>
            <small>{strings.forgotPassword}</small>
          </Link>
          <Button type='submit' disabled={disabled} variant='contained'>
            {strings.getLanguage() !== "en" ? strings.login : "LOGIN NOW"}
          </Button>
          <br />
          <br />
          <br />
          <br />
          <Button
            component={Link}
            to='/register'
            variant='contained'
            color='primary'
          >
            {strings.getLanguage() !== "en" ? strings.register : "SIGN IN"}
          </Button>
        </Stack>
      </form>
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}
