import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { IconButton } from "@mui/material";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { default as React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../logo-bb.png";
import {
  FORM_INTERNAL_SX,
  MESSAGES2_SX,
  STACK_SX,
  strings,
} from "./../../Constants";
import {
  selectLoginData,
  selectResponseChangePassword,
  changePasswordAsync,
} from "./../welcome/welcomeSlice";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Modal from "@mui/material/Modal";
import ResponseAlertMessages from "./../commons/ResponseAlertMessages";

export default function ChangePassword() {
  const dispatch = useDispatch();
  const response = useSelector(selectResponseChangePassword);
  const loginData = useSelector(selectLoginData);
  const navigate = useNavigate();
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [newPassword2, setNewPassword2] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [acceptUseImages, setAcceptUseImages] = useState(false);

  const [openTermAndCondictionsModal, setOpenTermAndCondictionsModal] =
    useState(false);

  const [email, setEmail] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const showTermsAndConditions = () => {
    setOpenTermAndCondictionsModal(true);
  };

  const handleCloseTermAndCondictionsModal = () =>
    setOpenTermAndCondictionsModal(false);

  useEffect(() => {
    //console.log(loginData);
    if (!loginData?.username) {
      navigate("/home", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginData]);

  useEffect(() => {
    //console.log(loginData);
    if (newPassword == newPassword2 && newPassword !== null) {
      setDisabled(false);
    }
    //console.log(response);
    if (response?.respdata?.success) {
      //navigate("/home", { replace: true });
    }
  }, [response]);

  useEffect(() => {
    //console.log(loginData);
    if (newPassword == newPassword2 && newPassword !== null) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [newPassword, newPassword2]);

  const onSubmit = (e) => {
    e.preventDefault();
    if (!disabled) {
      setDisabled(true);
      // console.log(username,password,email);
      dispatch(
        changePasswordAsync({ oldPassword: password, password: newPassword })
      );
    }
    // console.log(username,password,email);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "clamp(300px, 50%, 500px)",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <div>
      <br />
      <ResponseAlertMessages response={response} />
      <br />
      <form component='form' sx={FORM_INTERNAL_SX} onSubmit={onSubmit}>
        <Stack spacing={2} direction='column' sx={STACK_SX}>
          <TextField
            required
            label={strings.oldPassword}
            variant='standard'
            type={showPassword ? "text" : "password"}
            autoComplete='current-password'
            onChange={(event) => setPassword(event.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                    onMouseDown={handleMouseDownPassword}
                    edge='end'
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <TextField
            required
            label={strings.newPassword}
            variant='standard'
            type={showPassword ? "text" : "password"}
            autoComplete='new-password'
            onChange={(event) => setNewPassword(event.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                    onMouseDown={handleMouseDownPassword}
                    edge='end'
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <TextField
            required
            label={strings.newPasswordRepeat}
            variant='standard'
            type={showPassword ? "text" : "password"}
            autoComplete='new-password-2'
            onChange={(event) => setNewPassword2(event.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                    onMouseDown={handleMouseDownPassword}
                    edge='end'
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Button type='submit' disabled={disabled} variant='contained'>
            {strings.changepassword}
          </Button>
        </Stack>
      </form>

      <br />
      <br />
      <br />
      <br />
    </div>
  );
}
