import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getDomainElement } from './domainAPI';

const initialState = {
  respPagamenti:null,
  respDomainNazioni:null,
  respDomainLingue:null,
  respDomainLivelloLingue:null,
  respDomainRuoli: null,
  stauts: 'idle'
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getDomainNazioniAsync = createAsyncThunk(
  'domain/getNazioni',
  async (params) => {
    const response = await getDomainElement('Nazioni');
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const getDomainLingueAsync = createAsyncThunk(
  'domain/getLingue',
  async (params) => {
    const response = await getDomainElement('Lingue');
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);
export const getDomainLivelloLingueAsync= createAsyncThunk(
  'domain/getLivelloLingue',
  async (params) => {
    const response = await getDomainElement('LivelloLingue');
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const getDomainRuoliAsync= createAsyncThunk(
  'domain/getRuoli',
  async (params) => {
    const response = await getDomainElement('Ruoli');
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);





export const domainSlice = createSlice({
  name: 'domain',
  initialState,
  // The `reduce:rs` field lets us define reducers and generate associated actions
  reducers: {
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder

    .addCase(getDomainNazioniAsync.pending, (state) => {
      state.status = 'loading';
    })

    .addCase(getDomainNazioniAsync.fulfilled, (state, action) => {
      state.status = 'idle';
      state.respDomainNazioni = action.payload;
    })

    .addCase(getDomainLingueAsync.pending, (state) => {
      state.status = 'loading';
    })

    .addCase(getDomainLingueAsync.fulfilled, (state, action) => {
      state.status = 'idle';
      state.respDomainLingue = action.payload;
    })


    .addCase(getDomainLivelloLingueAsync.pending, (state) => {
      state.status = 'loading';
    })

    .addCase(getDomainLivelloLingueAsync.fulfilled, (state, action) => {
      state.status = 'idle';
      state.respDomainLivelloLingue = action.payload;
    })

      .addCase(getDomainRuoliAsync.pending, (state) => {
        state.status = 'loading';
      })

      .addCase(getDomainRuoliAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.respDomainRuoli = action.payload;
      })

 
      ;
      
      
  },
});

//export const { resetRespPostPagamenti, resetRespPostSubscription } = subscriptionSlice.actions;


export const selectRespDomainNazioni = (state) => state.domain.respDomainNazioni;
export const selectRespDomainLingue = (state) => state.domain.respDomainLingue;
export const selectRespDomainLivelloLingue = (state) => state.domain.respDomainLivelloLingue;
export const selectRespDomainRuoli = (state) => state.domain.respDomainRuoli;


export default domainSlice.reducer;
