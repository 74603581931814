import jQuery from "jquery";
import { BASE_API_URL } from "../../Constants.js";

export function getJobs() {
  return new Promise((resolve) =>
    jQuery
      .ajax({
        type: "GET",
        url: BASE_API_URL + "/api/Jobs/RecuperaJobs",
        // dataType: "json",
        //contentType: "application/json; charset=utf-8",
        // data: JSON.stringify({ username: username, password: password }),
      })
      .done((response) => resolve({ respdata: response }))
      .always((error) => resolve({ error }))
  );
}

export function getIngaggiInviati(job, page, per_page) {
  console.log("GET INGAGGI INVIATI", job);
  return new Promise((resolve) =>
    jQuery
      .ajax({
        type: "GET",
        url: BASE_API_URL + "/api/Jobs/RecuperaIngaggi/Inviati",
        // dataType: "json",
        //contentType: "application/json; charset=utf-8",
        data: { job, page, per_page },
      })
      .done((response) => resolve({ respdata: response }))
      .always((error) => resolve({ error }))
  );
}

export function getIngaggiAccettati(job, page, per_page) {
  console.log("GET INGAGGI ACCETTATI", job);
  return new Promise((resolve) =>
    jQuery
      .ajax({
        type: "GET",
        url: BASE_API_URL + "/api/Jobs/RecuperaIngaggi/Accettati",
        // dataType: "json",
        //contentType: "application/json; charset=utf-8",
        data: { job, page, per_page },
      })
      .done((response) => resolve({ respdata: response }))
      .always((error) => resolve({ error }))
  );
}

export function deleteJob(id) {
  return new Promise((resolve) =>
    jQuery
      .ajax({
        type: "DELETE",
        url: BASE_API_URL + "/api/Jobs/CancellaJob/" + id,
        // dataType: "json",
        //contentType: "application/json; charset=utf-8",
        // data: JSON.stringify({ username: username, password: password }),
      })
      .done((response) => resolve({ respdata: response }))
      .always((error) => resolve({ error }))
  );
}

export function putJob(params) {
  return new Promise((resolve) =>
    jQuery
      .ajax({
        type: "PUT",
        url: BASE_API_URL + "/api/Jobs/AggiornaJob",
        data: JSON.stringify(params),
        // dataType: "json",
        contentType: "application/json; charset=utf-8",
        // data: JSON.stringify({ username: username, password: password }),
      })
      .done((response) => resolve({ respdata: response }))
      .always((error) => resolve({ error }))
  );
}

export function postJob(params) {
  return new Promise((resolve) =>
    jQuery
      .ajax({
        type: "POST",
        url: BASE_API_URL + "/api/Jobs/CreaJob",
        data: JSON.stringify(params),
        // dataType: "json",
        contentType: "application/json; charset=utf-8",
        // data: JSON.stringify({ username: username, password: password }),
      })
      .done((response) => resolve({ respdata: response }))
      .always((error) => resolve({ error }))
  );
}
