import CallMade from "@mui/icons-material/CallMade";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LocalOffer from "@mui/icons-material/LocalOffer";
import Person from "@mui/icons-material/Person";
import ShareIcon from "@mui/icons-material/Share";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Favorite from "./Favorite";
import {
  resetRespShareCategoryEmail,
  selectRespPostCategoryShareEmail,
  deleteCategoryAsync,
  deleteFavoriteAsync,
  getCategoryAsync,
  postCondividiCategoriaEmailAsync,
  resetRespFavorites,
  selectRespDeleteCategory,
  selectRespDeleteFavorite,
  selectRespGetCategory,
} from "./favoriteSlice";
import { strings } from "../../Constants";
import ResponseAlertMessages from "../commons/ResponseAlertMessages";
import Loader from "../commons/Loader";

export default function Category(props) {
  const dispatch = useDispatch();

  const [expanded, setExpanded] = React.useState(false);
  const respCondividiCategoriaEmail = useSelector(
    selectRespPostCategoryShareEmail
  );

  const [categoryFavorites, setCategoryFavorites] = React.useState([]);
  const [favoritesCount, setFavoritesCount] = React.useState(
    props.category.num_Inseriti
  );

  const [currentCategory, setCurrentCategory] = React.useState(props.category);
  const respDeleteCategory = useSelector(selectRespDeleteCategory);
  const respGetCategory = useSelector(selectRespGetCategory);
  const respDeleteFavorite = useSelector(selectRespDeleteFavorite);

  const [emailShare, setEmailShare] = React.useState(null);
  const [nameShare, setNameShare] = React.useState(null);
  const [surnameShare, setSurnameShare] = React.useState(null);
  const [categoryIdShare, setCategoryIdShare] = React.useState(null);
  const [shareDone, setShareDone] = React.useState(false);

  const [openShareModal, setOpenShareModal] = React.useState(false);
  const handleOpenShareModal = () => setOpenShareModal(true);
  const handleCloseShareModal = () => setOpenShareModal(false);

  useEffect(() => {
    if (respDeleteFavorite?.respdata?.success) {
      dispatch(getCategoryAsync(props.category.id_CategoriaPreferiti));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [respDeleteFavorite]);

  useEffect(() => {
    console.log("INSIDE USE-EFFECT OF RESP-GET-CATEGORY", respGetCategory);
    console.log("PROPS", props);
    if (
      respGetCategory?.respdata?.success &&
      respGetCategory?.respdata?.data.categoria?.id_CategoriaPreferiti ===
        props.category.id_CategoriaPreferiti
    ) {
      console.log("SETTING CURRENT CATEGORY");
      setCurrentCategory(respGetCategory?.respdata?.data?.categoria);
      setCategoryFavorites(respGetCategory?.respdata?.data?.preferiti);
      setFavoritesCount(respGetCategory?.respdata?.data?.preferiti?.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [respGetCategory]);

  const handleExpand = (panel) => (event, isExpanded) => {
    if (isExpanded) {
      dispatch(getCategoryAsync(props.category.id_CategoriaPreferiti));
    }
    setExpanded(isExpanded ? panel : false);
  };

  const handleDeleteCategory = () => {
    const payload = {
      categoria: props.category.id_CategoriaPreferiti,
    };
    dispatch(deleteCategoryAsync(payload));
    console.log("INSIDE HANDLE-DELETE-CATEGORY");
  };

  useEffect(() => {
    if (respDeleteCategory?.respdata?.success)
      props.onRemoveCategory(
        respDeleteCategory.respdata.data.id_CategoriaPreferiti
      );
  }, [dispatch, respDeleteCategory]);

  const handleDeleteFavorite = (userId, categoryId) => {
    dispatch(resetRespFavorites());
    const payload = {
      userId: userId,
      categoria: categoryId,
    };
    dispatch(deleteFavoriteAsync(payload));
  };

  const shareCategoryPopup = (e, catId) => {
    e.stopPropagation();
    e.preventDefault();
    setEmailShare(null);
    setSurnameShare(null);
    setNameShare(null);
    dispatch(resetRespShareCategoryEmail());
    setOpenShareModal(true);
    setShareDone(false);
    setCategoryIdShare(catId);
  };

  const shareCategory = (e) => {
    console.log("ciaoo filippo");

    e.preventDefault();
    setShareDone(true);
    const payload = {
      id_Categoria: categoryIdShare,
      email: emailShare,
      nome: nameShare,
      cognome: surnameShare,
    };
    dispatch(postCondividiCategoriaEmailAsync(payload));
  };

  const respPostCategoryShareEmail = useSelector(
    selectRespPostCategoryShareEmail
  );

  useEffect(() => {
    if (!respPostCategoryShareEmail?.respdata?.success) {
      setShareDone(false);
    }
  }, [respPostCategoryShareEmail]);

  console.log("SALVE", respPostCategoryShareEmail);

  useEffect(() => {
    if (props.category.condiviso_Con.length !== 0) props.onSet(true);
  }, [props.category.condiviso_Con]);

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "clamp(300px, 50%, 500px)",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <Accordion
        expanded={expanded}
        onChange={handleExpand("panel" + props.category.id_CategoriaPreferiti)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={"panel" + props.category.id_CategoriaPreferiti}
          id={"panel" + props.category.id_CategoriaPreferiti}
        >
          <Grid
            container
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 2 }}
          >
            <Grid item xs={6}>
              <LocalOffer />
              &nbsp;
              {props.category.nome}
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Badge
              onClick={(event) => {
                shareCategoryPopup(event, props.category.id_CategoriaPreferiti);
              }}
              badgeContent={null}
              color='primary'
            >
              <ShareIcon />
            </Badge>
          </Grid>
          <Grid item xs={3}>
            <Badge badgeContent={favoritesCount} color='primary'>
              <Person />
            </Badge>
          </Grid>
          <Grid item xs={3}>
            <Badge
              badgeContent={props.category.condiviso_Con.length}
              color='primary'
            >
              <CallMade />
            </Badge>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <Button
              disabled={props?.category?.condiviso_Con.length !== 0}
              onClick={handleDeleteCategory}
            >
              {strings.getLanguage() !== "en"
                ? "Rimuovi categoria"
                : "Delete category"}
            </Button>
            {categoryFavorites?.map((favorite) => {
              return (
                <Favorite
                  favorite={favorite.preferito}
                  category={props.category.id_CategoriaPreferiti}
                  handleDeleteFavorite={handleDeleteFavorite}
                  canDelete={true}
                />
              );
            })}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Modal
        open={openShareModal}
        onClose={handleCloseShareModal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={modalStyle}>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            {strings.shareCategoryWith}
          </Typography>
          <br />
          <form component='form' onSubmit={shareCategory}>
            <FormControl variant={"standard"} fullWidth>
              <TextField
                required
                type='email'
                placeholder={
                  emailShare === "" ? strings.emailPlaceholder : emailShare
                }
                disabled={shareDone}
                label={strings.email}
                variant='standard'
                value={emailShare}
                onChange={(event) => setEmailShare(event.target.value)}
              />
            </FormControl>
            <FormControl variant={"standard"} fullWidth>
              <TextField
                required
                disabled={shareDone}
                label={strings.name}
                variant='standard'
                value={nameShare}
                onChange={(event) => setNameShare(event.target.value)}
              />
            </FormControl>

            <FormControl variant={"standard"} fullWidth>
              <TextField
                required
                disabled={shareDone}
                label={strings.surname}
                variant='standard'
                value={surnameShare}
                onChange={(event) => setSurnameShare(event.target.value)}
              />
            </FormControl>
            <br />
            <br />
            <Button type='submit' variant='contained' disabled={shareDone}>
              {strings.shareCategory}
            </Button>
          </form>

          <ResponseAlertMessages
            response={respCondividiCategoriaEmail}
          ></ResponseAlertMessages>
        </Box>
      </Modal>
    </>
  );
}
