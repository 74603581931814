import Stack from "@mui/material/Stack";
import React from "react";
import NumberFormat from "react-number-format";
import { strings, lMoment } from "../../Constants";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useDispatch } from "react-redux";
import { putSubscriptionAsync } from "./subscriptionSlice";
import { Button, ButtonGroup } from "@mui/material";

export default function Abbonamento(props) {
  const dispatch = useDispatch();

  const handleActiveAuto = (abbonamentoId, value) => {
    dispatch(
      putSubscriptionAsync({
        id_Abbonamento: abbonamentoId,
        pagamento_automatico: value,
      })
    );
  };

  return (
    <>
      <div className='subscriptionBox'>
        <h3>{props.abbonamento.nomeAbbonamento}</h3>
        <br />
        <Stack
          rowGap={2}
          direction='column'
          sx={{ m: "0 auto", width: "auto" }}
        >
          <NumberFormat
            value={props.abbonamento.prezzo}
            className='costoAbbonamento'
            displayType={"text"}
            thousandSeparator={strings.thousandSeparator}
            decimalSeparator={strings.decimalSeparator}
            decimalScale={2}
            fixedDecimalScale={true}
            prefix={strings.valutaPrefix}
            renderText={(value, props2) => <div {...props2}>{value}</div>}
          />
          {props.actual ? (
            <div className='durataAbbonamento'>
              {strings.endOfSubscription}:{" "}
              {lMoment(props.abbonamento.dataFineAbbonamento).fromNow()}
            </div>
          ) : (
            <div className='durataAbbonamento'>
              {strings.duration}: {props.abbonamento.durataAbbonamento}{" "}
              {props.abbonamento.durataAbbonamento === 1
                ? strings.month
                : strings.months}
            </div>
          )}
          {/*<Button onClick={() => { props.onAttivaClick(props.abbonamento) }} variant="contained">{strings.activate}</Button>*/}
          {props.recap === true || props.actual === true ? (
            ""
          ) : (
            <div className='abbonamentoSwitch'>
              <FormControlLabel
                className='attivaAbbonamento'
                labelPlacement='start'
                control={
                  <Switch
                    checked={false}
                    onChange={(event) => {
                      if (event.target.checked) {
                        props.onAttivaClick(props.abbonamento);
                      }
                    }}
                  />
                }
                label={strings.activateSubscription}
              />
            </div>
          )}
          {props.actual ? (
            <div className='abbonamentoSwitch'>
              <FormControlLabel
                className='attivaAbbonamento'
                labelPlacement='start'
                control={
                  <Switch
                    checked={props.abbonamento.pagamentoAutomatico}
                    onChange={(event) => {
                      handleActiveAuto(
                        props.abbonamento.id_Abbonamento,
                        event.target.checked
                      );
                    }}
                  />
                }
                label={strings.automaticRenewal}
              />
            </div>
          ) : (
            ""
          )}
        </Stack>
      </div>
    </>
  );
}
