import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router";
import BackGroundHome from "../../BackGroundHome-optimized.jpg";
import freccia from "../../Tracciato1.png";
import logo from "../../MyCrew_Choice_intermediate.png";
import "./HomePage.scss";
import { Box, Button, ButtonGroup } from "@mui/material";
import { strings } from "../../Constants";

const HomePage = () => {
  const navigate = useNavigate();
  const buttonRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const scrollToButton = () => {
    if (buttonRef.current) {
      buttonRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <>
      <div
        className="div-absolute"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          backgroundImage: `url(${BackGroundHome})`,
        }}
      >
        {/* <img className="home-image" src={BackGroundHome} alt="Home" /> */}
        <img className="logo-absolute" src={logo} alt="logo" />
      </div>
      <Box
        className="bounce"
        onClick={() => scrollToButton()}
        position="absolute"
        right="50%"
        left="50%"
        display="flex"
        bottom="12vh"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        gap="10px"
      >
        <div className="pointer clickable-fix" onClick={() => scrollToButton()}>
          <span
            style={{
              color: "#FFFFFF",
              fontSize: "18px",
            }}
          >
            SCROLL
          </span>
          <img
            onClick={() => scrollToButton()}
            className="child bounce"
            style={{
              width: "55px",
              height: "27px",
              marginBottom: "20px",
              marginTop: "16px",
            }}
            src={freccia}
            alt="icon"
          />
        </div>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        marginTop="70px"
      >
        <Box width="70%">
          <p className="color-black title">YOU WIN, WE WIN.</p>
          <p className="subtitle">
            <strong>{strings.homepageHeroTitle}</strong>
          </p>
          <p
            style={{ fontSize: "18px", fontWeight: "400" }}
            className="color-black"
          >
            {strings.homepageHeroText}
          </p>

          <div
            ref={buttonRef}
            className="button-group"
            style={{ marginTop: "33px" }}
          >
            <div onClick={() => navigate("/login")}>
              {strings.login.toUpperCase()}
            </div>
            |
            <div onClick={() => navigate("/register")}>
              {strings.register.toUpperCase()}
            </div>
          </div>
        </Box>
      </Box>
      <div
        style={{
          marginTop: "40px",
          display: "flex",
          justifyContent: "space-evenly",
        }}
      >
        <div>
          <a
            href="https://play.google.com/store/apps/details?id=com.setout.mycrew&hl=it&gl=US"
            about="blank"
            tabIndex="0"
          >
            <img
              class="bn45"
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Google_Play_Store_badge_EN.svg/2560px-Google_Play_Store_badge_EN.svg.png"
              alt="bn45"
            />
          </a>
        </div>
        <div>
          <a
            href="https://apps.apple.com/it/app/mycrew/id6447298032"
            about="blank"
            tabIndex="0"
          >
            <img
              class="bn46"
              src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
              alt="bn45"
            />
          </a>
        </div>
      </div>

      <Box
        width="100vw"
        height="200px"
        backgroundColor="#1CA8E3"
        marginTop="70px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        color="#FFFFFF"
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="70%"
        >
          <div style={{ display: "flex", gap: "20px" }}>
            <a
              href="https://www.iubenda.com/privacy-policy/70459956"
              class="iubenda-black iubenda-noiframe iubenda-embed iubenda-noiframe "
              title="Privacy Policy "
            >
              Privacy Policy
            </a>
            <script type="text/javascript">
              {(function (w, d) {
                var loader = function () {
                  var s = d.createElement("script"),
                    tag = d.getElementsByTagName("script")[0];
                  s.src = "https://cdn.iubenda.com/iubenda.js";
                  tag.parentNode.insertBefore(s, tag);
                };
                if (w.addEventListener) {
                  w.addEventListener("load", loader, false);
                } else if (w.attachEvent) {
                  w.attachEvent("onload", loader);
                } else {
                  w.onload = loader;
                }
              })(window, document)}
              ;
            </script>
            <p
              className="pointer"
              onClick={() => window.open(strings.brochureUrl, "about:blank")}
            >
              DOWNLOAD BROCHURE
            </p>
            <p
              className="pointer"
              onClick={() => window.open(strings.guideUrl, "about:blank")}
            >
              DOWNLOAD GUIDE
            </p>
            <p
              className="pointer"
              onClick={() =>
                (window.location = "mailto:segreteria@mycrewitaly.com")
              }
            >
              {strings.getLanguage() === "en" ? "CONTACT US" : "CONTATTACI"}
            </p>
            <p
              className="pointer"
              onClick={() =>
                (window.location = "https://mycrewitaly.com/privacy")
              }
            >
              PRIVACY
            </p>
            <p
              className="pointer"
              onClick={() =>
                (window.location = "https://mycrewitaly.com/terms")
              }
            >
              {strings.getLanguage() === "en" ? "TERMS" : "TERMINI"}
            </p>
            <p
              className={
                "pointer " +
                (strings.getLanguage() === "en" ? "activeLanguage" : "language")
              }
              onClick={() => {
                localStorage.setItem("activeLanguage", "en");
                strings.setLanguage("en");
                window.location.reload(true);
              }}
            >
              EN
            </p>
            <p
              className={
                "pointer " +
                (strings.getLanguage() === "it" ? "activeLanguage" : "language")
              }
              onClick={() => {
                localStorage.setItem("activeLanguage", "it");
                strings.setLanguage("it");
                window.location.reload(true);
              }}
            >
              IT
            </p>
          </div>
          <div style={{ maxWidth: "30%" }}>
            <p style={{ textAlign: "right", fontSize: "small" }}>
              MyCrew è un servizio offerto da Setout Srl <br /> via degli Orti
              Gianicolensi, 5 <br /> 00152 Roma · Italia <br /> © 2023. Tutti i
              diritti sono riservati.
            </p>
          </div>
        </Box>
      </Box>
    </>
  );
};

export default HomePage;
