import React from "react";
import { strings } from "../../Constants";
import parse from "html-react-parser";

export const Terms = (props) => {
  React.useEffect(() => {
    document.body.classList.add("no-ads");
  }, []);

  React.useEffect(
    () => () => {
      document.body.classList.remove("no-ads");
      console.log("terms unmount");
    },
    []
  );

  return (
    <div className='padded-20'>
      {/*  <h1>{strings.termAndCondsTitle}</h1>
       */}{" "}
      <p>{parse(strings.termsAndConditions)}</p>
    </div>
  );
};
