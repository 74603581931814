import jQuery from "jquery";
import { BASE_API_URL } from "../../Constants.js";

export function postTimesheet(params) {
  return new Promise((resolve) =>
    jQuery
      .ajax({
        type: "POST",
        url: BASE_API_URL + "/api/Ingaggi/CompilaTimeReport",
        // dataType: "json",
        contentType: "application/json; charset=utf-8",
        data: JSON.stringify(params)
      })
      .done((response) => resolve({ respdata: response }))
      .always((error) => resolve({ error }))
  );
}

export function putTimesheet(params) {
  return new Promise((resolve) =>
    jQuery
      .ajax({
        type: "PUT",
        url: BASE_API_URL + "/api/Ingaggi/ModificaTimeReport",
        // dataType: "json",
        contentType: "application/json; charset=utf-8",
        data: JSON.stringify(params)
      })
      .done((response) => resolve({ respdata: response }))
      .always((error) => resolve({ error }))
  );
}

export function getTimesheets(id) {
  return new Promise((resolve) =>
    jQuery
      .ajax({
        type: "GET",
        url: BASE_API_URL + "/api/Ingaggi/RecuperaDatiTimeReport/" + id,
        // dataType: "json",
        contentType: "application/json; charset=utf-8"
      })
      .done((response) => resolve({ respdata: response }))
      .always((error) => resolve({ error }))
  );
}

export function sendTimesheet(params) {
  return new Promise((resolve) =>
    jQuery
      .ajax({
        type: "POST",
        url: BASE_API_URL + "/api/Ingaggi/InviaMailTimeReport",
        // dataType: "json",
        contentType: "application/json; charset=utf-8",
        data: JSON.stringify(params)
      })
      .done((response) => resolve({ respdata: response }))
      .always((error) => resolve({ error }))
  );
}
