import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { doSearch } from './searchAPI';

const initialState = {
  respSearch: localStorage.getItem("respSearch") ? JSON.parse(localStorage.getItem("respSearch")) : null,
  stauts: 'idle'
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const doSearchAsync = createAsyncThunk(
  'search/do',
  async (params) => {
    const response = await doSearch(params);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);



export const searchSlice  = createSlice({
  name: 'search',
  initialState,
  // The `reduce:rs` field lets us define reducers and generate associated actions
  reducers: {
    resetRespSearch: (state) => {
      state.respSearch = null;
    },
    resetRespFavorites: (state) => {
      state.resetRespFavorites = null;
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
   
      .addCase(doSearchAsync.pending, (state) => {
        state.status = 'loading';
      })

      .addCase(doSearchAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.respSearch = action.payload;
        localStorage.setItem("respSearch", JSON.stringify(action.payload)); 
      })

      ;
      
      
  },
});

export const { resetRespSearch } = searchSlice.actions;


export const selectRespSearch = (state) => state.search.respSearch;


export default searchSlice.reducer;
