import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import FullCalendar from "@fullcalendar/react"; // must go before plugins
import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Badge from "@mui/material/Badge";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import SocialDistance from "@mui/icons-material/SocialDistance";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import Moment from "moment";

import {
  COMMON_SX,
  dpLocale,
  fcLocale,
  FORM_INTERNAL_SX,
  strings,
  toSQLDate,
  formatDate,
  MESSAGES2_SX,
} from "./../../Constants";

import { selectLoginData } from "../welcome/welcomeSlice";
import {
  getEnlistmentsAsync,
  selectRespGetEnlistments,
} from "../enlistment/enlistmentSlice";
import {
  sendCarsheetAsync,
  selectRespSendCarsheet,
  postCarsheetAsync,
  selectRespPostCarsheet,
  putCarsheetAsync,
  selectRespPutCarsheet,
  getCarsheetsAsync,
  selectRespGetCarsheets,
} from "../carsheet/carsheetSlice";
import { Alert, Grid } from "@mui/material";
import "./Carsheet.scss";

export default function Carsheet(props) {
  const dispatch = useDispatch();

  const loggedUser = useSelector(selectLoginData);
  const respEnlistments = useSelector(selectRespGetEnlistments);
  const respCarsheets = useSelector(selectRespGetCarsheets);
  const respPostCarsheet = useSelector(selectRespPostCarsheet);
  const respPutCarsheet = useSelector(selectRespPutCarsheet);
  const respSendCarsheet = useSelector(selectRespSendCarsheet);
  const [enlistmentList, setEnlistmentList] = useState([]);
  const [carsheetList, setCarsheetList] = useState([]);
  const [carsheetId, setCarsheetId] = useState(0);
  const [recordSent, setRecordSent] = useState(false);

  const [events, setEvents] = useState([]);
  const [enlistmentId, setEnlistmentId] = useState(-1);
  const [routes, setRoutes] = useState("");
  const [distance, setDistance] = useState(0);
  const [model, setModel] = useState("");
  const [plate, setPlate] = useState("");
  const [fuelprice, setFuelprice] = useState(0);
  const [date, setDate] = useState(Moment(new Date()).format("YYYY-MM-DD"));
  const [dateStart, setDateStart] = useState(
    Moment(new Date()).format("YYYY-MM-DD")
  );
  const [dateEnd, setDateEnd] = useState(
    Moment(new Date()).endOf("month").add(1, "days").format("YYYY-MM-DD")
  );
  const [mode, setMode] = useState("add");
  const [showSendButton, setShowSendButton] = useState(false);

  const resetFormData = () => {
    setRoutes("");
    setDistance(0);
    setModel("");
    setPlate("");
    setFuelprice(0);
    setRecordSent(false);
  };
  const handleDateClick = (info) => {
    console.log("INSIDE HANDLE-DATE-CLICK", info);
    resetFormData();
    setMode("add");
    setDate(formatDate(info.dateStr));
    setOpenCarsheetModal(true);
  };

  const handleEventClick = (info) => {
    console.log("INSIDE HANDLE-EVENT-CLICK", info);
    setMode("update");
    setDate(formatDate(info.event.extendedProps.carsheetRecord.giorno));
    setRoutes(info.event.extendedProps.carsheetRecord.percorsiEffettuati);
    setDistance(info.event.extendedProps.carsheetRecord.kmPercorsi);
    setModel(info.event.extendedProps.carsheetRecord.modello);
    setPlate(info.event.extendedProps.carsheetRecord.targa);
    setFuelprice(info.event.extendedProps.carsheetRecord.costoBenzina);
    setCarsheetId(info.event.extendedProps.carsheetRecord.id_SchedeAuto);
    setRecordSent(info.event.extendedProps.carsheetRecord.inviato);
    setOpenCarsheetModal(true);
  };

  const renderEventContent = (eventInfo) => {
    console.log(eventInfo);
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {eventInfo.event.extendedProps.carsheetRecord.targa !== "" ? (
              <Typography>
                <DirectionsCarIcon color={"secondary"} />{" "}
                {eventInfo.event.extendedProps.carsheetRecord.targa}
              </Typography>
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={6}>
            {eventInfo.event.extendedProps.carsheetRecord.kmPercorsi > 0 ? (
              <Badge
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                badgeContent={
                  eventInfo.event.extendedProps.carsheetRecord.kmPercorsi + "km"
                }
                color='primary'
              >
                <SocialDistance color={"secondary"} />
              </Badge>
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={6}>
            {eventInfo.event.extendedProps.carsheetRecord.costoBenzina > 0 ? (
              <Badge
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                badgeContent={
                  eventInfo.event.extendedProps.carsheetRecord.costoBenzina +
                  " euro"
                }
                color='primary'
              >
                <LocalGasStationIcon color={"secondary"} />
              </Badge>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
        <hr />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {eventInfo.event.extendedProps.carsheetRecord.inviato === true
              ? strings.getLanguage() !== "en"
                ? "Record inviato"
                : "Record sended"
              : strings.getLanguage() !== "en"
              ? "Record da inviare"
              : "Record to send"}
          </Grid>
        </Grid>
      </>
    );
  };

  useEffect(() => {
    if (loggedUser) {
      dispatch(getEnlistmentsAsync());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUser]);

  useEffect(() => {
    if (respEnlistments?.respdata?.success) {
      setEnlistmentList(respEnlistments.respdata.data);
    }
  }, [respEnlistments]);

  useEffect(() => {
    console.log("INSIDE USEEFFECT OF GET-CARSHEETS", respCarsheets);
    if (respCarsheets?.respdata?.success) {
      console.log(respCarsheets);
      setCarsheetList(respCarsheets.respdata.data);
      let events = [];
      respCarsheets?.respdata?.data?.map((record) => {
        events.push({
          modificabile: record.inviato ? false : true,
          disponibile: true,
          backgroundColor: "#FFF",
          color: "#000",
          borderColor: "#FFF",
          title: record.targa,
          start: formatDate(record.giorno),
          allDay: true,
          carsheetRecord: record,
        });
        if (record.inviato === false) {
          setShowSendButton(true);
        }
      });
      setEvents(events);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [respCarsheets]);

  useEffect(() => {
    dispatch(getCarsheetsAsync(enlistmentId));
  }, [respPostCarsheet]);

  useEffect(() => {
    dispatch(getCarsheetsAsync(enlistmentId));
  }, [respPutCarsheet]);

  useEffect(() => {
    dispatch(getCarsheetsAsync(enlistmentId));
  }, [respSendCarsheet]);

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "clamp(300px, 50%, 500px)",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [openCarsheetModal, setOpenCarsheetModal] = React.useState(false);
  const handleOpenCarsheetModal = () => setOpenCarsheetModal(true);
  const handleCloseCarsheetModal = () => setOpenCarsheetModal(false);

  const handleEnlistmentSelection = (event) => {
    setEnlistmentId(event.target.value);
    let selectedEnlistment = enlistmentList?.find(
      (o) => o.id_Ingaggio === event.target.value
    );
    setDateEnd(
      selectedEnlistment
        ? formatDate(selectedEnlistment.dataFine)
        : Moment(new Date()).endOf("month").add(1, "days").format("YYYY-MM-DD")
    );
    setDateStart(
      selectedEnlistment
        ? formatDate(selectedEnlistment.dataInizio)
        : Moment(new Date()).format("YYYY-MM-DD")
    );
    dispatch(getCarsheetsAsync(event.target.value));
  };
  const addCarsheetEntry = () => {
    console.log("INSIDE ADD-CARSHEET-ENTRY");
    const payload = {
      id_Ingaggio: enlistmentId,
      giorno: date,
      percorsiEffettuati: routes,
      kmPercorsi: parseInt(distance),
      modello: model,
      targa: plate,
      costoBenzina: parseInt(fuelprice),
      inviato: false,
    };
    if (mode === "add") {
      dispatch(postCarsheetAsync(payload));
    } else if (mode === "update") {
      payload.id_SchedeAuto = carsheetId;
      dispatch(putCarsheetAsync(payload));
    }
    resetFormData();
    setOpenCarsheetModal(false);
    dispatch(getCarsheetsAsync(enlistmentId));
  };

  const sendCarsheetEntry = () => {
    console.log("INSIDE SEND-CARSHEET-ENTRY");
    const payload = {
      idIngaggio: enlistmentId,
    };
    dispatch(sendCarsheetAsync(payload));
    dispatch(getCarsheetsAsync(enlistmentId));
  };

  return (
    <>
      <hr />
      <Grid container spacing={10}>
        <Grid item xs={8}>
          <FormControl variant='standard' fullWidth>
            <InputLabel id='enlistment-carsheet-label'>
              {strings.enlistments}
            </InputLabel>
            <Select
              required
              variant='standard'
              disabled={false}
              labelId='enlistment-carsheet-label'
              value={enlistmentId}
              label={strings.enlistment}
              onChange={handleEnlistmentSelection}
            >
              {enlistmentList?.map((enlistment) => {
                return (
                  <MenuItem value={enlistment.id_Ingaggio}>
                    {enlistment.nome}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <Button
            type='button'
            size={"small"}
            onClick={sendCarsheetEntry}
            variant='contained'
            disabled={!showSendButton}
          >
            {strings.record}
          </Button>
        </Grid>
      </Grid>
      <br />
      <br />
      {respPostCarsheet?.respdata?.success === false ? (
        <Alert sx={MESSAGES2_SX} severity='error'>
          {respPostCarsheet?.respdata?.message}{" "}
        </Alert>
      ) : (
        ""
      )}
      {respSendCarsheet?.respdata?.success === false ? (
        <Alert sx={MESSAGES2_SX} severity='error'>
          {strings.getLanguage() === "en"
            ? "The car card report has already been sent for today. You can resend it tomorrow"
            : respSendCarsheet?.respdata?.message}
        </Alert>
      ) : (
        ""
      )}
      {respSendCarsheet?.respdata?.success === true ? (
        <Alert sx={MESSAGES2_SX} severity='info'>
          {strings.getLanguage() === "en"
            ? "Email sent"
            : respSendCarsheet?.respdata?.message}
        </Alert>
      ) : (
        ""
      )}
      <br />
      <br />
      <FullCalendar
        width={600}
        validRange={{ start: dateStart, end: dateEnd }}
        height={600}
        dateClick={handleDateClick}
        eventClick={handleEventClick}
        events={events}
        locale={fcLocale}
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView='dayGridMonth'
        eventContent={renderEventContent}
      />
      <Modal
        open={openCarsheetModal}
        onClose={handleCloseCarsheetModal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={modalStyle}>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            {strings.getLanguage() !== "en"
              ? "Aggiungi dato al carsheet"
              : "Add date into carsheet"}
          </Typography>
          <br />
          <FormControl variant={"standard"} fullWidth>
            <TextField
              required
              placeholder={routes === "" ? strings.routesPlaceholder : routes}
              disabled={recordSent}
              label={strings.routes}
              variant='standard'
              value={routes}
              onChange={(event) => setRoutes(event.target.value)}
            />
          </FormControl>
          <FormControl variant={"standard"} fullWidth>
            <TextField
              required
              placeholder={
                distance > 0 ? distance : strings.distancePlaceholder
              }
              disabled={recordSent}
              label={strings.distance}
              variant='standard'
              value={distance}
              onChange={(event) => setDistance(event.target.value)}
            />
          </FormControl>
          <FormControl variant={"standard"} fullWidth>
            <TextField
              required
              placeholder={model === "" ? strings.modelPlaceholder : model}
              disabled={recordSent}
              label={strings.model}
              variant='standard'
              value={model}
              onChange={(event) => setModel(event.target.value)}
            />
          </FormControl>
          <FormControl variant={"standard"} fullWidth>
            <TextField
              required
              placeholder={plate === "" ? strings.platePlaceholder : plate}
              disabled={recordSent}
              label={strings.plate}
              variant='standard'
              value={plate}
              onChange={(event) => setPlate(event.target.value)}
            />
          </FormControl>
          <FormControl variant={"standard"} fullWidth>
            <TextField
              required
              placeholder={
                fuelprice > 0 ? fuelprice : strings.fuelpricePlaceholder
              }
              disabled={recordSent}
              label={strings.fuelprice}
              variant='standard'
              value={fuelprice}
              onChange={(event) => setFuelprice(event.target.value)}
            />
          </FormControl>
          <br />
          <br />
          <Button
            type='button'
            onClick={addCarsheetEntry}
            variant='contained'
            disabled={recordSent}
          >
            {strings.confirmRecord}
          </Button>
        </Box>
      </Modal>
    </>
  );
}
