import { CollectionsOutlined } from "@mui/icons-material";
import jQuery from "jquery";
import { BASE_API_URL } from "../../Constants.js";

export function getPreferiti() {
  return new Promise((resolve) =>
    jQuery
      .ajax({
        type: "GET",
        url: BASE_API_URL + "/api/Preferiti",
        // dataType: "json",
        //contentType: "application/json; charset=utf-8",
        // data: JSON.stringify({ username: username, password: password }),
      })
      .done((response) => resolve({ respdata: response }))
      .always((error) => resolve({ error }))
  );
}

export function getPreferito(id) {
  return new Promise((resolve) =>
    jQuery
      .ajax({
        type: "GET",
        url: BASE_API_URL + "/api/Preferiti/" + id,
        // dataType: "json",
        //contentType: "application/json; charset=utf-8",
        // data: JSON.stringify({ username: username, password: password }),
      })
      .done((response) => resolve({ respdata: response }))
      .always((error) => resolve({ error }))
  );
}

export function postPreferito(params) {
  return new Promise((resolve) =>
    jQuery
      .ajax({
        type: "POST",
        url: BASE_API_URL + "/api/Preferiti",
        data: JSON.stringify(params),
        contentType: "application/json; charset=utf-8",
      })
      .done((response) => resolve({ respdata: response }))
      .always((error) => resolve({ error }))
  );
}

export function postCondividiCategoriaEmail(params) {
  return new Promise((resolve) =>
    jQuery
      .ajax({
        type: "POST",
        url: BASE_API_URL + "/api/Preferiti/Condivisione/Email",
        data: JSON.stringify(params),
        contentType: "application/json; charset=utf-8",
      })
      .done((response) => resolve({ respdata: response }))
      .always((error) => resolve({ error }))
  );
}

export function deletePreferito(params) {
  return new Promise((resolve) =>
    jQuery
      .ajax({
        type: "DELETE",
        url:
          BASE_API_URL +
          "/api/Preferiti?userId=" +
          params.userId +
          "&categoria=" +
          params.categoria,
      })
      .done((response) => resolve({ respdata: response }))
      .always((error) => resolve({ error }))
  );
}

export function getCategorie() {
  return new Promise((resolve) =>
    jQuery
      .ajax({
        type: "GET",
        url: BASE_API_URL + "/api/Preferiti/Categoria",
      })
      .done((response) => resolve({ respdata: response }))
      .always((error) => resolve({ error }))
  );
}

export function getCategoria(id) {
  return new Promise((resolve) =>
    jQuery
      .ajax({
        type: "GET",
        url: BASE_API_URL + "/api/Preferiti/Categoria/" + id,
        // dataType: "json",
        //contentType: "application/json; charset=utf-8",
      })
      .done((response) => resolve({ respdata: response }))
      .always((error) => resolve({ error }))
  );
}

export function postCategoria(params) {
  return new Promise((resolve) =>
    jQuery
      .ajax({
        type: "POST",
        url: BASE_API_URL + "/api/Preferiti/Categoria/",
        data: JSON.stringify(params),
        contentType: "application/json; charset=utf-8",
      })
      .done((response) => resolve({ respdata: response }))
      .always((error) => resolve({ error }))
  );
}

export function deleteCategoria(params) {
  return new Promise((resolve) =>
    jQuery
      .ajax({
        type: "DELETE",
        url: BASE_API_URL + "/api/Preferiti/Categoria/" + params.categoria,
      })
      .done((response) => resolve({ respdata: response }))
      .always((error) => resolve({ error }))
  );
}

export function postCondividiCategoria(params) {
  return new Promise((resolve) =>
    jQuery
      .ajax({
        type: "POST",
        url: BASE_API_URL + "/api/Preferiti/Condivisione",
        data: JSON.stringify(params),
        contentType: "application/json; charset=utf-8",
      })
      .done((response) => resolve({ respdata: response }))
      .always((error) => resolve({ error }))
  );
}

export function getRichiesteCondivisioneCategoria(params) {
  console.log(params);
  let queryString = "";
  if (params?.statoRichiesta > -1) {
    queryString = "?statoRichiesta=" + params.statoRichiesta;
  }
  return new Promise((resolve) =>
    jQuery
      .ajax({
        type: "GET",
        url:
          BASE_API_URL + "/api/Preferiti/Condivisione/Richieste" + queryString,
      })
      .done((response) => resolve({ respdata: response }))
      .always((error) => resolve({ error }))
  );
}

export function putRichiestaCondivisioneCategoria(params) {
  return new Promise((resolve) =>
    jQuery
      .ajax({
        type: "PUT",
        url: BASE_API_URL + "/api/Preferiti/Condivisione",
        data: JSON.stringify(params),
        contentType: "application/json; charset=utf-8",
      })
      .done((response) => resolve({ respdata: response }))
      .always((error) => resolve({ error }))
  );
}

export function deleteCategoriaCondivisa(id) {
  return new Promise((resolve) =>
    jQuery
      .ajax({
        type: "DELETE",
        url:
          BASE_API_URL +
          "/api/Preferiti/Condivisione/Richieste?condivisioneCtegoria=" +
          id,
        contentType: "application/json; charset=utf-8",
      })
      .done((response) => resolve({ respdata: response }))
      .always((error) => resolve({ error }))
  );
}

export function getCategoriaCondivisa(id) {
  return new Promise((resolve) =>
    jQuery
      .ajax({
        type: "GET",
        url: BASE_API_URL + "/api/Preferiti/Condivisione/Richieste/" + id,
        contentType: "application/json; charset=utf-8",
      })
      .done((response) => resolve({ respdata: response }))
      .always((error) => resolve({ error }))
  );
}
