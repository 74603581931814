import jQuery from "jquery";
import { BASE_API_URL } from "../../Constants.js";

export function getSubscription() {
  return new Promise((resolve) =>
    jQuery
      .ajax({
        type: "GET",
        url: BASE_API_URL + "/api/Subscription/",
        // dataType: "json",
        //contentType: "application/json; charset=utf-8",
        // data: JSON.stringify({ username: username, password: password }),
      })
      .done((response) => resolve({ respdata: response }))
      .always((error) => resolve({ error }))
  );
}

export function deleteSubscription() {
  return new Promise((resolve) =>
    jQuery
      .ajax({
        type: "DELETE",
        url: BASE_API_URL + "/api/Subscription/",
        // dataType: "json",
        //contentType: "application/json; charset=utf-8",
        // data: JSON.stringify({ username: username, password: password }),
      })
      .done((response) => resolve({ respdata: response }))
      .always((error) => resolve({ error }))
  );
}

export function getSubscriptionList() {
  return new Promise((resolve) =>
    jQuery
      .ajax({
        type: "GET",
        url: BASE_API_URL + "/api/Subscription/List",
        // dataType: "json",
        //contentType: "application/json; charset=utf-8",
        // data: JSON.stringify({ username: username, password: password }),
      })
      .done((response) => resolve({ respdata: response }))
      .always((error) => resolve({ error }))
  );
}

export function getPagamentiKey() {
  return new Promise((resolve) =>
    jQuery
      .ajax({
        type: "GET",
        url: BASE_API_URL + "/api/Pagamenti/Key",
        // dataType: "json",
        //contentType: "application/json; charset=utf-8",
        // data: JSON.stringify({ username: username, password: password }),
      })
      .done((response) => resolve({ respdata: response }))
      .always((error) => resolve({ error }))
  );
}

export function getPagamenti() {
  return new Promise((resolve) =>
    jQuery
      .ajax({
        type: "GET",
        url: BASE_API_URL + "/api/Pagamenti",
        // dataType: "json",
        //contentType: "application/json; charset=utf-8",
        // data: JSON.stringify({ username: username, password: password }),
      })
      .done((response) => resolve({ respdata: response }))
      .always((error) => resolve({ error }))
  );
}

export function postPagamenti(params) {
  return new Promise((resolve) =>
    jQuery
      .ajax({
        type: "POST",
        url: BASE_API_URL + "/api/Pagamenti",
        data: JSON.stringify(params),
        // dataType: "json",
        contentType: "application/json; charset=utf-8",
        // data: JSON.stringify({ username: username, password: password }),
      })
      .done((response) => resolve({ respdata: response }))
      .always((error) => resolve({ error }))
  );
}

export function postSubscription(params) {
  return new Promise((resolve) =>
    jQuery
      .ajax({
        type: "POST",
        url: BASE_API_URL + "/api/Subscription",
        data: JSON.stringify(params),
        // dataType: "json",
        contentType: "application/json; charset=utf-8",
        // data: JSON.stringify({ username: username, password: password }),
      })
      .done((response) => resolve({ respdata: response }))
      .always((error) => resolve({ error }))
  );
}

export function putSubscription(params) {
  return new Promise((resolve) =>
    jQuery
      .ajax({
        type: "PUT",
        url: BASE_API_URL + "/api/Subscription",
        data: JSON.stringify(params),
        // dataType: "json",
        contentType: "application/json; charset=utf-8",
        // data: JSON.stringify({ username: username, password: password }),
      })
      .done((response) => resolve({ respdata: response }))
      .always((error) => resolve({ error }))
  );
}

export function createStripeCardToken(
  serviceKey,
  cardNumber,
  cardExpMonth,
  cardExpYear,
  cardCVC
) {
  return new Promise((resolve) =>
    fetch("https://api.stripe.com/v1/tokens", {
      method: "POST",
      headers: {
        //  'Authorization': 'Basic ' + btoa(serviceKey)
        //'Authorization': 'Bearer ' + btoa(serviceKey)
        "Authorization": "Bearer " + serviceKey,
        "Content-Type": "application/x-www-form-urlencoded",
        //'Content-Type': 'application/x-www-form-rlencoded; charset=UTF-8',
      },
      redirect: "follow",
      body:
        "card[number]=" +
        cardNumber +
        "&card[exp_month]=" +
        cardExpMonth +
        "&card[exp_year]=20" +
        cardExpYear +
        "&card[cvc]=" +
        cardCVC,
    })
      .then((response) => {
        response.json().then(function (data) {
          // console.log(data);
          resolve({ respdata: data });
        });
      })
      .catch((error) => resolve({ error }))
  );
}
