import { Alert, Button, Stack, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { COMMON_SX, MESSAGES2_SX, strings } from "../../Constants";
import { doSuggest } from "./suggestAPI";
import moment from "moment";

const SuggestUs = () => {
  const [text, setText] = useState("");
  const currentDate = new Date().toISOString();
  const [alert, setAlert] = useState(false)

  const handleChange = (event) => {
    setText(event.target.value);
  };

  const disabled = text === "" ? true : false;


  const onSubmit = (e) => {
    e.preventDefault();

    doSuggest({descrizione: text, dataInizio: currentDate})
    setText("")
    setAlert(true)
  }
  return (
    <Stack
      className="muiStackFix"
      spacing={2.5}
      direction="column"
      sx={COMMON_SX}
    >
      <>
        <h2 style={{ textAlign: "center" }}>{strings.reportaProblem}</h2>
        { alert ? (
        <Alert style={{maxWidth: "92%"}} sx={MESSAGES2_SX} severity="info">
          {strings.getLanguage() === "en"
            ? "Thank you for writing us"
            : "Grazie per averci scritto"}
        </Alert>
      ) : (
        ""
      )}
        <form onSubmit={onSubmit}>
          <TextField
            multiline
            value={text}
            onChange={handleChange}
            label={strings.writeHere + "..."}
            rows={8}
            fullWidth
          />

          <div
            style={{
              textAlign: "center",
              marginTop: "40px",
              marginBottom: "40px",
            }}
          >
            <Button
              sx={{
                width: { sd: "26ch", md: "26ch" },
              }}
              type="submit"
              variant="contained"
              disabled={disabled}
            >
              {strings.conferma}
            </Button>
          </div>
        </form>
      </>
    </Stack>
  );
};

export default SuggestUs;
