import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  sendCarsheet,
  postCarsheet,
  putCarsheet,
  getCarsheets,
} from "./carsheetAPI";

const initialState = {
  respPostCarsheet: null,
  respGetCarsheets: null,
  respPutCarsheet: null,
  respSendCarsheet: null,
  status: "idle",
};

export const sendCarsheetAsync = createAsyncThunk(
  "carsheet/send",
  async (params) => {
    const response = await sendCarsheet(params);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const postCarsheetAsync = createAsyncThunk(
  "carsheet/post",
  async (params) => {
    const response = await postCarsheet(params);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const putCarsheetAsync = createAsyncThunk(
  "carsheet/put",
  async (params) => {
    const response = await putCarsheet(params);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const getCarsheetsAsync = createAsyncThunk(
  "carsheet/get",
  async (params) => {
    const response = await getCarsheets(params);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const carsheetSlice = createSlice({
  name: "carsheet",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(postCarsheetAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(postCarsheetAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.respPostCarsheet = action.payload;
      })

      .addCase(putCarsheetAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(putCarsheetAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.respPutCarsheet = action.payload;
      })

      .addCase(getCarsheetsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCarsheetsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.respGetCarsheets = action.payload;
      })

      .addCase(sendCarsheetAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(sendCarsheetAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.respSendCarsheet = action.payload;
      });
  },
});

export const selectRespSendCarsheet = (state) =>
  state.carsheet.respSendCarsheet;
export const selectRespPostCarsheet = (state) =>
  state.carsheet.respPostCarsheet;
export const selectRespPutCarsheet = (state) => state.carsheet.respPutCarsheet;
export const selectRespGetCarsheets = (state) =>
  state.carsheet.respGetCarsheets;

export default carsheetSlice.reducer;
