import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { IconButton } from "@mui/material";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { default as React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../logo-bb.png";
import {
  FORM_INTERNAL_SX,
  MESSAGES2_SX,
  STACK_SX,
  strings,
} from "./../../Constants";
import {
  registrationAsync,
  selectLoginData,
  selectResponseRegister,
} from "./welcomeSlice";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Modal from "@mui/material/Modal";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import parse from "html-react-parser";

export default function Register() {
  const dispatch = useDispatch();
  const response = useSelector(selectResponseRegister);
  const loginData = useSelector(selectLoginData);
  const navigate = useNavigate();
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);
  const [disabled, setDisabled] = useState(true);
  /*   const [acceptTerms, setAcceptTerms] = useState(false);
  const [acceptUseImages, setAcceptUseImages] = useState(false); */

  const [openTermAndCondictionsModal, setOpenTermAndCondictionsModal] =
    useState(false);

  const [email, setEmail] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);

  const showTermsAndConditions = () => {
    setOpenTermAndCondictionsModal(true);
  };

  const handleCloseTermAndCondictionsModal = () =>
    setOpenTermAndCondictionsModal(false);

  useEffect(() => {
    //console.log(loginData);
    if (loginData?.username) {
      navigate("/home", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginData]);

  /*   useEffect(() => {
    //console.log(loginData);
    if (acceptUseImages && acceptTerms) {
      setDisabled(false);
    }
    //console.log(response);
    if (response?.respdata?.success) {
      //navigate("/home", { replace: true });
    }
  }, [response]); */

  /*   useEffect(() => {
    //console.log(loginData);
    if (acceptUseImages && acceptTerms) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [acceptUseImages, acceptTerms]); */

  const onSubmit = (data) => {
    // console.log(username,password,email);
    dispatch(registrationAsync(data));
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "clamp(300px, 50%, 500px)",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const schema = Yup.object().shape({
    username: Yup.string()
      .required(strings.requiredUsername)
      .matches(/^\S*$/, strings.invalidUsername),
    email: Yup.string()
      .email(strings.invalidEmail)
      .required(strings.requiredEmail),
    password: Yup.string().required(strings.requiredPassword),
    repeatPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], strings.invalidRepeatPassword)
      .required(strings.requiredRepeatPassword),
    acceptTerms: Yup.boolean().oneOf([true], strings.requiredTerms).required(),
    acceptUseImages: Yup.boolean()
      .oneOf([true], strings.requiredImages)
      .required(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  console.log(response);
  console.log(strings.getLanguage() === "en");

  return (
    <div>
      <br />
      <div>
        {response?.respdata?.data?.esito ? (
          <Alert sx={MESSAGES2_SX} severity='success'>
            {strings.signupok} <Link to='/home'>&gt; {strings.signin}</Link>{" "}
          </Alert>
        ) : (
          ""
        )}

        {response?.respdata?.success === false ? (
          <Alert sx={MESSAGES2_SX} severity='info'>
            {response?.respdata?.message}{" "}
          </Alert>
        ) : (
          ""
        )}

        {response && response.error && response.error.responseJSON ? (
          strings.getLanguage() !== "en" ? (
            <Alert sx={MESSAGES2_SX} severity='error'>
              {response.error.responseJSON.data.title}{" "}
            </Alert>
          ) : (
            <Alert sx={MESSAGES2_SX} severity='error'>
              Validation error, check the entered data
            </Alert>
          )
        ) : (
          ""
        )}

        {response && response.error && response.error.responseJSON ? (
          strings.getLanguage() !== "en" ? (
            response.error.responseJSON.modelValidationError.map((o, i) => (
              <div>
                <br />
                <Alert sx={MESSAGES2_SX} severity='error'>
                  {o}
                </Alert>
              </div>
            ))
          ) : (
            <div>
              <br />
              <Alert sx={MESSAGES2_SX} severity='error'>
                The password must be at least 8 characters long, must contain at
                least one numeric character and one uppercase character
              </Alert>
            </div>
          )
        ) : (
          ""
        )}
      </div>
      <br />
      <form
        component='form'
        sx={FORM_INTERNAL_SX}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Stack spacing={2} direction='column' sx={STACK_SX}>
          <img
            alt='logo'
            src={logo}
            style={{ margin: "0 auto", width: "60%", height: "auto" }}
          />

          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <h3 className='colorPrimary'>{strings.createNewAccountHeading}</h3>
          </Box>
          <br />
          <TextField
            variant='standard'
            label='Username'
            {...register("username")}
            error={!!errors.username}
            helperText={errors.username?.message}
          />
          <TextField
            label='Password'
            variant='standard'
            type={showPassword ? "text" : "password"}
            autoComplete='current-password'
            {...register("password")}
            error={!!errors.password?.message}
            helperText={errors.password?.message}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                    onMouseDown={handleMouseDownPassword}
                    edge='end'
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label='Ripeti password'
            variant='standard'
            type={showRepeatPassword ? "text" : "password"}
            {...register("repeatPassword")}
            error={!!errors.repeatPassword}
            autoComplete='current-password'
            helperText={errors.repeatPassword?.message}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={() => {
                      setShowRepeatPassword(!showRepeatPassword);
                    }}
                    onMouseDown={handleMouseDownPassword}
                    edge='end'
                  >
                    {showRepeatPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <TextField
            label='Email'
            type='email'
            variant='standard'
            autoComplete='email'
            error={!!errors.email}
            helperText={errors.email?.message}
            {...register("email")}
          />

          <br />
          <br />
          <FormControlLabel
            control={
              <Checkbox
                {...register("acceptTerms")}
                /*                 checked={acceptTerms}
                onChange={(e) => {
                  setAcceptTerms(e.target.checked); 
                }}*/
              />
            }
            label={
              <p>
                {strings.accept} {strings.the_plural}
                &nbsp;
                <Link
                  to='/terms'
                  onClick={(e) => {
                    e.preventDefault();
                    showTermsAndConditions();
                  }}
                >
                  <u>{strings.termAndCondsTitle}</u>
                </Link>
              </p>
            }
          />
          {errors.acceptTerms && (
            <span style={{ color: "#D32F2F", fontSize: "12px" }}>
              <strong>{errors.acceptTerms.message}</strong>
            </span>
          )}
          <FormControlLabel
            control={
              <Checkbox
                {...register("acceptUseImages")}
                /*                 checked={acceptUseImages}
                onChange={(e) => {
                  setAcceptUseImages(e.target.checked);
                }} */
              />
            }
            label={strings.allowImageUsage}
          />
          {errors.acceptUseImages && (
            <span style={{ color: "#D32F2F", fontSize: "12px" }}>
              <strong>{errors.acceptUseImages.message}</strong>
            </span>
          )}

          <br />
          <br />
          <br />
          <Button type='submit' variant='contained'>
            {strings.register}
          </Button>
          <Link to='/login'>{strings.alreadyHaveAccount}</Link>
        </Stack>
      </form>

      <Modal
        open={openTermAndCondictionsModal}
        onClose={handleCloseTermAndCondictionsModal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={modalStyle}>
          <h2>{strings.termAndCondsTitle}</h2>
          <p class='max-height-modal-text'>
            {parse(strings.termsAndConditions)}
          </p>
        </Box>
      </Modal>
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}
