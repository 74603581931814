import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { default as React, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { strings } from "../../Constants";
import { getJobsAsync } from "../job/jobSlice";
import { COMMON_SX_LARGE, FORM_INTERNAL_SX } from "./../../Constants";
import AvatarSimple from "./../commons/AvatarSimple";
import { selectRespGetJobs } from "./../job/jobSlice";
import { selectLoginData } from "./../welcome/welcomeSlice";
import { selectRespSearch } from "./searchSlice";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { dpLocale, formatDate } from "./../../Constants";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import {
  postInviaRichiestaIngaggioAsync,
  resetRespInviaRichiestaIngaggio,
  selectRespInviaRichiestaIngaggio,
} from "../employment/employmentSlice";
import {
  postFavoriteAsync,
  getFavoritesAsync,
  postCategoryAsync,
  getCategoriesAsync,
  postShareCategoryAsync,
} from "../favorite/favoriteSlice";
import ResponseAlertMessages from "../commons/ResponseAlertMessages";
import {
  selectRespPostFavorite,
  selectRespGetCategories,
  selectRespPostCategory,
  selectRespPostShareCategory,
  resetRespFavorites,
} from "../favorite/favoriteSlice";
import {
  getDomainRuoliAsync,
  selectRespDomainRuoli,
} from "../domain/domainSlice";

export default function SearchResultUserDetail(props) {
  const [jobList, setJobList] = useState([]);
  const dispatch = useDispatch();
  const { id } = useParams();
  const dominioRuoliResp = useSelector(selectRespDomainRuoli);
  const resp = useSelector(selectRespSearch);
  const [results, setResults] = useState([]);
  const [result, setResult] = useState(null);
  const respJobs = useSelector(selectRespGetJobs);
  const loggedUser = useSelector(selectLoginData);
  const respInviaIngaggio = useSelector(selectRespInviaRichiestaIngaggio);
  const [jobs, setJobs] = useState(
    localStorage.getItem("search_jobs")
      ? JSON.parse(localStorage.getItem("search_jobs"))
      : []
  );
  const [favorites, setFavorites] = useState([]);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState(0);
  const [categoryName, setCategoryName] = useState(null);
  const respPostFavorite = useSelector(selectRespPostFavorite);
  const respGetCategories = useSelector(selectRespGetCategories);
  const respPostCategory = useSelector(selectRespPostCategory);
  const respPostShareCategory = useSelector(selectRespPostShareCategory);

  console.log(respPostShareCategory);

  const [dataInizio, setDataInizio] = useState(
    localStorage.getItem("search_dataInizio")
  );
  //useEffect(() => { dataInizio ? localStorage.setItem('search_dataInizio', dataInizio) : localStorage.removeItem('search_dataInizio') }, [dataInizio])

  const [dataFine, setDataFine] = useState(
    localStorage.getItem("search_dataFine")
  );
  //useEffect(() => { dataFine ? localStorage.setItem('search_dataFine', dataFine) : localStorage.removeItem('search_dataFine') }, [dataFine]);

  useEffect(() => {
    //eslint-disable-next-line react-hooks/exhaustive-deps
    dispatch(resetRespFavorites());
    dispatch(getDomainRuoliAsync());
  }, []);

  useEffect(() => {
    //console.log(loggedUser);
    if (loggedUser) {
      dispatch(resetRespInviaRichiestaIngaggio());
      dispatch(getJobsAsync());
      dispatch(getFavoritesAsync());
      dispatch(getCategoriesAsync());
      //     dispatch(getDomainRuoliAsync());
      //     dispatch(getDomainNazioniAsync());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUser]);

  useEffect(() => {
    //console.log(loggedUser);
    if (respJobs?.respdata?.success) {
      setJobList(respJobs.respdata.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [respJobs]);

  useEffect(() => {
    if (respGetCategories?.respdata?.success) {
      setCategories(respGetCategories.respdata.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [respGetCategories]);

  const [openProjectEmploymentModal, setOpenProjectEmploymentModal] =
    React.useState(false);
  const handleOpenProjectEmploymentModal = () =>
    setOpenProjectEmploymentModal(true);
  const handleCloseProjectEmploymentModal = () =>
    setOpenProjectEmploymentModal(false);

  const [openFavoriteModal, setOpenFavoriteModal] = React.useState(false);
  const handleOpenFavoriteModal = () => setOpenFavoriteModal(true);
  const handleCloseFavoriteModal = () => setOpenFavoriteModal(false);

  const [openShareModal, setOpenShareModal] = React.useState(false);
  const handleOpenShareModal = () => setOpenShareModal(true);
  const handleCloseShareModal = () => setOpenShareModal(false);

  useEffect(() => {
    //console.log(loggedUser);
    if (resp?.respdata?.success) {
      setResults(resp.respdata.data.listaUtenti);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resp]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [respInviaIngaggio]);

  useEffect(() => {
    dispatch(getCategoriesAsync());
  }, [respPostCategory]);

  useEffect(() => {
    //console.log(loggedUser);
    setResult(results.find((r) => r.userId === id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results]);

  const employmentRequest = () => {
    const obj = {
      userId: result.userId,
      id_Ingaggio: 0,
      id_Job: jobs[0].id_Job,
      nome: jobs[0].nome,
      dataInizio: formatDate(dataInizio),
      dataFine: formatDate(dataFine),
    };
    if (obj.dataInizio && obj.dataFine) {
      dispatch(postInviaRichiestaIngaggioAsync(obj));
    } else {
      console.log("NO DATES ARE SPECIFIED");
    }
  };

  const addFavorite = () => {
    const obj = {
      id_Preferito: result.userId,
      id_Categoria: category,
    };
    dispatch(postFavoriteAsync(obj));
  };

  const addCategory = () => {
    const obj = {
      nome: categoryName,
    };
    dispatch(postCategoryAsync(obj));
  };

  const shareCategory = () => {
    const obj = {
      id_Categoria: category,
      user_ID: result.userId,
    };
    dispatch(postShareCategoryAsync(obj));
  };

  const parseDate = (str, country) => {
    const strToDate = new Date(str).toLocaleDateString(country);
    return strToDate;
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "clamp(300px, 50%, 500px)",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <br />
      <br />
      <form component='form' sx={FORM_INTERNAL_SX}>
        <Stack
          className='muiStackFix'
          spacing={2.5}
          direction='column'
          sx={COMMON_SX_LARGE}
        >
          {result ? (
            <div className='genericBox searchResult'>
              <Box className='paddingZero' sx={{ p: 0 }}>
                <AvatarSimple userId={result.userId}></AvatarSimple>{" "}
                <span className='blueDash'></span>
                {/*JSON.stringify(result)*/}
                <br />
                <div
                  style={{
                    textTransform: "none",
                    borderBottom: "1px solid lightgray",
                  }}
                >
                  {result.nome} {result.cognome}
                </div>
                <br />
                {result?.listaNazioni.length > 0 ? (
                  <>
                    {result?.listaNazioni.map((item) => (
                      <>
                        <div
                          style={{
                            textTransform: "none",
                            borderBottom: "1px solid lightgray",
                          }}
                        >
                          {strings.nazionalita}: {item.nazione}{" "}
                          {item.regione !== null
                            ? "- " + strings.region + ": " + item.regione
                            : ""}{" "}
                        </div>
                        <br />
                      </>
                    ))}
                  </>
                ) : (
                  <></>
                )}
                <div
                  style={{
                    textTransform: "none",
                    borderBottom: "1px solid lightgray",
                  }}
                >
                  {strings.height} {result.datiFisici.altezza}cm{" "}
                  {strings.weight} {result.datiFisici.peso}kg
                </div>
                <br />
                {result?.esperienze === "" || result?.esperienze === null ? (
                  <></>
                ) : (
                  <>
                    <div
                      style={{
                        textTransform: "none",
                        borderBottom: "1px solid lightgray",
                      }}
                    >
                      {strings.experience}: {result?.esperienze}
                    </div>
                    <br />
                  </>
                )}
                {result?.listaDisponibilita.length > 0 ? (
                  <>
                    {result?.listaDisponibilita.slice(0, 10).map((item) => (
                      <>
                        <div
                          style={{
                            textTransform: "none",
                            borderBottom: "1px solid lightgray",
                          }}
                        >
                          {strings.day}:{" "}
                          {parseDate(
                            item.giorno,
                            strings.getLanguage() === "en" ? "en-EN" : "it-IT"
                          )}{" "}
                          {strings.tabDisponibilita}:{" "}
                          {item.disponibile ? strings.yes : "No"}
                        </div>
                        <br />
                      </>
                    ))}
                  </>
                ) : (
                  <></>
                )}
                {result?.listaRuoli.length > 0 ? (
                  <>
                    {result?.listaRuoli.map((item) => (
                      <>
                        <div
                          style={{
                            textTransform: "none",
                            borderBottom: "1px solid lightgray",
                          }}
                        >
                          {strings.scope}: {item.ambito}
                        </div>
                        <br />
                        <div
                          style={{
                            textTransform: "none",
                            borderBottom: "1px solid lightgray",
                          }}
                        >
                          {strings.department}: {item.dipartimento}
                        </div>
                        <br />
                        <div
                          style={{
                            textTransform: "none",
                            borderBottom: "1px solid lightgray",
                          }}
                        >
                          {strings.role}: {item.ruolo}
                        </div>
                        <br />
                      </>
                    ))}
                  </>
                ) : (
                  <></>
                )}
                {result?.listaLingue.length > 0 ? (
                  <>
                    {result?.listaLingue.map((item) => (
                      <>
                        <div
                          style={{
                            textTransform: "none",
                            borderBottom: "1px solid lightgray",
                          }}
                        >
                          {strings.lingua}: {item.lingua} {strings.level}:{" "}
                          {item.livello}
                        </div>
                        <br />
                      </>
                    ))}
                  </>
                ) : (
                  <></>
                )}
              </Box>
              <Button onClick={handleOpenProjectEmploymentModal}>
                {strings.inviaIngaggio}
              </Button>
              <Button onClick={handleOpenFavoriteModal}>
                {strings.addFavourite}
              </Button>
              <Button onClick={handleOpenShareModal}>
                {strings.shareFavourite}
              </Button>

              {/* Modale per invio richiesta ingaggio */}
              <Modal
                open={openProjectEmploymentModal}
                onClose={handleCloseProjectEmploymentModal}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
              >
                <Box sx={modalStyle}>
                  <Typography
                    id='modal-modal-title'
                    variant='h6'
                    component='h2'
                  >
                    {strings.getLanguage() === "en"
                      ? strings.hiringJob
                      : strings.hiringJobIta}
                  </Typography>
                  <br />
                  {jobList ? (
                    <Autocomplete
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            variant='outlined'
                            label={option.nome}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      value={jobs.length ? jobs[0] : null}
                      isOptionEqualToValue={(opt, value) =>
                        value.id_Job === opt.id_Job
                      }
                      options={jobList}
                      getOptionLabel={(option) =>
                        "#" + option.id_Job + " - " + option.nome
                      }
                      onChange={(event, newValue) => {
                        if (newValue) {
                          if (!newValue?.length) {
                            newValue = [newValue];
                          }
                          //console.log(newValue);
                          setJobs(newValue);
                        } else {
                          setJobs([]);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant='standard'
                          label={strings.job}
                          placeholder={strings.selectAJob}
                        />
                      )}
                    ></Autocomplete>
                  ) : (
                    ""
                  )}

                  <Typography id='modal-modal-description' sx={{ mt: 2 }}>
                    {strings.getLanguage() === "en"
                      ? strings.checkJob
                      : strings.checkJobIta}
                  </Typography>
                  <h5 style={{ marginTop: "35px" }}>
                    {strings.getLanguage() === "en"
                      ? strings.periodoEngagement
                      : strings.periodoEngagementIta}
                  </h5>
                  <LocalizationProvider
                    locale={dpLocale}
                    dateAdapter={AdapterDateFns}
                  >
                    <Stack className='StackRow' direction='row' spacing={2}>
                      <DatePicker
                        required={true}
                        value={dataInizio}
                        label={strings.startDate}
                        minDate={new Date()}
                        maxDate={dataFine ? dataFine : null}
                        inputFormat={
                          strings.getLanguage() === "it"
                            ? "dd/MM/yyyy"
                            : "MM/dd/yyyy"
                        }
                        onChange={(val) => setDataInizio(val)}
                        renderInput={(params) => (
                          <TextField
                            required
                            variant='standard'
                            {...params}
                            fullWidth
                          />
                        )}
                      />

                      <DatePicker
                        required={true}
                        value={dataFine}
                        label={strings.endDate}
                        minDate={dataInizio ? dataInizio : new Date()}
                        inputFormat={
                          strings.getLanguage() === "it"
                            ? "dd/MM/yyyy"
                            : "MM/dd/yyyy"
                        }
                        onChange={(val) => setDataFine(val)}
                        renderInput={(params) => (
                          <TextField
                            required
                            variant='standard'
                            {...params}
                            fullWidth
                          />
                        )}
                      />
                    </Stack>
                  </LocalizationProvider>
                  <br />
                  <Button
                    sx={{
                      width: { sd: "100%", md: "100%" },
                    }}
                    type='button'
                    onClick={employmentRequest}
                    variant='contained'
                    disabled={!dataInizio || !dataFine || jobs.length === 0}
                  >
                    {strings.inviaIngaggio}
                  </Button>
                  <br />
                  <br />
                  <ResponseAlertMessages
                    response={respInviaIngaggio}
                  ></ResponseAlertMessages>
                </Box>
              </Modal>
              <Modal
                open={openFavoriteModal}
                onClose={handleCloseFavoriteModal}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
              >
                <Box sx={modalStyle}>
                  <Typography
                    id='modal-modal-title'
                    variant='h6'
                    component='h2'
                  >
                    {strings.addFavourite}
                  </Typography>
                  <br />
                  <FormControl variant='standard' fullWidth>
                    <InputLabel id='categoria-utente-label'>
                      {strings.categories}
                    </InputLabel>
                    <Select
                      required
                      variant='standard'
                      disabled={false}
                      labelId='categoria-utente-label'
                      value={category}
                      label={strings.categories}
                      onChange={(event) => setCategory(event.target.value)}
                    >
                      <MenuItem value={-1} selected={true}>
                        {strings.selectAnOption}
                      </MenuItem>
                      {categories?.map((o, i) => {
                        return (
                          <MenuItem value={o.id_CategoriaPreferiti}>
                            {o.nome}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <br />
                  <br />
                  <TextField
                    required
                    placeholder={strings.categoryNamePlaceholder}
                    disabled={false}
                    label={strings.categoryName}
                    variant='standard'
                    onChange={(event) => setCategoryName(event.target.value)}
                  />
                  <Button
                    type='button'
                    onClick={addCategory}
                    variant='contained'
                  >
                    {strings.getLanguage() === "en"
                      ? strings.addCategories
                      : strings.addCategoriesIta}
                  </Button>
                  <ResponseAlertMessages
                    response={respPostCategory}
                  ></ResponseAlertMessages>
                  <br />
                  <br />
                  <Button
                    disabled={!category || category === -1}
                    sx={{
                      width: { sd: "100%", md: "100%" },
                    }}
                    type='button'
                    onClick={addFavorite}
                    variant='contained'
                  >
                    {strings.conferma}
                  </Button>
                  <br />
                  <br />
                  <ResponseAlertMessages
                    response={respPostFavorite}
                  ></ResponseAlertMessages>
                </Box>
              </Modal>
              <Modal
                open={openShareModal}
                onClose={handleCloseShareModal}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
              >
                <Box sx={modalStyle}>
                  <Typography
                    id='modal-modal-title'
                    variant='h6'
                    component='h2'
                  >
                    {strings.shareFavourite}
                  </Typography>
                  <br />
                  <FormControl variant='standard' fullWidth>
                    <InputLabel id='categoria-utente-label'>
                      {strings.categories}
                    </InputLabel>
                    <Select
                      required
                      variant='standard'
                      disabled={false}
                      labelId='categoria-utente-label'
                      value={category}
                      label={strings.categories}
                      onChange={(event) => setCategory(event.target.value)}
                    >
                      <MenuItem value={-1}>{strings.selectAnOption}</MenuItem>
                      {categories?.map((o, i) => {
                        return (
                          <MenuItem value={o.id_CategoriaPreferiti}>
                            {o.nome}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <br />
                  <br />
                  <Button
                    type='button'
                    onClick={shareCategory}
                    variant='contained'
                  >
                    {strings.conferma}
                  </Button>
                  <ResponseAlertMessages
                    response={respPostShareCategory}
                  ></ResponseAlertMessages>
                </Box>
              </Modal>
            </div>
          ) : (
            ""
          )}
        </Stack>
      </form>
      <br />
      <br />
    </>
  );
}
