import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getUserDataAsync, selectLoginData } from "../welcome/welcomeSlice";
import { DISMISS_TIME_MS, profileStepMap, strings } from './../../Constants';
import Bancari from './Bancari';
import Cittadinanza from './Cittadinanza';
import DatiFisici from './DatiFisici';
import DatiPersonaliGuiridici from './DatiPersonaliGuiridici';
import Disponibilita from './Disponibilita';
import Familiari from './Familiari';
import Fiscali from './Fiscali';
import Professionali from './Professionali';
import Profile from './Profile';
import { resetRespSaveAll } from "./profileSlice";


function ProfileTabs(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

ProfileTabs.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs(props) {
  const [value, setValue] = React.useState(props.index);
  const loggedUser = useSelector(selectLoginData);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onTabNext = () => {
    dispatch(getUserDataAsync());
  };

  React.useEffect(() => {
    if (loggedUser?.workflow === 0) {
      if (localStorage.getItem("profileCompleted_"+loggedUser?.username)!==null){
        setTimeout(() => dispatch(resetRespSaveAll()), DISMISS_TIME_MS);
      }else{
        localStorage.setItem("profileCompleted_"+loggedUser?.username, "true");
        dispatch(resetRespSaveAll());
        const url = profileStepMap.get((loggedUser?.workflow - 1));
        if (url) {
          navigate(url, { replace: true });
          // console.log('navigiated', url);
        }
      }
      //navigate('/home', {replace: true});
    } else {
      dispatch(resetRespSaveAll());
      const url = profileStepMap.get((loggedUser?.workflow - 1));
      if (url) {
        navigate(url, { replace: true });
        // console.log('navigiated', url);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUser]);


  const handleChange = (event, newValue) => {
    setValue(newValue);
    props.onTabChange(newValue);

  };

  React.useEffect(() => {
    // console.log(props);
    setValue(props.index);
  }, [props]);

  const isTabDisabled = (idx) => {
    return loggedUser?.workflow !== 0 && loggedUser?.workflow < idx + 1;
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          value={value} onChange={handleChange} aria-label={strings.tabsAriaLabel}>
          <Tab label={strings.tabDisponibilita} {...a11yProps(0)} />
          <Tab disabled={isTabDisabled(1)} label={strings.tabProfilo} {...a11yProps(1)} />
          <Tab disabled={isTabDisabled(2)} label={strings.tabPersonaliGiurd} {...a11yProps(2)} />
          <Tab disabled={isTabDisabled(3)} label={strings.tabCittadinanza} {...a11yProps(3)} />
          <Tab disabled={isTabDisabled(4)} label={strings.tabFisici} {...a11yProps(4)} />
          <Tab disabled={isTabDisabled(5)} label={strings.tabFamiliari} {...a11yProps(5)} />
          <Tab disabled={isTabDisabled(6)} label={strings.tabProfessionali} {...a11yProps(6)} />
          <Tab disabled={isTabDisabled(7)} label={strings.tabFiscali} {...a11yProps(7)} />
          <Tab disabled={isTabDisabled(8)} label={strings.tabBancari} {...a11yProps(8)} />
        </Tabs>
      </Box>
      <ProfileTabs value={value} index={0}>
        <Disponibilita onTabNext={onTabNext}></Disponibilita>
      </ProfileTabs>
      <ProfileTabs value={value} index={1}>
        <Profile onTabNext={onTabNext}></Profile>
      </ProfileTabs>
      <ProfileTabs value={value} index={2}>
        <DatiPersonaliGuiridici onTabNext={onTabNext}></DatiPersonaliGuiridici>
      </ProfileTabs>
      <ProfileTabs value={value} index={3}>
        <Cittadinanza onTabNext={onTabNext}></Cittadinanza>
      </ProfileTabs>
      <ProfileTabs value={value} index={4}>
        <DatiFisici onTabNext={onTabNext}></DatiFisici>
      </ProfileTabs>
      <ProfileTabs value={value} index={5}>
        <Familiari onTabNext={onTabNext}></Familiari>
      </ProfileTabs>
      <ProfileTabs value={value} index={6}>
        <Professionali onTabNext={onTabNext}></Professionali>
      </ProfileTabs>
      <ProfileTabs value={value} index={7}>
        <Fiscali onTabNext={onTabNext}></Fiscali>
      </ProfileTabs>
      <ProfileTabs value={value} index={8}>
        <Bancari onTabNext={onTabNext}></Bancari>
      </ProfileTabs>
    </Box>
  );
}