import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { cloneDeep } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectLoginData } from "../welcome/welcomeSlice";
import { COMMON_SX, FORM_INTERNAL_SX, strings } from "./../../Constants";
import ResponseAlertMessages from "./../commons/ResponseAlertMessages";
import {
  getDatiFamiliariAsync,
  putDatiFamiliariAsync,
  selectRespDatiFamiliari,
  selectRespSaveDatiFamiliari,
} from "./profileSlice";

export default function Familiari(props) {
  const dispatch = useDispatch();
  const loggedUser = useSelector(selectLoginData);
  const profileResponse = useSelector(selectRespDatiFamiliari);
  const profileSaveResponse = useSelector(selectRespSaveDatiFamiliari);
  const [disabled, setDisabled] = useState(true);
  const [statoCivile, setStatoCivile] = useState(-1);
  const [codiceFiscaleConiuge, setCodiceFiscaleConiuge] = useState(null);
  const [numeroFigliACarico, setNumeroFigliACarico] = useState(0);
  const [percentualeFigliACarico, setPercentualeFigliACarico] = useState(0);
  const [listaFigliACarico, setListaFigliACarico] = useState([]);

  useEffect(() => {
    resetFields();
    //console.log(loggedUser);
    if (loggedUser) {
      dispatch(getDatiFamiliariAsync());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (profileSaveResponse != null) {
      setDisabled(false);
      //  console.log(profileSaveResponse);
      if (profileSaveResponse.respdata?.success) {
        props.onTabNext();
        /*
        const url = profileStepMap.get(loggedUser.workflow);
        dispatch(resetRespSaveDatiFamiliari());
        navigate(url, {replace: true});
        console.log('navigiated', url);
        */
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileSaveResponse]);

  const translateSelect = (value) => {
    if (strings.getLanguage() !== "en") {
      if (value === strings.celibeNubile) {
        return "Celibe/Nubile";
      }
      if (value === strings.coniugato) {
        return "Coniugato/a";
      }
      if (value === strings.vedovo) {
        return "Vedovo/a";
      }
      if (value === strings.divorziato) {
        return "Divorziato/a";
      }
    }
    return value;
  };

  const resetFields = () => {
    setDisabled(true);
    setStatoCivile(-1);
    setCodiceFiscaleConiuge(null);
    setNumeroFigliACarico(0);
    setPercentualeFigliACarico(0);
    setListaFigliACarico([]);
  };

  useEffect(() => {
    if (profileResponse?.respdata?.success) {
      //console.log(profileResponse?.respdata)
      setDisabled(false);

      setStatoCivile(profileResponse?.respdata.data.statoCivile);
      setCodiceFiscaleConiuge(
        profileResponse?.respdata.data.codiceFiscaleConiuge
      );
      setNumeroFigliACarico(profileResponse?.respdata.data.numeroFigliACarico);
      setPercentualeFigliACarico(
        profileResponse?.respdata.data.percentualeFigliACarico
      );
      setListaFigliACarico(profileResponse?.respdata.data.listaFigliACarico);
    }
  }, [profileResponse]);

  const setListaFigliACaricoSize = (size) => {
    const listaFigliACarico2 = [];
    for (let i = 0; i < size; i++) {
      if (
        listaFigliACarico === null ||
        typeof listaFigliACarico[i] === "undefined"
      ) {
        listaFigliACarico2.push({
          id_FiglioACarico: 0,
          codiceFiscaleFiglio: null,
        });
      } else {
        if (typeof listaFigliACarico[i] !== "undefined") {
          listaFigliACarico2.push(listaFigliACarico[i]);
        }
      }
    }
    console.log(listaFigliACarico2);
    setListaFigliACarico(listaFigliACarico2);
  };

  const setCodiceFiscaleFiglio = (idx, id_FiglioACarico, value) => {
    const listaFigliACarico2 = cloneDeep(listaFigliACarico);
    if (id_FiglioACarico > 0) {
      const figlioIdx = listaFigliACarico.findIndex(
        (o) => o.id_FiglioACarico === id_FiglioACarico
      );
      if (figlioIdx !== -1) {
        listaFigliACarico2[figlioIdx].codiceFiscaleFiglio = value;
      }
    } else {
      listaFigliACarico2[idx].codiceFiscaleFiglio = value;
    }
    setListaFigliACarico(listaFigliACarico2);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!disabled) {
      setDisabled(true);

      dispatch(
        putDatiFamiliariAsync({
          statoCivile,
          codiceFiscaleConiuge,
          numeroFigliACarico: parseInt(numeroFigliACarico),
          percentualeFigliACarico: parseInt(percentualeFigliACarico),
          listaFigliACarico,
        })
      );
    }
  };

  return (
    <div>
      <ResponseAlertMessages
        loadResponse={profileResponse}
        response={profileSaveResponse}
      ></ResponseAlertMessages>

      <br />
      {profileResponse?.respdata ? (
        <form component="form" sx={FORM_INTERNAL_SX} onSubmit={onSubmit}>
          <Stack
            className="muiStackFix"
            spacing={2}
            direction="column"
            sx={COMMON_SX}
          >
            <FormControl fullWidth>
              <InputLabel id="sc-utente-label">
                {strings.getLanguage() !== "en"
                  ? "Stato civile"
                  : "Marital Status"}
              </InputLabel>
              <Select
                variant="standard"
                disabled={disabled}
                labelId="sc-utente-label"
                value={statoCivile}
                label={
                  strings.getLanguage() !== "en"
                    ? "Stato civile"
                    : "Civil Status"
                }
                onChange={(event) => setStatoCivile(event.target.value)}
              >
                {profileResponse?.respdata?.data?.listaStatiCivili?.map(
                  (o, i) => {
                    return (
                      <MenuItem value={o.chiave}>
                        {translateSelect(o.valore)}
                      </MenuItem>
                    );
                  }
                )}
              </Select>
            </FormControl>

            {statoCivile === 1 ? (
              <TextField
                disabled={disabled}
                inputProps={{
                  pattern:
                    "^([A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1})$",
                }}
                label={
                  strings.getLanguage() !== "en"
                    ? "Codice Fiscale Coniuge"
                    : "Spouse Tax Code"
                }
                variant="standard"
                value={codiceFiscaleConiuge}
                defaultValue={codiceFiscaleConiuge}
                onChange={(event) =>
                  setCodiceFiscaleConiuge(event.target.value)
                }
              />
            ) : (
              ""
            )}

            <Grid className="muiGridFix" container columnSpacing={2}>
              <Grid item xs={6} md={4}>
                <TextField
                  type="number"
                  placeholder="1"
                  disabled={disabled}
                  label={
                    strings.getLanguage() !== "en"
                      ? "Numero figli a carico"
                      : "Number of dependent children"
                  }
                  variant="standard"
                  value={numeroFigliACarico}
                  defaultValue={numeroFigliACarico}
                  onChange={(event) => {
                    setNumeroFigliACarico(event.target.value);
                    setListaFigliACaricoSize(event.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={6} md={8}>
                <TextField
                  type="number"
                  placeholder="50"
                  disabled={disabled}
                  label={
                    strings.getLanguage() !== "en"
                      ? "Percentuale figli a carico"
                      : "Percentage of dependent children"
                  }
                  variant="standard"
                  value={percentualeFigliACarico}
                  defaultValue={percentualeFigliACarico}
                  onChange={(event) =>
                    setPercentualeFigliACarico(event.target.value)
                  }
                />
              </Grid>
            </Grid>

            {listaFigliACarico?.map((o, i) => (
              <TextField
                disabled={disabled}
                inputProps={{
                  pattern:
                    "^([A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1})$",
                }}
                label={"Codice fiscale figlio " + (i + 1)}
                variant="standard"
                value={o.codiceFiscaleFiglio}
                defaultValue={o.codiceFiscaleFiglio}
                onChange={(event) =>
                  setCodiceFiscaleFiglio(
                    i,
                    o.id_FiglioACarico,
                    event.target.value
                  )
                }
              />
            ))}

            <Button type="submit" disabled={disabled} variant="contained">
              {strings.getLanguage() !== "en" ? "Salva" : "Save"}
            </Button>
          </Stack>
        </form>
      ) : (
        <div className="loaderDiv">
          <CircularProgress></CircularProgress>
        </div>
      )}
    </div>
  );
}
