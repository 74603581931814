import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getPagamenti,
  postPagamenti,
  getSubscription,
  deleteSubscription,
  postSubscription,
  getSubscriptionList,
  getPagamentiKey,
  createStripeCardToken,
  putSubscription,
} from "./subscriptionAPI";

const initialState = {
  respPagamenti: null,
  respPostPagamenti: null,
  respSubscription: null,
  respPostSubscription: null,
  respPutSubscription: null,
  respSubscriptionList: null,
  respStripeCardToken: null,
  respPagamentiKey: null,
  respDeleteSubscription: null,
  stauts: "idle",
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getPagamentiAsync = createAsyncThunk(
  "subscr/getPagamenti",
  async (params) => {
    const response = await getPagamenti();
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const getPagamentiKeyAsync = createAsyncThunk(
  "subscr/getPagamenti/key",
  async (params) => {
    const response = await getPagamentiKey();
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const postPagamentiAsync = createAsyncThunk(
  "subscr/postPagamenti",
  async (params) => {
    const response = await postPagamenti(params);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const createStripeCardTokenAsync = createAsyncThunk(
  "subscr/createStripeCardToken",
  async (params) => {
    const { pk, cardNumber, cardExpMonth, cardExpYear, cardCVC } = params;
    const response = await createStripeCardToken(
      pk,
      cardNumber,
      cardExpMonth,
      cardExpYear,
      cardCVC
    );
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const getSubscriptionAsync = createAsyncThunk(
  "subscr/get",
  async (params) => {
    const response = await getSubscription();
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const deleteSubscriptionAsync = createAsyncThunk(
  "subscr/delete",
  async (params) => {
    const response = await deleteSubscription();
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const postSubscriptionAsync = createAsyncThunk(
  "subscr/post",
  async (params) => {
    const response = await postSubscription(params);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const putSubscriptionAsync = createAsyncThunk(
  "subscr/put",
  async (params) => {
    const response = await putSubscription(params);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const getSubscriptionListAsync = createAsyncThunk(
  "subscr/get/list",
  async (params) => {
    const response = await getSubscriptionList();
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  // The `reduce:rs` field lets us define reducers and generate associated actions
  reducers: {
    resetRespPostPagamenti: (state) => {
      state.respPostPagamenti = null;
    },
    resetRespPostSubscription: (state) => {
      state.respPostSubscription = null;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getPagamentiAsync.pending, (state) => {
        state.status = "loading";
      })

      .addCase(getPagamentiAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.respPagamenti = action.payload;
      })

      .addCase(postPagamentiAsync.pending, (state) => {
        state.status = "loading";
      })

      .addCase(postPagamentiAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.respPostPagamenti = action.payload;
        state.respPagamenti = action.payload;
      })

      .addCase(getSubscriptionAsync.pending, (state) => {
        state.status = "loading";
      })

      .addCase(getSubscriptionAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.respSubscription = action.payload;
      })

      .addCase(deleteSubscriptionAsync.pending, (state) => {
        state.status = "loading";
      })

      .addCase(deleteSubscriptionAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.respDeleteSubscription = action.payload;
      })

      .addCase(getSubscriptionListAsync.pending, (state) => {
        state.status = "loading";
      })

      .addCase(getSubscriptionListAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.respSubscriptionList = action.payload;
      })

      .addCase(getPagamentiKeyAsync.pending, (state) => {
        state.status = "loading";
      })

      .addCase(getPagamentiKeyAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.respPagamentiKey = action.payload;
      })

      .addCase(createStripeCardTokenAsync.pending, (state) => {
        state.status = "loading";
      })

      .addCase(createStripeCardTokenAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.respStripeCardToken = action.payload;
      })

      .addCase(postSubscriptionAsync.pending, (state) => {
        state.status = "loading";
      })

      .addCase(postSubscriptionAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.respPostSubscription = action.payload;
      })

      .addCase(putSubscriptionAsync.pending, (state) => {
        state.status = "loading";
      })

      .addCase(putSubscriptionAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.respPostSubscription = action.payload;
      });
  },
});

export const { resetRespPostPagamenti, resetRespPostSubscription } =
  subscriptionSlice.actions;

export const selectRespSubscription = (state) =>
  state.subscription.respSubscription;
export const selectRespPostSubscription = (state) =>
  state.subscription.respPostSubscription;
export const selectRespPutSubscription = (state) =>
  state.subscription.respPutSubscription;
export const selectRespPagamenti = (state) => state.subscription.respPagamenti;
export const selectRespPagamentiKey = (state) =>
  state.subscription.respPagamentiKey;
export const selectRespPostPagamenti = (state) =>
  state.subscription.respPostPagamenti;
export const selectRespSubscriptionList = (state) =>
  state.subscription.respSubscriptionList;
export const selectRespStripeCardToken = (state) =>
  state.subscription.respStripeCardToken;
export const selectRespDeleteSubscription = (state) =>
  state.subscription.respDeleteSubscription;

export default subscriptionSlice.reducer;
