import jQuery from "jquery";
import { BASE_API_URL } from "../../Constants.js";

export function postInviaRichiestaIngaggio(params) {
  return new Promise((resolve) =>
    jQuery
      .ajax({
        type: "POST",
        url: BASE_API_URL + "/api/Ingaggi/InviaRichiestaIngaggio",
        // dataType: "json",
        contentType: "application/json; charset=utf-8",
        data: JSON.stringify(params),
      })
      .done((response) => resolve({ respdata: response }))
      .always((error) => resolve({ error }))
  );
}
