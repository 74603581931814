/* eslint-disable react-hooks/exhaustive-deps */
import Box from "@mui/material/Box";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FORM_INTERNAL_SX, strings, _INTERNAL_WIDTH_PX } from "../../Constants";
import { selectLoginData } from "../welcome/welcomeSlice";
import { getJobsAsync, selectRespGetJobs } from "./jobSlice";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useState } from "react";
import Job from "./Job";

//https://stackoverflow.com/questions/39419237/what-is-mapdispatchtoprops
//https://newbedev.com/react-hook-useeffect-has-a-missing-dependency-dispatch
export default function Jobs() {
  const dispatch = useDispatch();
  //const stableDispatch = useCallback(dispatch, []) //assuming that it doesn't need to change

  const loggedUser = useSelector(selectLoginData);

  const respJobs = useSelector(selectRespGetJobs);
  const [jobList, setJobList] = useState([]);

  useEffect(() => {
    //console.log(loggedUser);
    if (loggedUser) {
      dispatch(getJobsAsync());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUser]);

  useEffect(() => {
    console.log("INGAGGI", respJobs);
    if (respJobs?.respdata?.success) {
      setJobList(respJobs.respdata.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [respJobs]);

  return (
    <div>
      <Box component='form' sx={FORM_INTERNAL_SX}>
        <Box
          className='dontUseMuiStackHere'
          sx={{ p: 1, m: "0 auto", width: _INTERNAL_WIDTH_PX }}
        >
          <h2 style={{ textAlign: "center" }}>{strings.jobManagment}</h2>
          <br />
          <Link to={"/jobs/new"}>
            <span style={{ display: "block" }} className='genericBox clickable'>
              <h4>{strings.createNewJob}</h4>
              <br />
              <div>{strings.createNewJobDescr}</div>
            </span>
          </Link>
          <br />
          <br />
          <>
            {
              <Grid
                container
                rowSpacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 2 }}
              >
                {jobList?.map((o, i) => {
                  return (
                    <Grid item xs={12} sm={12} md={12} key={i}>
                      <Job job={o} i={i + 1}></Job>
                    </Grid>
                  );
                })}
              </Grid>
            }
          </>
          <br />
          <br />
        </Box>
      </Box>
    </div>
  );
}
