import jQuery from "jquery";
import { BASE_API_URL } from "../../Constants.js";

export function recuperaProfilo(id) {
  return new Promise((resolve) =>
    jQuery
      .ajax({
        type: "GET",
        url: BASE_API_URL + "/api/Profilo/RecuperaProfilo/"+id,
        // dataType: "json",
        //contentType: "application/json; charset=utf-8",
       // data: JSON.stringify({ username: username, password: password }),
      })
      .done((response) => resolve({ respdata: response }))
      .always((error) => resolve({ error }))
  );
}

export function recuperaProfiloUtente(id) {
  return new Promise((resolve) =>
    jQuery
      .ajax({
        type: "GET",
        url: BASE_API_URL + "/api/Preferiti/"+id,
        // dataType: "json",
        //contentType: "application/json; charset=utf-8",
       // data: JSON.stringify({ username: username, password: password }),
      })
      .done((response) => resolve({ respdata: response }))
      .always((error) => resolve({ error }))
  );
}

export function recuperaFotoProfilo() {
  return new Promise((resolve) =>
    jQuery
      .ajax({
        type: "GET",
        url: BASE_API_URL + "/api/Profilo/RecuperaImmagineProfilo/",
        // dataType: "json",
        //contentType: "application/json; charset=utf-8",
       // data: JSON.stringify({ username: username, password: password }),
       data: {
        base64: true
      },
      })
      .done((response) => resolve({ respdata: response }))
      .always((error) => resolve({ error }))
  );
}

export function recuperaDatiProfilo() {
  return new Promise((resolve) =>
    jQuery
      .ajax({
        type: "GET",
        url: BASE_API_URL + "/api/Profilo/RecuperaDatiProfilo/",
        // dataType: "json",
        //contentType: "application/json; charset=utf-8",
       // data: JSON.stringify({ username: username, password: password }),
      })
      .done((response) => resolve({ respdata: response }))
      .always((error) => resolve({ error }))
  );
}


export function salvaDatiProfilo(params) {
  return new Promise((resolve) =>
    jQuery
      .ajax({
        type: "PUT",
        url: BASE_API_URL + "/api/Profilo/SalvaDatiProfilo",
        data: JSON.stringify(params),
        // dataType: "json",
        contentType: "application/json; charset=utf-8",
       // data: JSON.stringify({ username: username, password: password }),
      })
      .done((response) => resolve({ respdata: response }))
      .always((error) => resolve({ error }))
  );
}

export function recuperaDatiDisponibilita() {
  return new Promise((resolve) =>
    jQuery
      .ajax({
        type: "GET",
        url: BASE_API_URL + "/api/Profilo/RecuperaDatiDisponibilita/",
        // dataType: "json",
        //contentType: "application/json; charset=utf-8",
       // data: JSON.stringify({ username: username, password: password }),
      })
      .done((response) => resolve({ respdata: response }))
      .always((error) => resolve({ error }))
  );
}


export function salvaDatiDisponibilita(params) {
  return new Promise((resolve) =>
    jQuery
      .ajax({
        type: "PUT",
        url: BASE_API_URL + "/api/Profilo/SalvaDatiDisponibilita",
        data: JSON.stringify(params),
        // dataType: "json",
        contentType: "application/json; charset=utf-8",
       // data: JSON.stringify({ username: username, password: password }),
      })
      .done((response) => resolve({ respdata: response }))
      .always((error) => resolve({ error }))
  );
}



export function recuperaDatiPersonaliGiuridici() {
  return new Promise((resolve) =>
    jQuery
      .ajax({
        type: "GET",
        url: BASE_API_URL + "/api/Profilo/RecuperaDatiPersonaliGiuridici/",
        // dataType: "json",
        //contentType: "application/json; charset=utf-8",
       // data: JSON.stringify({ username: username, password: password }),
      })
      .done((response) => resolve({ respdata: response }))
      .always((error) => resolve({ error }))
  );
}


export function salvaDatiPersonaliGiuridici(params) {
  return new Promise((resolve) =>
    jQuery
      .ajax({
        type: "PUT",
        url: BASE_API_URL + "/api/Profilo/SalvaDatiPersonaliGiuridici",
        data: JSON.stringify(params),
        // dataType: "json",
        contentType: "application/json; charset=utf-8",
       // data: JSON.stringify({ username: username, password: password }),
      })
      .done((response) => resolve({ respdata: response }))
      .always((error) => resolve({ error }))
  );
}




export function recuperaDatiCittadinanza() {
  return new Promise((resolve) =>
    jQuery
      .ajax({
        type: "GET",
        url: BASE_API_URL + "/api/Profilo/RecuperaDatiCittadinanza/",
        // dataType: "json",
        //contentType: "application/json; charset=utf-8",
       // data: JSON.stringify({ username: username, password: password }),
      })
      .done((response) => resolve({ respdata: response }))
      .always((error) => resolve({ error }))
  );
}


export function salvaDatiCittadinanza(params) {
  return new Promise((resolve) =>
    jQuery
      .ajax({
        type: "PUT",
        url: BASE_API_URL + "/api/Profilo/SalvaDatiCittadinanza",
        data: JSON.stringify(params),
        // dataType: "json",
        contentType: "application/json; charset=utf-8",
       // data: JSON.stringify({ username: username, password: password }),
      })
      .done((response) => resolve({ respdata: response }))
      .always((error) => resolve({ error }))
  );
}


export function recuperaDatiFisici() {
  return new Promise((resolve) =>
    jQuery
      .ajax({
        type: "GET",
        url: BASE_API_URL + "/api/Profilo/RecuperaDatiFisici/",
        // dataType: "json",
        //contentType: "application/json; charset=utf-8",
       // data: JSON.stringify({ username: username, password: password }),
      })
      .done((response) => resolve({ respdata: response }))
      .always((error) => resolve({ error }))
  );
}


export function salvaDatiFisici(params) {
  return new Promise((resolve) =>
    jQuery
      .ajax({
        type: "PUT",
        url: BASE_API_URL + "/api/Profilo/SalvaDatiFisici",
        data: JSON.stringify(params),
        // dataType: "json",
        contentType: "application/json; charset=utf-8",
       // data: JSON.stringify({ username: username, password: password }),
      })
      .done((response) => resolve({ respdata: response }))
      .always((error) => resolve({ error }))
  );
}


export function recuperaDatiFamiliari() {
  return new Promise((resolve) =>
    jQuery
      .ajax({
        type: "GET",
        url: BASE_API_URL + "/api/Profilo/RecuperaDatiFamiliari/",
        // dataType: "json",
        //contentType: "application/json; charset=utf-8",
       // data: JSON.stringify({ username: username, password: password }),
      })
      .done((response) => resolve({ respdata: response }))
      .always((error) => resolve({ error }))
  );
}


export function salvaDatiFamiliari(params) {
  return new Promise((resolve) =>
    jQuery
      .ajax({
        type: "PUT",
        url: BASE_API_URL + "/api/Profilo/SalvaDatiFamiliari",
        data: JSON.stringify(params),
        // dataType: "json",
        contentType: "application/json; charset=utf-8",
       // data: JSON.stringify({ username: username, password: password }),
      })
      .done((response) => resolve({ respdata: response }))
      .always((error) => resolve({ error }))
  );
}





export function recuperaDatiProfessionali() {
  return new Promise((resolve) =>
    jQuery
      .ajax({
        type: "GET",
        url: BASE_API_URL + "/api/Profilo/RecuperaDatiProfessionali/",
        // dataType: "json",
        //contentType: "application/json; charset=utf-8",
       // data: JSON.stringify({ username: username, password: password }),
      })
      .done((response) => resolve({ respdata: response }))
      .always((error) => resolve({ error }))
  );
}


export function salvaDatiProfessionali(params) {
  return new Promise((resolve) =>
    jQuery
      .ajax({
        type: "PUT",
        url: BASE_API_URL + "/api/Profilo/SalvaDatiProfessionali",
        data: JSON.stringify(params),
        // dataType: "json",
        contentType: "application/json; charset=utf-8",
       // data: JSON.stringify({ username: username, password: password }),
      })
      .done((response) => resolve({ respdata: response }))
      .always((error) => resolve({ error }))
  );
}



export function recuperaDatiFiscali() {
  return new Promise((resolve) =>
    jQuery
      .ajax({
        type: "GET",
        url: BASE_API_URL + "/api/Profilo/RecuperaDatiFiscali/",
        // dataType: "json",
        //contentType: "application/json; charset=utf-8",
       // data: JSON.stringify({ username: username, password: password }),
      })
      .done((response) => resolve({ respdata: response }))
      .always((error) => resolve({ error }))
  );
}


export function salvaDatiFiscali(params) {
  return new Promise((resolve) =>
    jQuery
      .ajax({
        type: "PUT",
        url: BASE_API_URL + "/api/Profilo/SalvaDatiFiscali",
        data: JSON.stringify(params),
        // dataType: "json",
        contentType: "application/json; charset=utf-8",
       // data: JSON.stringify({ username: username, password: password }),
      })
      .done((response) => resolve({ respdata: response }))
      .always((error) => resolve({ error }))
  );
}


export function recuperaDatiBancari() {
  return new Promise((resolve) =>
    jQuery
      .ajax({
        type: "GET",
        url: BASE_API_URL + "/api/Profilo/RecuperaDatiBancari",
        // dataType: "json",
        //contentType: "application/json; charset=utf-8",
       // data: JSON.stringify({ username: username, password: password }),
      })
      .done((response) => resolve({ respdata: response }))
      .always((error) => resolve({ error }))
  );
}


export function salvaDatiBancari(params) {
  return new Promise((resolve) =>
    jQuery
      .ajax({
        type: "PUT",
        url: BASE_API_URL + "/api/Profilo/SalvaDatiBancari",
        data: JSON.stringify(params),
        // dataType: "json",
        contentType: "application/json; charset=utf-8",
       // data: JSON.stringify({ username: username, password: password }),
      })
      .done((response) => resolve({ respdata: response }))
      .always((error) => resolve({ error }))
  );
}

export function aggiornaDatiFatturazione(params) {
  return new Promise((resolve) =>
    jQuery
      .ajax({
        type: "POST",
        url: BASE_API_URL + "/api/Profilo/aggiornaDatiFatturazione",
        data: JSON.stringify(params),
        // dataType: "json",
        contentType: "application/json; charset=utf-8",
       // data: JSON.stringify({ username: username, password: password }),
      })
      .done((response) => resolve({ respdata: response }))
      .always((error) => resolve({ error }))
  );
}

export function uploadMultipartFotoProfilo({files=[]}) {
  var data = new FormData();
    files.forEach((file, i) => {
     //data.append("file-"+i, file);
      data.append("file", file);
    });
    return new Promise((resolve) =>
    jQuery.ajax({
      method: "POST",
      url: BASE_API_URL+"/api/Profilo/CaricaImmagineProfilo",
      data: data,
      dataType: 'json',
      cache: false,
      processData: false,
      contentType: false
    }).done((response) => resolve({ respdata: response }))
    .always((error) => resolve({ error }))
    );
}


export function uploadMultipartDoc({files=[],tipoDocumento=0}){
  var data = new FormData();
    files.forEach((file, i) => {
     //data.append("file-"+i, file);
      data.append("files", file);
    });
    return new Promise((resolve) =>
    jQuery.ajax({
      method: "POST",
      url: BASE_API_URL+"/api/Profilo/CaricaFile/"+tipoDocumento,
      data: data,
      dataType: 'json',
      cache: false,
      processData: false,
      contentType: false
    }).done((response) => resolve({ respdata: response }))
    .always((error) => resolve({ error }))
    );
}

