import Box from '@mui/material/Box';
import React from "react";
import { Link } from 'react-router-dom';
import AvatarSimple from './../commons/AvatarSimple';

export default function SearchResult(props) {


  return (
    <>
      <div className="genericBox searchResult">
      <Box className="paddingZero" component={Link} to={"/search/result/user/"+props.result.userId}  sx={{p:0}}>
        <AvatarSimple userId={props.result.userId}></AvatarSimple> <span className="blueDash"></span>
        <div style={{textTransform: 'none', borderBottom: '1px solid lightgray'}}>{props.result.nome} {props.result.cognome}</div>
         <br/>
        </Box>
      </div>
    </>


  );
}
