import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getJobs,
  postJob,
  putJob,
  deleteJob,
  getIngaggiInviati,
  getIngaggiAccettati,
} from "./jobAPI";

const initialState = {
  respGetJobs: null,
  respPostJob: null,
  respPutJob: null,
  respDeleteJob: null,
  respIngaggiAccettati: null,
  respIngaggiInviati: null,
  stauts: "idle",
};

export const getJobsAsync = createAsyncThunk("jobs/get", async (params) => {
  const response = await getJobs();
  // The value we return becomes the `fulfilled` action payload
  return response;
});

export const getJobIngaggiInviatiAsync = createAsyncThunk(
  "jobs/getIngaggiInviati",
  async (params) => {
    const { job, page, per_page } = params;
    const response = await getIngaggiInviati(job, page, per_page);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const getJobIngaggiAccettatiAsync = createAsyncThunk(
  "jobs/getIngaggiAccettati",
  async (params) => {
    const { job, page, per_page } = params;
    const response = await getIngaggiAccettati(job, page, per_page);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const putJobAsync = createAsyncThunk("jobs/put", async (params) => {
  const response = await putJob(params);
  // The value we return becomes the `fulfilled` action payload
  return response;
});
export const postJobAsync = createAsyncThunk("jobs/post", async (params) => {
  const response = await postJob(params);
  // The value we return becomes the `fulfilled` action payload
  return response;
});

export const deleteJobAsync = createAsyncThunk(
  "jobs/delete",
  async (params) => {
    const { id } = params;
    const response = await deleteJob(id);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const jobSlice = createSlice({
  name: "job",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetRespJobs: (state) => {
      state.respPostJob = null;
      state.respPutJob = null;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getJobsAsync.pending, (state) => {
        state.status = "loading";
      })

      .addCase(getJobsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.respGetJobs = action.payload;
      })

      .addCase(postJobAsync.pending, (state) => {
        state.status = "loading";
      })

      .addCase(postJobAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.respPostJob = action.payload;
      })

      .addCase(putJobAsync.pending, (state) => {
        state.status = "loading";
      })

      .addCase(putJobAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.respPutJob = action.payload;
      })

      .addCase(deleteJobAsync.pending, (state) => {
        state.status = "loading";
      })

      .addCase(deleteJobAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.respDeleteJob = action.payload;
      })

      .addCase(getJobIngaggiAccettatiAsync.pending, (state) => {
        state.status = "loading";
      })

      .addCase(getJobIngaggiAccettatiAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.respIngaggiAccettati = action.payload;
      })

      .addCase(getJobIngaggiInviatiAsync.pending, (state) => {
        state.status = "loading";
      })

      .addCase(getJobIngaggiInviatiAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.respIngaggiInviati = action.payload;
      });
  },
});

export const { resetRespJobs } = jobSlice.actions;

export const selectRespGetJobs = (state) => state.job.respGetJobs;
export const selectRespPutJob = (state) => state.job.respPutJob;
export const selectRespPostJob = (state) => state.job.respPostJob;
export const selectRespDeleteJob = (state) => state.job.respDeleteJob;
export const selectRespIngaggiInviati = (state) => state.job.respIngaggiInviati;
export const selectRespIngaggiAccettati = (state) =>
  state.job.respIngaggiAccettati;

export default jobSlice.reducer;
