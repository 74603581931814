import React from "react";
import { Link } from 'react-router-dom';
import { strings } from '../../Constants';


export default function Job(props) {




  return (
    <>
       <Link to={"/jobs/"+props.job.id_Job}>
            <span style={{display: "block"}}  className="genericBox clickable">
              <h4>{strings.manageJob} n°{props.job.id_Job}</h4>
              <h4>{strings.title}: {props.job.nome}</h4>
              <br/>
              <div>{strings.editJob}</div>
            </span>
            </Link>
    </>


  );
}
