import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import FullCalendar from "@fullcalendar/react"; // must go before plugins
import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import AdapterDateFns from "@mui/lab/AdapterDateFns";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Badge from "@mui/material/Badge";
import Work from "@mui/icons-material/Work";
import BeachAccess from "@mui/icons-material/BeachAccess";
import Hotel from "@mui/icons-material/Hotel";
import Moment from "moment";

import {
  COMMON_SX,
  dpLocale,
  fcLocale,
  FORM_INTERNAL_SX,
  strings,
  toSQLDate,
  formatDate,
  MESSAGES2_SX,
} from "./../../Constants";

import { selectLoginData } from "../welcome/welcomeSlice";
import {
  getEnlistmentsAsync,
  selectRespGetEnlistments,
} from "../enlistment/enlistmentSlice";
import {
  sendTimesheetAsync,
  selectRespSendTimesheet,
  postTimesheetAsync,
  selectRespPostTimesheet,
  putTimesheetAsync,
  selectRespPutTimesheet,
  getTimesheetsAsync,
  selectRespGetTimesheets,
} from "../timesheet/timesheetSlice";
import { Alert, Grid } from "@mui/material";

export default function Timesheet(props) {
  const dispatch = useDispatch();

  const loggedUser = useSelector(selectLoginData);
  const respEnlistments = useSelector(selectRespGetEnlistments);
  const respTimesheets = useSelector(selectRespGetTimesheets);
  const respPostTimesheet = useSelector(selectRespPostTimesheet);
  const respPutTimesheet = useSelector(selectRespPutTimesheet);
  const respSendTimesheet = useSelector(selectRespSendTimesheet);
  const [enlistmentList, setEnlistmentList] = useState([]);
  const [timesheetList, setTimesheetList] = useState([]);

  const [events, setEvents] = useState([]);
  const [enlistmentId, setEnlistmentId] = useState(-1);
  const [workedHours, setWorkedHours] = useState(0);
  const [holidayHours, setHolidayHours] = useState(0);
  const [absenceHours, setAbsenceHours] = useState(0);
  const [date, setDate] = useState(Moment(new Date()).format("YYYY-MM-DD"));
  const [dateStart, setDateStart] = useState(
    Moment(new Date()).format("YYYY-MM-DD")
  );
  const [dateEnd, setDateEnd] = useState(
    Moment(new Date()).endOf("month").add(1, "days").format("YYYY-MM-DD")
  );
  const [mode, setMode] = useState("add");
  const [showSendButton, setShowSendButton] = useState(false);
  const [recordSent, setRecordSent] = useState(false);

  const resetFormData = () => {
    setWorkedHours(0);
    setHolidayHours(0);
    setAbsenceHours(0);
    setRecordSent(false);
  };
  const handleDateClick = (info) => {
    console.log("INSIDE HANDLE-DATE-CLICK", info);
    resetFormData();
    setMode("add");
    setDate(formatDate(info.dateStr));
    setOpenTimesheetModal(true);
  };

  const handleEventClick = (info) => {
    console.log("INSIDE HANDLE-EVENT-CLICK", info);
    setMode("update");
    setDate(
      formatDate(info.event.extendedProps.timesheetRecord.dataRiferimento)
    );
    setWorkedHours(info.event.extendedProps.timesheetRecord.oreLavorate);
    setHolidayHours(info.event.extendedProps.timesheetRecord.oreFerie);
    setAbsenceHours(info.event.extendedProps.timesheetRecord.orePermessi);
    setRecordSent(info.event.extendedProps.timesheetRecord.inviato);
    setOpenTimesheetModal(true);
  };

  const renderEventContent = (eventInfo) => {
    console.log(eventInfo);
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            {eventInfo.event.extendedProps.timesheetRecord.oreLavorate > 0 ? (
              <Badge
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                badgeContent={
                  eventInfo.event.extendedProps.timesheetRecord.oreLavorate
                }
                color="primary"
              >
                <Work color={"secondary"} />
              </Badge>
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={4}>
            {eventInfo.event.extendedProps.timesheetRecord.oreFerie > 0 ? (
              <Badge
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                badgeContent={
                  eventInfo.event.extendedProps.timesheetRecord.oreFerie
                }
                color="primary"
              >
                <BeachAccess color={"warning"} />
              </Badge>
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={4}>
            {eventInfo.event.extendedProps.timesheetRecord.orePermessi > 0 ? (
              <Badge
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                badgeContent={
                  eventInfo.event.extendedProps.timesheetRecord.orePermessi
                }
                color="primary"
              >
                <Hotel color={"primary"} />
              </Badge>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
        <hr />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {eventInfo.event.extendedProps.timesheetRecord.inviato === true
              ? strings.getLanguage() !== "en"
                ? "Record inviato"
                : "Record sended"
              : strings.getLanguage() !== "en"
              ? "Record da inviare"
              : "Record to send"}
          </Grid>
        </Grid>
      </>
    );
  };

  useEffect(() => {
    if (loggedUser) {
      dispatch(getEnlistmentsAsync());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUser]);

  useEffect(() => {
    if (respEnlistments?.respdata?.success) {
      setEnlistmentList(respEnlistments.respdata.data);
    }
  }, [respEnlistments]);

  useEffect(() => {
    console.log("INSIDE USEEFFECT OF GET-TIMESHEETS", respTimesheets);
    if (respTimesheets?.respdata?.success) {
      console.log(respTimesheets);
      setTimesheetList(respTimesheets.respdata.data);
      let events = [];
      respTimesheets?.respdata?.data?.map((record) => {
        events.push({
          modificabile: record.inviato ? false : true,
          disponibile: true,
          backgroundColor: "#FFF",
          color: "#000",
          borderColor: "#FFF",
          title: "",
          start: formatDate(record.dataRiferimento),
          allDay: true,
          timesheetRecord: record,
        });
        if (record.inviato === false) {
          setShowSendButton(true);
        }
      });
      setEvents(events);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [respTimesheets]);

  console.log("we resp della get:", timesheetList, "we array events:", events);

  useEffect(() => {
    dispatch(getTimesheetsAsync(enlistmentId));
    setEvents();
  }, [respPostTimesheet]);

  useEffect(() => {
    dispatch(getTimesheetsAsync(enlistmentId));
  }, [respPutTimesheet]);

  useEffect(() => {
    dispatch(getTimesheetsAsync(enlistmentId));
  }, [respSendTimesheet]);

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "clamp(300px, 50%, 500px)",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [openTimesheetModal, setOpenTimesheetModal] = React.useState(false);
  const handleOpenTimesheetModal = () => setOpenTimesheetModal(true);
  const handleCloseTimesheetModal = () => setOpenTimesheetModal(false);

  const handleEnlistmentSelection = (event) => {
    setEnlistmentId(event.target.value);
    let selectedEnlistment = enlistmentList?.find(
      (o) => o.id_Ingaggio === event.target.value
    );
    setDateEnd(
      selectedEnlistment
        ? formatDate(selectedEnlistment.dataFine)
        : Moment(new Date()).endOf("month").add(1, "days").format("YYYY-MM-DD")
    );
    setDateStart(
      selectedEnlistment
        ? formatDate(selectedEnlistment.dataInizio)
        : Moment(new Date()).format("YYYY-MM-DD")
    );
    dispatch(getTimesheetsAsync(event.target.value));
  };
  const addTimesheetEntry = () => {
    console.log("INSIDE ADD-TIMESHEET-ENTRY");
    const payload = {
      dataRiferimento: date,
      id_Ingaggio: enlistmentId,
      inviato: false,
      oreLavorate: parseInt(workedHours),
      oreFerie: parseInt(holidayHours),
      orePermessi: parseInt(absenceHours),
    };
    if (mode === "add") {
      dispatch(postTimesheetAsync(payload));
    } else if (mode === "update") {
      dispatch(putTimesheetAsync(payload));
    }
    resetFormData();
    setOpenTimesheetModal(false);
    dispatch(getTimesheetsAsync(enlistmentId));
  };

  const sendTimesheetEntry = () => {
    console.log("INSIDE SEND-TIMESHEET-ENTRY");
    const payload = {
      idIngaggio: enlistmentId,
    };
    dispatch(sendTimesheetAsync(payload));
    dispatch(getTimesheetsAsync(enlistmentId));
  };

  return (
    <>
      <hr />
      <Grid container spacing={10}>
        <Grid item xs={8}>
          <FormControl variant="standard" fullWidth>
            <InputLabel id="enlistment-timesheet-label">
              {strings.enlistments}
            </InputLabel>
            <Select
              required
              variant="standard"
              disabled={false}
              labelId="enlistment-timesheet-label"
              value={enlistmentId}
              label={strings.enlistment}
              onChange={handleEnlistmentSelection}
            >
              {enlistmentList?.map((enlistment) => {
                return (
                  <MenuItem value={enlistment.id_Ingaggio}>
                    {enlistment.nome}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <Button
            type="button"
            size={"small"}
            onClick={sendTimesheetEntry}
            variant="contained"
            disabled={!showSendButton}
          >
            {strings.record}
          </Button>
        </Grid>
      </Grid>
      <br />
      <br />
      {respPostTimesheet?.respdata?.success === false ? (
        <Alert sx={MESSAGES2_SX} severity="error">
          {strings.getLanguage() === "en"
            ? "The specified date cannot be greater than today"
            : respPostTimesheet?.respdata?.message}
        </Alert>
      ) : (
        ""
      )}
      {respSendTimesheet?.respdata?.success === false ? (
        <Alert sx={MESSAGES2_SX} severity="error">
          {strings.getLanguage() === "en"
            ? "The time report has already been invited for today. You can resend it tomorrow"
            : respSendTimesheet?.respdata?.message}
        </Alert>
      ) : (
        ""
      )}
      {respSendTimesheet?.respdata?.success === true ? (
        <Alert sx={MESSAGES2_SX} severity="info">
          {strings.getLanguage() === "en"
            ? "Email sent"
            : respSendTimesheet?.respdata?.message}
        </Alert>
      ) : (
        ""
      )}
      <br />
      <br />
      <FullCalendar
        width={600}
        validRange={{ start: dateStart, end: dateEnd }}
        height={600}
        dateClick={handleDateClick}
        eventClick={handleEventClick}
        events={events}
        locale={fcLocale}
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        eventContent={renderEventContent}
      />
      <Modal
        open={openTimesheetModal}
        onClose={handleCloseTimesheetModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {strings.timesheet}
          </Typography>
          <br />
          <FormControl variant={"standard"} fullWidth>
            <TextField
              required
              placeholder={
                workedHours > 0 ? workedHours : strings.hoursPlaceholder
              }
              disabled={recordSent}
              label={strings.oreLavorate}
              variant="standard"
              value={workedHours}
              onChange={(event) => setWorkedHours(event.target.value)}
            />
          </FormControl>
          <FormControl variant={"standard"} fullWidth>
            <TextField
              required
              placeholder={
                holidayHours > 0 ? holidayHours : strings.hoursPlaceholder
              }
              disabled={recordSent}
              label={strings.oreFerie}
              variant="standard"
              value={holidayHours}
              onChange={(event) => setHolidayHours(event.target.value)}
            />
          </FormControl>
          <FormControl variant={"standard"} fullWidth>
            <TextField
              required
              placeholder={
                absenceHours > 0 ? absenceHours : strings.hoursPlaceholder
              }
              disabled={recordSent}
              label={strings.orePermessi}
              variant="standard"
              value={absenceHours}
              onChange={(event) => setAbsenceHours(event.target.value)}
            />
          </FormControl>
          <br />
          <br />
          <Button
            type="button"
            onClick={addTimesheetEntry}
            variant="contained"
            disabled={recordSent}
          >
            {strings.confirmRecord}
          </Button>
        </Box>
      </Modal>
    </>
  );
}
