import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { default as React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { MESSAGES2_SX, STACK_SX, strings } from "../../Constants";
import logo from "../../logo-bb.png";
import { FORM_INTERNAL_SX } from "./../../Constants";
import {
  resetPasswordAsync,
  selectLoginData,
  selectResponseResetPassword,
} from "./welcomeSlice";

export default function ResetPassword() {
  const dispatch = useDispatch();
  const response = useSelector(selectResponseResetPassword);
  const loginData = useSelector(selectLoginData);
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(true);
  const [email, setEmail] = useState(null);
  const [username, setUsername] = useState(null);

  useEffect(() => {
    //console.log(loginData);
    if (loginData?.username) {
      navigate("/home", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginData]);

  useEffect(() => {
    //console.log(loginData);
    setDisabled(false);
    //console.log(response);
    if (response?.respdata?.success) {
      //navigate("/home", { replace: true });
    }
  }, [response]);

  const onSubmit = (e) => {
    e.preventDefault();
    if (!disabled) {
      setDisabled(true);
      // console.log(username,password,email);
      dispatch(resetPasswordAsync({ email: email, userName: username }));
    }
  };

  return (
    <div>
      <br />
      <div>
        {response?.respdata?.data?.esito ? (
          <Alert sx={MESSAGES2_SX} severity='success'>
            {strings.recoverPasswordOk}{" "}
            <Link to='/home'>&gt; {strings.signin}</Link>{" "}
          </Alert>
        ) : (
          ""
        )}

        {response?.respdata?.success === false ? (
          <Alert sx={MESSAGES2_SX} severity='info'>
            {response?.respdata?.message}{" "}
          </Alert>
        ) : (
          ""
        )}

        {response && response.error && response.error.responseJSON ? (
          <Alert sx={MESSAGES2_SX} severity='error'>
            {response.error.responseJSON.data.title}{" "}
          </Alert>
        ) : (
          ""
        )}

        {response && response.error && response.error.responseJSON
          ? response.error.responseJSON.modelValidationError.map((o, i) => (
              <div>
                <br />
                <Alert sx={MESSAGES2_SX} severity='error'>
                  {o}{" "}
                </Alert>
              </div>
            ))
          : ""}
      </div>
      <br />
      <form component='form' sx={FORM_INTERNAL_SX} onSubmit={onSubmit}>
        <Stack spacing={2} direction='column' sx={STACK_SX}>
          <img
            alt='logo'
            src={logo}
            style={{ margin: "0 auto", width: "60%", height: "auto" }}
          />

          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <h3 className='colorPrimary'>{strings.resetPasswordHeading}</h3>
          </Box>
          <br />
          <h4 className='colorGray'>{strings.recoverYourPassword}</h4>

          <TextField
            label='Email'
            type='email'
            variant='standard'
            autoComplete=''
            onChange={(event) => setEmail(event.target.value)}
          />

          <TextField
            label='Username'
            type='username'
            variant='standard'
            autoComplete=''
            onChange={(event) => setUsername(event.target.value)}
          />

          <br />
          <br />
          <br />
          <br />
          <br />
          <Button
            type='submit'
            disabled={!email && !username}
            variant='contained'
          >
            {strings.resetPassword}
          </Button>
          <Link to='/login'>{strings.alreadyHaveAccount}</Link>
        </Stack>
      </form>
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}
