import jQuery from "jquery";
import { BASE_API_URL } from "../../Constants.js";

export function getEnlistments() {
    return new Promise((resolve) =>
        jQuery
            .ajax({
                type: "GET",
                url: BASE_API_URL + "/api/Ingaggi/ListaIngaggi",
            })
            .done((response) => resolve({ respdata: response }))
            .always((error) => resolve({ error }))
    );
}

export function getEnlistmentRequests() {
    return new Promise((resolve) =>
        jQuery
            .ajax({
                type: "GET",
                url: BASE_API_URL + "/api/Ingaggi/RichiesteIngaggio",
            })
            .done((response) => resolve({ respdata: response }))
            .always((error) => resolve({ error }))
    );
}

export function postEnlistment(params) {
    return new Promise((resolve) =>
        jQuery
            .ajax({
                type: "POST",
                url: BASE_API_URL + "/api/Ingaggi/InviaRichiestaIngaggio",
                data: JSON.stringify(params),
                contentType: "application/json; charset=utf-8",
            })
            .done((response) => resolve({ respdata: response }))
            .always((error) => resolve({ error }))
    );
}


export function postInviaMailIngaggio(params) {
    return new Promise((resolve) =>
        jQuery
            .ajax({
                type: "POST",
                url: BASE_API_URL + "/api/Ingaggi/InviaMailIngaggioAccettato",
                data: JSON.stringify(params),
                contentType: "application/json; charset=utf-8",
            })
            .done((response) => resolve({ respdata: response }))
            .always((error) => resolve({ error }))
    );
}


export function putEnlistment(params) {
    return new Promise((resolve) =>
        jQuery
            .ajax({
                type: "PUT",
                url: BASE_API_URL + "/api/Ingaggi/AggiornaRichiestaIngaggio",
                data: JSON.stringify(params),
                contentType: "application/json; charset=utf-8",
            })
            .done((response) => resolve({ respdata: response }))
            .always((error) => resolve({ error }))
    );
}