import InfoIcon from "@mui/icons-material/Info";
import StarRateIcon from "@mui/icons-material/StarRate";
import Stack from "@mui/material/Stack";
import React from "react";
import { Link } from "react-router-dom";
import { COMMON_SX_PX, FORM_INTERNAL_SX_PX, strings } from "./../../Constants";

export default function Home(props) {
  console.log("HOME PROPERTIES", props);

  let mainMenu =
    props.subscriptionType === 2
      ? [
          { t: strings.profile, u: "/profile" },
          { t: strings.enlistments, u: "/enlistments" },
          { t: strings.subscriptions, u: "/subscriptions" },
          { t: strings.carSheets, starred: true, u: "/carsheets" },
          { t: strings.timeReports, starred: true, u: "/timesheets" },
          { t: strings.searchEngine, starred: true, u: "/search" },
          { t: strings.favorites, starred: true, u: "/favorites" },
          { t: strings.jobManagment, starred: true, u: "/jobs" },
          { t: strings.aboutUs, u: "/about-us" },
          { t: strings.privacy, u: "/privacy" },
          { t: strings.termAndCondsTitle, u: "/terms" },
        ]
      : [
          { t: strings.profile, u: "/profile" },
          { t: strings.enlistments, u: "/enlistments" },
          { t: strings.subscriptions, u: "/subscriptions" },
          { t: strings.aboutUs, u: "/about-us" },
          { t: strings.privacy, u: "/privacy" },
          { t: strings.termAndCondsTitle, u: "/terms" },
        ];

  return (
    <form component='form' sx={FORM_INTERNAL_SX_PX}>
      <Stack
        className='muiStackFix'
        spacing={2}
        direction='column'
        sx={COMMON_SX_PX}
      >
        <div className='spacerBig'></div>

        {mainMenu.map((o, i) => (
          <Link
            to={o.u}
            className='homeBox'
            style={{ display: "flex", flexDirection: "row" }}
          >
            <span style={{ alignSelf: "center" }}>{o.t}</span>
            <span
              style={{
                justifySelf: "flex-end",
                marginLeft: "auto",
                color: "#A5A5A5",
              }}
            >
              {o.starred ? (
                <StarRateIcon
                  style={{ marginRight: "20px", color: "#0091D9" }}
                ></StarRateIcon>
              ) : (
                ""
              )}
              <InfoIcon></InfoIcon>
            </span>
          </Link>
        ))}
        <div className='spacerBig'></div>
      </Stack>
    </form>
  );
}
