import React from 'react';
import {
  BrowserRouter
} from "react-router-dom";
import './App.css';
import MainLayout from './features/home/MainLayout';
import { BASE_ROOT_ROUTE_PATH } from './Constants';

function App() {
  return (
    <BrowserRouter  basename={BASE_ROOT_ROUTE_PATH}>
        <MainLayout ></MainLayout>
    </BrowserRouter>
  );
}

export default App;
