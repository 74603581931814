/* eslint-disable react-hooks/exhaustive-deps */
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ResponseAlertMessages from "../commons/ResponseAlertMessages";
import { selectLoginData } from "../welcome/welcomeSlice";
import { COMMON_SX, FORM_INTERNAL_SX, strings } from "./../../Constants";
import {
  getDatiBancariAsync,
  putDatiBancariAsync,
  selectRespDatiBancari,
  selectRespSaveDatiBancari,
} from "./profileSlice";

export default function Bancari(props) {
  const dispatch = useDispatch();
  const loggedUser = useSelector(selectLoginData);
  const profileResponse = useSelector(selectRespDatiBancari);
  const profileSaveResponse = useSelector(selectRespSaveDatiBancari);
  const [disabled, setDisabled] = useState(true);
  const [nomeBanca, setNomeBanca] = useState(null);
  const [iban, setIban] = useState(null);
  const [bic, setBic] = useState(null);

  useEffect(() => {
    resetFields();
    //console.log(loggedUser);
    if (loggedUser) {
      dispatch(getDatiBancariAsync());
    }
  }, []);

  useEffect(() => {
    if (profileSaveResponse != null) {
      setDisabled(false);
      // console.log(profileSaveResponse);
      if (profileSaveResponse.respdata?.success) {
        props.onTabNext();
        /*
        const url = profileStepMap.get(loggedUser.workflow);
        dispatch(resetRespSaveDatiBancari());
        navigate(url, {replace: true});
        console.log('navigiated', url);
        */
      }
    }
  }, [profileSaveResponse]);

  const resetFields = () => {
    setDisabled(true);
    setNomeBanca(null);
    setIban(null);
    setBic(null);
  };

  useEffect(() => {
    if (profileResponse?.respdata?.success) {
      console.log(profileResponse?.respdata);
      setDisabled(false);
      setIban(profileResponse?.respdata?.data?.iban);
      setNomeBanca(profileResponse?.respdata?.data?.nomeBanca);
      setBic(profileResponse?.respdata?.data?.swiftCode);
    }
  }, [profileResponse]);

  const onSubmit = (e) => {
    e.preventDefault();
    if (!disabled) {
      setDisabled(true);
      dispatch(
        putDatiBancariAsync({
          iban: iban,
          nomeBanca: nomeBanca,
          swiftCode: bic,
        })
      );
    }
  };

  return (
    <div>
      <ResponseAlertMessages
        loadResponse={profileResponse}
        response={profileSaveResponse}
      ></ResponseAlertMessages>

      <br />
      {profileResponse?.respdata ? (
        <form component='form' sx={FORM_INTERNAL_SX} onSubmit={onSubmit}>
          <Stack
            className='muiStackFix'
            spacing={2}
            direction='column'
            sx={COMMON_SX}
          >
            <TextField
              placeholder='IT...'
              disabled={disabled}
              label='IBAN'
              variant='standard'
              value={iban}
              defaultValue={iban}
              onChange={(event) => setIban(event.target.value)}
            />

            <TextField
              placeholder=''
              disabled={disabled}
              label={
                strings.getLanguage() !== "en" ? "Nome banca" : "Bank name"
              }
              variant='standard'
              value={nomeBanca}
              defaultValue={nomeBanca}
              onChange={(event) => setNomeBanca(event.target.value)}
            />

            <TextField
              required={false}
              placeholder='BIC/SWIFT'
              disabled={disabled}
              label='BIC/SWIFT'
              variant='standard'
              value={bic}
              defaultValue={bic}
              onChange={(event) => setBic(event.target.value)}
            />

            <Button type='submit' disabled={disabled} variant='contained'>
              {strings.getLanguage() !== "en" ? "Salva" : "Save"}
            </Button>
          </Stack>
        </form>
      ) : (
        <div className='loaderDiv'>
          <CircularProgress></CircularProgress>
        </div>
      )}
    </div>
  );
}
