import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  recuperaDatiProfilo,
  salvaDatiProfilo,
  salvaDatiDisponibilita,
  recuperaDatiDisponibilita,
  recuperaDatiPersonaliGiuridici,
  salvaDatiPersonaliGiuridici,
  recuperaDatiCittadinanza,
  salvaDatiCittadinanza,
  salvaDatiFisici,
  recuperaDatiFisici,
  recuperaDatiFamiliari,
  salvaDatiFamiliari,
  recuperaDatiProfessionali,
  salvaDatiProfessionali,
  recuperaDatiFiscali,
  salvaDatiFiscali,
  recuperaDatiBancari,
  salvaDatiBancari,
  uploadMultipartDoc,
  uploadMultipartFotoProfilo,
  recuperaProfilo,
  recuperaProfiloUtente,
  aggiornaDatiFatturazione,
  recuperaFotoProfilo,
} from "./profileAPI";

const initialState = {
  respProfilo: null,
  respProfiloUtente: null,
  respDatiProfilo: null,
  respSaveDatiProfilo: null,
  respDatiDisponibilita: null,
  respSaveDatiDisponibilita: null,
  respDatiPersonaliGuiridici: null,
  respSaveDatiPersonaliGiuridici: null,
  respDatiCittadinanza: null,
  respSaveDatiCittadinanza: null,
  respDatiFisici: null,
  respSaveDatiFisici: null,
  respDatiFamiliari: null,
  respSaveDatiFamiliari: null,
  respDatiProfessionali: null,
  respSaveDatiProfessionali: null,
  respDatiFiscali: null,
  respSaveDatiFiscali: null,
  respDatiBancari: null,
  respSaveDatiBancari: null,
  respDatiDocumento: null,
  respFotoProfilo: null,
  respDatiFatturazione: null,
  stauts: "idle",
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getProfiloAsync = createAsyncThunk(
  "profile/getProfilo",
  async (params) => {
    const response = await recuperaProfilo(params.id);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const getProfiloUtenteAsync = createAsyncThunk(
  "profile/getProfiloUtente",
  async (params) => {
    const response = await recuperaProfiloUtente(params.id);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const getDatiProfiloAsync = createAsyncThunk(
  "profile/getDatiProfilo",
  async (params) => {
    const response = await recuperaDatiProfilo();
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const getFotoProfiloAsync = createAsyncThunk(
  "profile/getFotoProfilo",
  async (params) => {
    const response = await recuperaFotoProfilo(params.id);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const putDatiProfiloAsync = createAsyncThunk(
  "profile/putDatiProfilo",
  async (params) => {
    const response = await salvaDatiProfilo(params);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const postDocumentoAsync = createAsyncThunk(
  "profile/caricaDocumento",
  async (params) => {
    const response = await uploadMultipartDoc(params);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const postFotoProfiloAsync = createAsyncThunk(
  "profile/caricaFotoProfilo",
  async (params) => {
    const response = await uploadMultipartFotoProfilo(params);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const getDatiDisponibilitaAsync = createAsyncThunk(
  "profile/getDatiDisponibilita",
  async (params) => {
    const response = await recuperaDatiDisponibilita();
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const putDatiDisponibilitaAsync = createAsyncThunk(
  "profile/putDatiDisponibilita",
  async (params) => {
    const response = await salvaDatiDisponibilita(params);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const getDatiPersonaliGuiridiciAsync = createAsyncThunk(
  "profile/getDatiPersonaliGuiridici",
  async (params) => {
    const response = await recuperaDatiPersonaliGiuridici();
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const putDatiPersonaliGuiridiciAsync = createAsyncThunk(
  "profile/putDatiPersonaliGuiridici",
  async (params) => {
    const response = await salvaDatiPersonaliGiuridici(params);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const getDatiCittadinanzaAsync = createAsyncThunk(
  "profile/getDatiCittadinanza",
  async (params) => {
    const response = await recuperaDatiCittadinanza();
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const putDatiCittadinanzaAsync = createAsyncThunk(
  "profile/putDatiCittadinanza",
  async (params) => {
    const response = await salvaDatiCittadinanza(params);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const getDatiFisiciAsync = createAsyncThunk(
  "profile/getDatiFisici",
  async (params) => {
    const response = await recuperaDatiFisici();
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const putDatiFisiciAsync = createAsyncThunk(
  "profile/putDatiFisici",
  async (params) => {
    const response = await salvaDatiFisici(params);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const getDatiFamiliariAsync = createAsyncThunk(
  "profile/getDatiFamiliari",
  async (params) => {
    const response = await recuperaDatiFamiliari();
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const putDatiFamiliariAsync = createAsyncThunk(
  "profile/putDatiFamiliari",
  async (params) => {
    const response = await salvaDatiFamiliari(params);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const getDatiProfessionaliAsync = createAsyncThunk(
  "profile/getDatiProfessionali",
  async (params) => {
    const response = await recuperaDatiProfessionali();
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const putDatiProfessionaliAsync = createAsyncThunk(
  "profile/putDatiProfessionali",
  async (params) => {
    const response = await salvaDatiProfessionali(params);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const getDatiFiscaliAsync = createAsyncThunk(
  "profile/getDatiFiscali",
  async (params) => {
    const response = await recuperaDatiFiscali();
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const putDatiFiscaliAsync = createAsyncThunk(
  "profile/putDatiFiscali",
  async (params) => {
    const response = await salvaDatiFiscali(params);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const getDatiBancariAsync = createAsyncThunk(
  "profile/getDatiBancari",
  async (params) => {
    const response = await recuperaDatiBancari();
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const putDatiBancariAsync = createAsyncThunk(
  "profile/putDatiBancari",
  async (params) => {
    const response = await salvaDatiBancari(params);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const postDatiFatturazioneAsync = createAsyncThunk(
  "profile/postDatiFatturazione",
  async (params) => {
    const response = await aggiornaDatiFatturazione(params);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetRespSaveDatiDisponibilita: (state) => {
      state.respSaveDatiDisponibilita = null;
    },
    resetRespSaveDatiProfilo: (state) => {
      state.respSaveDatiProfilo = null;
    },
    resetRespSaveDatiPersonaliGiuridici: (state) => {
      state.respSaveDatiPersonaliGiuridici = null;
    },
    resetRespSaveDatiCittadinanza: (state) => {
      state.respSaveDatiCittadinanza = null;
    },
    resetRespSaveDatiFisici: (state) => {
      state.respSaveDatiFisici = null;
    },
    resetRespSaveDatiFamiliari: (state) => {
      state.respSaveDatiFamiliari = null;
    },
    resetRespSaveDatiProfessionali: (state) => {
      state.respSaveDatiProfessionali = null;
    },
    resetRespSaveDatiFiscali: (state) => {
      state.respSaveDatiFiscali = null;
    },
    resetRespSaveDatiBancari: (state) => {
      state.respSaveDatiBancari = null;
    },
    resetRespProfiloUtente: (state) => {
      state.respProfiloUtente = null;
    },
    resetRespFotoProfiloUtente: (state) => {
      state.respFotoProfilo = null;
    },
    resetRespSaveAll: (state) => {
      state.respSaveDatiBancari = null;
      state.respSaveDatiCittadinanza = null;
      state.respSaveDatiFamiliari = null;
      state.respSaveDatiFiscali = null;
      state.respSaveDatiFisici = null;
      state.respSaveDatiPersonaliGiuridici = null;
      state.respSaveDatiProfessionali = null;
      state.respSaveDatiProfilo = null;
      state.respSaveDatiDisponibilita = null;
      state.respFotoProfilo = null;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder

      .addCase(getProfiloAsync.pending, (state) => {
        state.status = "loading";
      })

      .addCase(getProfiloAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.respProfilo = action.payload;
      })

      .addCase(getProfiloUtenteAsync.pending, (state) => {
        state.status = "loading";
      })

      .addCase(getProfiloUtenteAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.respProfiloUtente = action.payload;
      })

      .addCase(postFotoProfiloAsync.pending, (state) => {
        state.status = "loading";
      })

      .addCase(postFotoProfiloAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.respDatiFotoProfilo = action.payload;
      })

      .addCase(postDocumentoAsync.pending, (state) => {
        state.status = "loading";
      })

      .addCase(postDocumentoAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.respDatiDocumento = action.payload;
      })

      .addCase(getDatiProfiloAsync.pending, (state) => {
        state.status = "loading";
      })

      .addCase(getDatiProfiloAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.respDatiProfilo = action.payload;
      })

      .addCase(getFotoProfiloAsync.pending, (state) => {
        state.status = "loading";
      })

      .addCase(getFotoProfiloAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.respFotoProfilo = action.payload;
      })

      .addCase(putDatiProfiloAsync.pending, (state) => {
        state.status = "loading";
      })

      .addCase(putDatiProfiloAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.respSaveDatiProfilo = action.payload;
      })

      .addCase(getDatiDisponibilitaAsync.pending, (state) => {
        state.status = "loading";
      })

      .addCase(getDatiDisponibilitaAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.respDatiDisponibilita = action.payload;
      })

      .addCase(putDatiDisponibilitaAsync.pending, (state) => {
        state.status = "loading";
      })

      .addCase(putDatiDisponibilitaAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.respSaveDatiDisponibilita = action.payload;
      })

      .addCase(getDatiPersonaliGuiridiciAsync.pending, (state) => {
        state.status = "loading";
      })

      .addCase(getDatiPersonaliGuiridiciAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.respDatiPersonaliGiuridici = action.payload;
      })

      .addCase(putDatiPersonaliGuiridiciAsync.pending, (state) => {
        state.status = "loading";
      })

      .addCase(putDatiPersonaliGuiridiciAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.respSaveDatiPersonaliGiuridici = action.payload;
      })

      .addCase(getDatiCittadinanzaAsync.pending, (state) => {
        state.status = "loading";
      })

      .addCase(getDatiCittadinanzaAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.respDatiCittadinanza = action.payload;
      })

      .addCase(putDatiCittadinanzaAsync.pending, (state) => {
        state.status = "loading";
      })

      .addCase(putDatiCittadinanzaAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.respSaveDatiCittadinanza = action.payload;
      })

      .addCase(getDatiFisiciAsync.pending, (state) => {
        state.status = "loading";
      })

      .addCase(getDatiFisiciAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.respDatiFisici = action.payload;
      })

      .addCase(putDatiFisiciAsync.pending, (state) => {
        state.status = "loading";
      })

      .addCase(putDatiFisiciAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.respSaveDatiFisici = action.payload;
      })

      .addCase(getDatiFamiliariAsync.pending, (state) => {
        state.status = "loading";
      })

      .addCase(getDatiFamiliariAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.respDatiFamiliari = action.payload;
      })

      .addCase(putDatiFamiliariAsync.pending, (state) => {
        state.status = "loading";
      })

      .addCase(putDatiFamiliariAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.respSaveDatiFamiliari = action.payload;
      })

      .addCase(getDatiProfessionaliAsync.pending, (state) => {
        state.status = "loading";
      })

      .addCase(getDatiProfessionaliAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.respDatiProfessionali = action.payload;
      })

      .addCase(putDatiProfessionaliAsync.pending, (state) => {
        state.status = "loading";
      })

      .addCase(putDatiProfessionaliAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.respSaveDatiProfessionali = action.payload;
      })

      .addCase(getDatiFiscaliAsync.pending, (state) => {
        state.status = "loading";
      })

      .addCase(getDatiFiscaliAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.respDatiFiscali = action.payload;
      })

      .addCase(putDatiFiscaliAsync.pending, (state) => {
        state.status = "loading";
      })

      .addCase(putDatiFiscaliAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.respSaveDatiFiscali = action.payload;
      })

      .addCase(getDatiBancariAsync.pending, (state) => {
        state.status = "loading";
      })

      .addCase(getDatiBancariAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.respDatiBancari = action.payload;
      })

      .addCase(putDatiBancariAsync.pending, (state) => {
        state.status = "loading";
      })

      .addCase(putDatiBancariAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.respSaveDatiBancari = action.payload;
      })

      .addCase(postDatiFatturazioneAsync.pending, (state) => {
        state.status = "loading";
      })

      .addCase(postDatiFatturazioneAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.respDatiFatturazione = action.payload;
      });
  },
});

export const {
  resetRespSaveDatiDisponibilita,
  resetRespSaveDatiProfilo,
  resetRespSaveDatiPersonaliGiuridici,
  resetRespSaveDatiCittadinanza,
  resetRespSaveDatiFisici,
  resetRespSaveDatiFamiliari,
  resetRespSaveDatiProfessionali,
  resetRespSaveDatiFiscali,
  resetRespSaveDatiBancari,
  resetRespSaveAll,
  resetRespProfiloUtente,
  resetRespFotoProfiloUtente,
} = profileSlice.actions;

export const selectRespProfilo = (state) => state.profile.respProfilo;
export const selectFotoProfilo = (state) => state.profile.respfotoProfilo;
export const selectRespProfiloUtente = (state) =>
  state.profile.respProfiloUtente;
export const selectRespDatiProfilo = (state) => state.profile.respDatiProfilo;
export const selectRespSaveDatiProfilo = (state) =>
  state.profile.respSaveDatiProfilo;
export const selectRespDatiDisponibilita = (state) =>
  state.profile.respDatiDisponibilita;
export const selectRespSaveDatiDisponibilita = (state) =>
  state.profile.respSaveDatiDisponibilita;
export const selectRespDatiPersonaliGiuridici = (state) =>
  state.profile.respDatiPersonaliGiuridici;
export const selectRespSaveDatiPersonaliGiuridici = (state) =>
  state.profile.respSaveDatiPersonaliGiuridici;
export const selectRespDatiCittadinanza = (state) =>
  state.profile.respDatiCittadinanza;
export const selectRespSaveDatiCittadinanza = (state) =>
  state.profile.respSaveDatiCittadinanza;
export const selectRespDatiFisici = (state) => state.profile.respDatiFisici;
export const selectRespSaveDatiFisici = (state) =>
  state.profile.respSaveDatiFisici;
export const selectRespDatiFamiliari = (state) =>
  state.profile.respDatiFamiliari;
export const selectRespSaveDatiFamiliari = (state) =>
  state.profile.respSaveDatiFamiliari;
export const selectRespDatiProfessionali = (state) =>
  state.profile.respDatiProfessionali;
export const selectRespSaveDatiProfessionali = (state) =>
  state.profile.respSaveDatiProfessionali;
export const selectRespDatiFiscali = (state) => state.profile.respDatiFiscali;
export const selectRespSaveDatiFiscali = (state) =>
  state.profile.respSaveDatiFiscali;
export const selectRespDatiBancari = (state) => state.profile.respDatiBancari;
export const selectRespSaveDatiBancari = (state) =>
  state.profile.respSaveDatiBancari;
export const selectRespDatiDocumento = (state) =>
  state.profile.respDatiDocumento;
export const selectRespDatiFotoProfilo = (state) =>
  state.profile.respDatiFotoProfilo;
export const selectRespDatiFatturazione = (state) =>
  state.profile.respDatiFatturazione;

export default profileSlice.reducer;
