import { configureStore } from '@reduxjs/toolkit';
import welcomeReducer from '../features/welcome/welcomeSlice';
import {ajaxError,ajaxOk} from '../features/welcome/welcomeSlice';
import profileReducer from '../features/profile/profileSlice';
import jobReducer from '../features/job/jobSlice';
import enlistmentReducer from '../features/enlistment/enlistmentSlice';
import favoriteReducer from '../features/favorite/favoriteSlice';
import domainReducer from '../features/domain/domainSlice';
import searchReducer from '../features/search/searchSlice';
import subscriptionReducer from '../features/subscription/subscriptionSlice';
import employmentReducer from '../features/employment/employmentSlice';
import timesheetReducer from '../features/timesheet/timesheetSlice';
import carsheetReducer from '../features/carsheet/carsheetSlice';
import {strings} from '../Constants'

import jQuery from 'jquery';

export const store = configureStore({
  reducer: {
    welcome: welcomeReducer,
    profile: profileReducer,
    job: jobReducer,
    enlistment: enlistmentReducer,
    favorite: favoriteReducer,
    subscription: subscriptionReducer,
    domain: domainReducer,
    search: searchReducer,
    employment: employmentReducer,
    timesheet: timesheetReducer,
    carsheet: carsheetReducer
  },
});



jQuery.ajaxSetup({
  beforeSend: function(xhr) {
      const json = JSON.parse(localStorage.getItem("loginData"));
      if (json){
        xhr.setRequestHeader('Authorization', 'Bearer '+json.token);
      }
      xhr.setRequestHeader('Accept-Language', strings.getLanguage()+";q=0.9" );
  },
  complete: function (xhr,status) {
    store.dispatch(ajaxOk());
  },
  error: function (xhr,status,error) {
 //   console.log("Ajax error", xhr, status, error);
 //   console.log('error2',xhr.getAllResponseHeaders(),xhr.getResponseHeader('www-authenticate'));
    store.dispatch(ajaxError());
  },
  /*dataFilter: function (data, type) {
    alert(data);
    return data;
  }*/
});
