/* eslint-disable react-hooks/exhaustive-deps */
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import DescriptionIcon from "@mui/icons-material/Description";
import EventNoteIcon from "@mui/icons-material/EventNote";
import FavoriteIcon from "@mui/icons-material/Favorite";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import GroupsIcon from "@mui/icons-material/Groups";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import HealthAndSafety from "@mui/icons-material/HealthAndSafety";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import SearchIcon from "@mui/icons-material/Search";
import WorkIcon from "@mui/icons-material/Work";
import KeyIcon from "@mui/icons-material/Key";
import MuiAlert from "@mui/material/Alert";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Snackbar from "@mui/material/Snackbar";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import banner1 from "../../banner.jpg";
import { ADS_STICKY, profileStepMap } from "../../Constants";
import NotFound from "../notFound/NotFound";
import ProfileTabs from "../profile/ProfileTabs";
import Login from "../welcome/Login";
import Register from "../welcome/Register";
import ResetPassword from "../welcome/ResetPassword";
import { logout, selectLoginData } from "../welcome/welcomeSlice";
import { DISMISS_TIME_MS, strings } from "./../../Constants";
import Subscription from "./../subscription/Subscription";
import { getUserDataAsync, selectAjaxError } from "./../welcome/welcomeSlice";
import Home from "./Home";
import Jobs from "./../job/Jobs";
import JobEdit from "./../job/JobEdit";
import Enlistments from "../enlistment/Enlistments";
import EnlistmentEdit from "./../enlistment/EnlistmentEdit";
import SearchEngine from "./../search/SearchEngine";
import SearchResultUserDetail from "./../search/SearchResultUserDetail";
import UserDetail from "./../user/UserDetail";
import Favorites from "../favorite/Favorites";
import Timesheet from "../timesheet/Timesheet";
import Carsheet from "../carsheet/Carsheet";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import PaymentMethodUpdate from "../settings/PaymentMethodUpdate";
import DeleteAccount from "../settings/DeleteAccount";
import ChangePassword from "../settings/ChangePassword";

import {
  getSubscriptionAsync,
  selectRespSubscription,
  getSubscriptionListAsync,
  selectRespSubscriptionList,
} from "../subscription/subscriptionSlice";

import { Terms } from "./../terms/Terms";
import { Privacy } from "./../privacy/Privacy";
import { ADS_ENABLED } from "./../../Constants";
import { AboutUs } from "../about/AboutUs";
import HomePage from "../welcome/HomePage";
import SuggestUs from "../suggerisci/SuggestUs";

const drawerWidth = 270;

export default function MainLayout(props) {
  const [menuOpen, setMobileOpen] = React.useState(false);
  const location = useLocation();
  const loginData = useSelector(selectLoginData);
  const ajaxNetworkError = useSelector(selectAjaxError);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [pageTitle, setPageTitle] = React.useState("");
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [subscription, setSubscription] = React.useState(null);
  const [subscriptionList, setSubscriptionList] = React.useState([]);
  const [subscriptionType, setSubscriptionType] = React.useState(-1);

  const respSubscription = useSelector(selectRespSubscription);
  const respSubscriptionList = useSelector(selectRespSubscriptionList);

  const refAd1 = React.useRef(null);
  const refAd2 = React.useRef(null);
  const refLayout = React.useRef(null);
  const refLayoutInner = React.useRef(null);

  React.useEffect(() => {
    if (respSubscriptionList?.respdata?.success) {
      setSubscription(respSubscription?.respdata?.data);
    }
  }, [respSubscription]);

  React.useEffect(() => {
    if (respSubscriptionList?.respdata?.success) {
      setSubscriptionList(respSubscriptionList?.respdata?.data);
    }
  }, [respSubscriptionList]);

  React.useEffect(() => {
    if (respSubscription?.respdata?.success) {
      if (subscriptionList.length > 0) {
        const sub = subscriptionList.find(
          (s) =>
            s.id_Abbonamento ===
            respSubscription?.respdata?.data?.id_Abbonamento
        );
        if (sub !== null && sub !== undefined) {
          //console.log(sub,respSubscription?.respdata?.data);
          setSubscription(sub);
          setSubscriptionType(sub.tipologiaAbbonamento);
          //setSubscriptionType(1);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [respSubscription, subscriptionList]);

  const stickAd = (ref) => {
    const top = ref.current.getBoundingClientRect().top;

    if (window.pageYOffset > 0) {
      //console.log(ref.current.getBoundingClientRect());
      if (top <= 0) {
        // eslint-disable-next-line no-restricted-globals
        // console.log('top2',top,window.innerHeight, window.pageYOffset);
        // const diff = window.innerHeight - window.pageYOffset;
        //console.log('diff', diff);
        ref.current.style.top = Math.abs(window.pageYOffset) + 10 + "px";
      }

      const bottom = ref.current.getBoundingClientRect().bottom;

      if (top + bottom > window.innerHeight) {
        //console.log('bottom2',top,window.innerHeight, window.pageYOffset);
        //      ref.current.style.top = '';
        const h = ref.current.firstChild.getBoundingClientRect().height;
        ref.current.style.top =
          window.pageYOffset + window.innerHeight - h - 20 + "px";
      }
    }

    const contentHeight = refLayoutInner.current.getBoundingClientRect().height;
    if (contentHeight < window.innerHeight) {
      // console.log(ref.current.getBoundingClientRect());
      ref.current.style.top = "50px"; //(window.pageYOffset - window.innerHeight + contentHeight - 10) + 'px';
    }
  };

  const stickyAds = () => {
    if (ADS_ENABLED === false || ADS_STICKY === false) return;
    if (refAd1.current) {
      stickAd(refAd1);
      //console.log(refAd1.current.getBoundingClientRect().top);
    }
    if (refAd2.current) {
      stickAd(refAd2);
      //  refAd2.current.style.top=Math.abs(refLayout.current.getBoundingClientRect().height)+'px';
    }
  };

  const handleScroll = () => {
    stickyAds();
  };

  React.useEffect(() => {
    if (localStorage.getItem("loginData") !== null) {
      dispatch(getUserDataAsync());
    }

    stickyAds();

    // eslint-disable-next-line no-restricted-globals
    addEventListener("scroll", handleScroll);

    return () => {
      // eslint-disable-next-line no-restricted-globals
      removeEventListener("scroll", () => handleScroll);
    };
  }, []);

  React.useEffect(() => {
    if (loginData?.username) {
      dispatch(getSubscriptionAsync());
      dispatch(getSubscriptionListAsync());
    }
    stickyAds();

    //console.log(loginData);
    if (!loginData?.username) {
      if (
        !(
          location.pathname === "/login" ||
          location.pathname === "/register" ||
          location.pathname === "/home-page" ||
          location.pathname === "/" ||
          location.pathname === "" ||
          location.pathname === "/forgot-password" ||
          location.pathname === "/privacy" ||
          location.pathname === "/terms" ||
          location.pathname === "/about-us" ||
          location.pathname === "/terms"
        )
      ) {
        navigate("/", { replace: true });
      }
    }

    //TODO: add page title in various cases
    switch (location.pathname) {
      case "/profile":
        setPageTitle(" - " + strings.profile);
        break;
      default:
        setPageTitle("");
    }
  }, [loginData, location, navigate]);

  React.useEffect(() => {
    document.title = "MyCrew" + pageTitle;
  }, [pageTitle]);

  const isStaticHomePage = React.useRef(false);

  React.useEffect(() => {
    isStaticHomePage.current = false;
    if (location.pathname === "/" || location.pathname === "") {
      isStaticHomePage.current = true;
    }
    if (ajaxNetworkError !== null && !isStaticHomePage.current) {
      setSnackbarOpen(true);
      setSnackbarMessage(ajaxNetworkError);
    }
  }, [ajaxNetworkError]);

  React.useEffect(() => {
    if (loginData?.username) {
      const workflowComplete =
        loginData.workflow >= 10 || loginData.workflow === 0;
      const workflowUrl = profileStepMap.get(loginData.workflow - 1);
      if (!workflowComplete && location.pathname !== workflowUrl) {
        // console.log('navigationg to workflow url',workflowUrl);
        navigate(workflowUrl, { replace: true });
      }
    }
  }, [loginData]);

  const handleProfileTabChange = (step) => {
    const url = profileStepMap.get(step);
    // console.log('changed profile tab to:',url);
    navigate(url);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!menuOpen);
  };

  function refreshPage() {
    navigate("/home", { replace: true });
    //window.location.reload(true);
  }

  const handleLogout = () => {
    //  debugger;
    //  console.log(window);
    // eslint-disable-next-line no-restricted-globals
    if (confirm(strings.areYouSure)) {
      dispatch(logout());
      refreshPage();
      setMobileOpen(false);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
  });

  const getActiveClass = (path) =>
    location?.pathname.indexOf(path) === 0 ? "active" : "";

  const drawer = (
    <div className='drawerMenu'>
      {loginData?.workflow === 0 ? (
        <List className='menuListHomeLink'>
          <ListItem
            button
            className={getActiveClass("/home")}
            key={"home"}
            onClick={handleDrawerToggle}
            component={Link}
            to={"/home"}
          >
            <ListItemText primary={"Homepage"} />
          </ListItem>
        </List>
      ) : (
        ""
      )}
      <List className='menuList'>
        <ListItem
          button
          className={getActiveClass("/profile")}
          key={"profilo"}
          onClick={handleDrawerToggle}
          component={Link}
          to={"/profile"}
        >
          <ListItemIcon>
            <AccountBoxIcon />
          </ListItemIcon>
          <ListItemText primary={strings.profile} />
        </ListItem>

        {loginData?.workflow === 0 ? (
          <>
            <ListItem
              button
              className={getActiveClass("/enlistments")}
              key={"ingaggi"}
              onClick={handleDrawerToggle}
              component={Link}
              to={"/enlistments"}
            >
              <ListItemIcon>
                <GroupAddIcon />
              </ListItemIcon>
              <ListItemText primary={strings.enlistments} />
            </ListItem>
            <ListItem
              button
              className={getActiveClass("/subscriptions")}
              key={"abbonamenti"}
              onClick={handleDrawerToggle}
              component={Link}
              to={"/subscriptions"}
            >
              <ListItemIcon>
                <MonetizationOnIcon />
              </ListItemIcon>
              <ListItemText primary={strings.subscriptions} />
            </ListItem>
          </>
        ) : (
          ""
        )}
        {subscriptionType === 2 ? (
          <>
            <ListItem
              button
              key={"schedeauto"}
              className={getActiveClass("/carsheets")}
              onClick={handleDrawerToggle}
              component={Link}
              to={"/carsheets"}
            >
              <ListItemIcon>
                <DirectionsCarIcon />
              </ListItemIcon>
              <ListItemText primary={strings.carSheets} />
            </ListItem>
            <ListItem
              button
              key={"timesheet"}
              className={getActiveClass("/timesheet")}
              onClick={handleDrawerToggle}
              component={Link}
              to={"/timesheets"}
            >
              <ListItemIcon>
                <EventNoteIcon />
              </ListItemIcon>
              <ListItemText primary={strings.timeReports} />
            </ListItem>
            <ListItem
              button
              key={"motorericerca"}
              className={getActiveClass("/search")}
              onClick={handleDrawerToggle}
              component={Link}
              to={"/search"}
            >
              <ListItemIcon>
                <SearchIcon />
              </ListItemIcon>
              <ListItemText primary={strings.searchEngine} />
            </ListItem>
            <ListItem
              button
              key={"preferiti"}
              className={getActiveClass("/favorites")}
              onClick={handleDrawerToggle}
              component={Link}
              to={"/favorites"}
            >
              <ListItemIcon>
                <FavoriteIcon />
              </ListItemIcon>
              <ListItemText primary={strings.favorites} />
            </ListItem>
            <ListItem
              button
              key={"gestionejob"}
              className={getActiveClass("/jobs")}
              onClick={handleDrawerToggle}
              component={Link}
              to={"/jobs"}
            >
              <ListItemIcon>
                <WorkIcon />
              </ListItemIcon>
              <ListItemText primary={strings.jobManagment} />
            </ListItem>
          </>
        ) : (
          <></>
        )}
        <ListItem
          button
          key={"suggerisci"}
          className={getActiveClass("/suggest-us")}
          onClick={handleDrawerToggle}
          component={Link}
          to={"/suggest-us"}
        >
          <ListItemIcon>
            <RecordVoiceOverIcon />
          </ListItemIcon>
          <ListItemText primary={strings.suggestUs} />
        </ListItem>
        <ListItem
          button
          key={"about"}
          className={getActiveClass("/about-us")}
          onClick={handleDrawerToggle}
          component={Link}
          to={"/about-us"}
        >
          <ListItemIcon>
            <GroupsIcon />
          </ListItemIcon>
          <ListItemText primary={strings.aboutUs} />
        </ListItem>
        <ListItem
          button
          key={"privacy"}
          className={getActiveClass("/privacy")}
          onClick={handleDrawerToggle}
          component={Link}
          to={"/privacy"}
        >
          <ListItemIcon>
            <HealthAndSafety />
          </ListItemIcon>
          <ListItemText primary={strings.privacy} />
        </ListItem>

        <ListItem
          button
          key={"terms"}
          className={getActiveClass("/terms")}
          onClick={handleDrawerToggle}
          component={Link}
          to={"/terms"}
        >
          <ListItemIcon>
            <DescriptionIcon />
          </ListItemIcon>
          <ListItemText primary={strings.termAndCondsTitle} />
        </ListItem>
      </List>
      <Divider />
      <h3 className='menuListTitle'>{strings.settings}</h3>
      <List className='menuListSettings'>
        <ListItem
          button
          key={"changepassword"}
          className={getActiveClass("/changepassword")}
          onClick={handleDrawerToggle}
          component={Link}
          to={"/changepassword"}
        >
          <ListItemIcon>
            <KeyIcon />
          </ListItemIcon>
          <ListItemText primary={strings.changepassword} />
        </ListItem>

        <ListItem
          button
          key={"changepayment"}
          className={getActiveClass("/changepayment")}
          onClick={handleDrawerToggle}
          component={Link}
          to={"/changepayment"}
        >
          <ListItemIcon>
            <CreditCardIcon />
          </ListItemIcon>
          <ListItemText primary={strings.changepayment} />
        </ListItem>

        <ListItem
          button
          key={"deleteaccount"}
          className={getActiveClass("/deleteaccount")}
          onClick={handleDrawerToggle}
          component={Link}
          to={"/deleteaccount"}
        >
          <ListItemIcon>
            <PersonRemoveIcon />
          </ListItemIcon>
          <ListItemText primary={strings.deleteaccount} />
        </ListItem>
      </List>
      <Divider />
      <List className='menuListLogout'>
        <ListItem button key={"logout"} onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary={"LogOut"} />
        </ListItem>
      </List>
    </div>
  );

  const container = document.querySelector("#layoutCountainer");
  // const VIDEO_WIDTH = 1920;
  // const VIDEO_HEIGHT = 1080;
  return (
    <>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={DISMISS_TIME_MS}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity='error'
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      {location.pathname === "/" && (
        <Routes>
          <Route index element={<HomePage />} />
          <Route exact path='/' element={<HomePage />} />
        </Routes>
      )}
      {location.pathname !== "" && location.pathname !== "/" && (
        <div
          className='pushZoneContainer mainApp'
          style={{ paddingLeft: menuOpen ? drawerWidth : 0 }}
        >
          <div ref={refLayout} id='layoutContainer'>
            {ADS_ENABLED ? (
              <>
                <aside ref={refAd1} className='ad adLeft'>
                  <img alt='ads' src={banner1} />
                </aside>

                <aside ref={refAd2} className='ad adRight'>
                  <img alt='ads' src={banner1} />
                </aside>
              </>
            ) : (
              <></>
            )}

            <div ref={refLayoutInner} id='layoutInner'>
              <Box sx={{ display: "flex" }}>
                <Box
                  component='main'
                  sx={{
                    flexGrow: 1,
                    p: 0,
                    width: { sm: `calc(100%)` },
                  }}
                  className='mainSection'
                >
                  <AppBar position='static'>
                    <Toolbar variant='dense'>
                      {!menuOpen && loginData?.username ? (
                        <IconButton
                          color='inherit'
                          aria-label='open menu'
                          edge='start'
                          onClick={handleDrawerToggle}
                          sx={{ mr: 2 }}
                        >
                          <MenuIcon />
                        </IconButton>
                      ) : (
                        ""
                      )}
                    </Toolbar>
                  </AppBar>

                  <Drawer
                    container={container}
                    variant='temporary'
                    open={menuOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                      keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                      display: { xs: "block", sm: "block" },
                      "& .MuiDrawer-paper": {
                        boxSizing: "border-box",
                        width: drawerWidth,
                      },
                    }}
                  >
                    {drawer}
                  </Drawer>

                  <Routes>
                    <Route
                      exact
                      path='/'
                      element={<Home subscriptionType={subscriptionType} />}
                    />
                    <Route
                      exact
                      path='/app'
                      element={<Home subscriptionType={subscriptionType} />}
                    />
                    <Route
                      exact
                      path='/home'
                      element={<Home subscriptionType={subscriptionType} />}
                    />
                    <Route exact path='/privacy-policy' element={<Privacy />} />
                    <Route exact path='/terms' element={<Terms />} />
                    <Route exact path='/terms-of-service' element={<Terms />} />
                    <Route exact path='/about-us' element={<AboutUs />} />
                    <Route exact path='/login' element={<Login />} />
                    <Route exact path='/register' element={<Register />} />
                    <Route
                      exact
                      path='/enlistments'
                      element={<Enlistments />}
                    />
                    <Route exact path='/favorites' element={<Favorites />} />
                    <Route exact path='/suggest-us' element={<SuggestUs />} />
                    <Route
                      path='/enlistments/:id/:status'
                      element={<EnlistmentEdit />}
                    />
                    <Route path='*' element={<NotFound />} />
                    <Route
                      exact
                      path='/profile'
                      element={
                        <ProfileTabs
                          onTabChange={handleProfileTabChange}
                          index={0}
                        />
                      }
                    />
                    <Route
                      exact
                      path={profileStepMap.get(0)}
                      element={
                        <ProfileTabs
                          onTabChange={handleProfileTabChange}
                          index={0}
                        />
                      }
                    />
                    <Route
                      exact
                      path={profileStepMap.get(1)}
                      element={
                        <ProfileTabs
                          onTabChange={handleProfileTabChange}
                          index={1}
                        />
                      }
                    />
                    <Route
                      exact
                      path={profileStepMap.get(2)}
                      element={
                        <ProfileTabs
                          onTabChange={handleProfileTabChange}
                          index={2}
                        />
                      }
                    />
                    <Route
                      exact
                      path={profileStepMap.get(3)}
                      element={
                        <ProfileTabs
                          onTabChange={handleProfileTabChange}
                          index={3}
                        />
                      }
                    />
                    <Route
                      exact
                      path={profileStepMap.get(4)}
                      element={
                        <ProfileTabs
                          onTabChange={handleProfileTabChange}
                          index={4}
                        />
                      }
                    />
                    <Route
                      exact
                      path={profileStepMap.get(5)}
                      element={
                        <ProfileTabs
                          onTabChange={handleProfileTabChange}
                          index={5}
                        />
                      }
                    />
                    <Route
                      exact
                      path={profileStepMap.get(6)}
                      element={
                        <ProfileTabs
                          onTabChange={handleProfileTabChange}
                          index={6}
                        />
                      }
                    />
                    <Route
                      exact
                      path={profileStepMap.get(7)}
                      element={
                        <ProfileTabs
                          onTabChange={handleProfileTabChange}
                          index={7}
                        />
                      }
                    />
                    <Route
                      exact
                      path={profileStepMap.get(8)}
                      element={
                        <ProfileTabs
                          onTabChange={handleProfileTabChange}
                          index={8}
                        />
                      }
                    />
                    <Route
                      exact
                      path='/subscriptions'
                      element={<Subscription />}
                    />
                    <Route exact path='/jobs' element={<Jobs />} />
                    <Route path='/jobs/:id' element={<JobEdit />} />
                    <Route exact path='/search' element={<SearchEngine />} />
                    <Route
                      path='/search/result/user/:id'
                      element={<SearchResultUserDetail />}
                    />
                    <Route path='/user/:id' element={<UserDetail />} />
                    <Route
                      exact
                      path='/forgot-password'
                      element={<ResetPassword />}
                    />
                    <Route path='/privacy' element={<Privacy />} />
                    <Route path='/timesheets' element={<Timesheet />} />
                    <Route path='/carsheets' element={<Carsheet />} />
                    <Route
                      path='/changepayment'
                      element={<PaymentMethodUpdate />}
                    />
                    <Route path='/deleteaccount' element={<DeleteAccount />} />
                    <Route
                      path='/changepassword'
                      element={<ChangePassword />}
                    />
                  </Routes>
                </Box>
              </Box>
            </div>
            <div
              style={{
                width: "100%",
                textAlign: "center",
                padding: "20px",
                fontSize: "80%",
              }}
              className='languageSelector'
            >
              <span
                className={
                  strings.getLanguage() === "en" ? "activeLanguage" : "language"
                }
                onClick={() => {
                  localStorage.setItem("activeLanguage", "en");
                  strings.setLanguage("en");
                  window.location.reload(true);
                }}
              >
                English
              </span>
              -{" "}
              <span
                onClick={() => {
                  localStorage.setItem("activeLanguage", "it");
                  strings.setLanguage("it");
                  window.location.reload(true);
                }}
                className={
                  strings.getLanguage() === "it" ? "activeLanguage" : "language"
                }
              >
                Italian
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
