/* eslint-disable react-hooks/exhaustive-deps */
import SearchIcon from "@mui/icons-material/Search";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { cloneDeep } from "lodash";
import Moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  COMMON_SX_LARGE,
  FORM_INTERNAL_SX,
  strings,
  toSQLDate,
} from "../../Constants";
import { selectLoginData } from "../welcome/welcomeSlice";
import { COMMON_SX, dpLocale } from "./../../Constants";
import ResponseAlertMessages from "./../commons/ResponseAlertMessages";
import {
  getDomainNazioniAsync,
  getDomainRuoliAsync,
  selectRespDomainNazioni,
  selectRespDomainRuoli,
} from "./../domain/domainSlice";
import SearchResult from "./SearchResult";
import {
  doSearchAsync,
  resetRespSearch,
  selectRespSearch,
} from "./searchSlice";

//https://stackoverflow.com/questions/39419237/what-is-mapdispatchtoprops
//https://newbedev.com/react-hook-useeffect-has-a-missing-dependency-dispatch
export default function SearchEngine() {
  const dispatch = useDispatch();
  //const stableDispatch = useCallback(dispatch, []) //assuming that it doesn't need to change

  const loggedUser = useSelector(selectLoginData);

  const [disabled, setDisabled] = useState(false);

  const resp = useSelector(selectRespSearch);
  const [results, setResults] = useState([]);

  const [nazionerif1, setNazioneRif1] = useState(0);
  //useEffect(() => { localStorage.setItem('search_nazionerif1', nazionerif1); }, [nazionerif1]);
  const [regionerif1, setRegioneRif1] = useState(0);
  //useEffect(() => { localStorage.setItem('search_regionerif1', regionerif1); }, [regionerif1]);

  const [listaNazioni, setListaNazioni] = useState([]);
  const dominioNazioniResp = useSelector(selectRespDomainNazioni);

  const dominioRuoliResp = useSelector(selectRespDomainRuoli);

  const [dataInizio, setDataInizio] = useState(null);
  useEffect(() => {
    if (dataInizio) {
      localStorage.setItem("search_dataInizio", dataInizio);
    }
  }, [dataInizio]);

  const [dataFine, setDataFine] = useState(null);
  useEffect(() => {
    if (dataFine) {
      localStorage.setItem("search_dataFine", dataFine);
    }
  }, [dataFine]);

  const [nome, setNome] = useState("");
  /*   useEffect(() => {
    nome
      ? localStorage.setItem("search_nome", nome)
      : localStorage.removeItem("search_nome");
  }, [nome]); */
  const [cognome, setCognome] = useState("");
  /*   useEffect(() => {
    cognome
      ? localStorage.setItem("search_cognome", cognome)
      : localStorage.removeItem("search_cognome");
  }, [cognome]); */

  const [jobs, setJobs] = useState(
    localStorage.getItem("search_jobs")
      ? JSON.parse(localStorage.getItem("search_jobs"))
      : []
  );
  useEffect(() => {
    localStorage.setItem("search_jobs", JSON.stringify(jobs));
  }, [jobs]);

  const [listaRuoli, setListaRuoli] = useState([]);
  /*   useEffect(() => {
    localStorage.setItem("search_listaRuoli", JSON.stringify(listaRuoli));
  }, [listaRuoli]); */
  const [listaDipartimentiDomain, setListaDipartimentiDomain] = useState([]);
  const [listaRuoliDomain, setListaRuoliDomain] = useState([]);

  useEffect(() => {
    let italy = dominioNazioniResp?.respdata?.data?.find(
      (entry) => entry.nome_Nazione === "Italia"
    );
    if (italy) {
      console.log("Verifying Italy as default nation");
      setNazioneRif1(nazionerif1 === -1 ? italy.id_Nazione : nazionerif1);
    }
  }, [dominioNazioniResp]);

  useEffect(() => {
    if (listaRuoli.length === 0) {
      addRole();
    }
  }, []);

  useEffect(() => {
    if (jobs[0]?.dataInizio) {
      if (Moment(jobs[0]?.dataInizio).isBefore(Moment(new Date()))) {
        setDataInizio(new Date());
      } else {
        setDataInizio(jobs[0]?.dataInizio);
      }
    }
    if (jobs[0]?.dataFine) {
      setDataFine(jobs[0]?.dataFine);
    }
    if (jobs.length === 0 || jobs[0] === null) {
      setDataFine(null);
      setDataInizio(null);
    }

    if (jobs[0]?.listaNazioni?.length > 0) {
      //  console.log(jobs[0]?.listaNazioni[0]);
      setNazioneRif1(jobs[0].listaNazioni[0].id_Nazione);
      setRegioneRif1(jobs[0].listaNazioni[0].id_Regione);
    } else {
      //reset em only after the first call
      // setNazioneRif1(localStorage.getItem('search_nazionerif1')?parseInt(localStorage.getItem('search_nazionerif1')):-1);
      // setRegioneRif1(localStorage.getItem('search_regionerif1')?parseInt(localStorage.getItem('search_regionerif1')):0);
    }
  }, [jobs]);

  useEffect(() => {
    //console.log(loggedUser);
    if (loggedUser) {
      dispatch(getDomainRuoliAsync());
      dispatch(getDomainNazioniAsync());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUser]);

  useEffect(() => {
    //console.log(loggedUser);
    if (dominioRuoliResp?.respdata?.success) {
      checkAndFillDomainRuoliData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dominioRuoliResp]);

  useEffect(() => {
    //console.log(loggedUser);
    if (resp?.respdata?.success) {
      setResults(resp.respdata.data.listaUtenti);
    }
    if (resp === null) {
      setResults([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resp]);

  const checkAndFillDomainRuoliData = (listaRuoliOptional) => {
    //console.log('Checking domainRuoli');
    let listaRuoli2 = cloneDeep(listaRuoliOptional);
    if (!listaRuoliOptional) {
      listaRuoli2 = cloneDeep(listaRuoli);
    }
    const listaDipartimentiDomain2 = [];
    const listaRuoliDomain2 = [];
    //setListaRuoli(profileResponse?.respdata?.data?.listaRuoli);
    listaRuoli2.forEach((role, idxRole) => {
      const listaDipartimenti = dominioRuoliResp?.respdata?.data?.find(
        (a) => a.id_Ambito === role.id_Ambito
      )?.listaDipartimenti;
      if (listaDipartimenti) {
        listaDipartimentiDomain2.push(listaDipartimenti);
        const listaRuoli = listaDipartimenti?.find(
          (a) => a.id_Dipartimento === role.id_Dipartimento
        )?.listaRuoli;
        listaRuoliDomain2.push(listaRuoli);
      }
    });
    setListaDipartimentiDomain(listaDipartimentiDomain2);
    setListaRuoliDomain(listaRuoliDomain2);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const listaNazioni = [];

    listaNazioni.push({ id_Nazione: nazionerif1, id_Regione: regionerif1 });
    /* if (nazionerif2 !== -1) {
       listaNazioni.push({ id_Nazione: nazionerif2, id_Regione: regionerif2 });
     }
     if (nazionerif3 !== -1) {
       listaNazioni.push({ id_Nazione: nazionerif3, id_Regione: regionerif3 });
     }      //*/
    dispatch(
      doSearchAsync({
        nome,
        cognome,
        id_Nazione: listaNazioni[0].id_Nazione,
        id_Regione: listaNazioni[0].id_Regione,
        dataInizio: toSQLDate(dataInizio),
        dataFine: toSQLDate(dataFine),
        id_Ruolo: listaRuoli[0].id_Ruolo,
        id_Dipartimento: listaRuoli[0].id_Dipartimento,
        id_Ambito: listaRuoli[0].id_Ambito,
        paginaCorrente: 0,
        elementiPerPagina: 1000, // Number.MAX_SAFE_INTEGER
      })
    );
  };

  const goBack = (e) => {
    e.preventDefault();
    dispatch(resetRespSearch());
  };

  const addRole = () => {
    const listaRuoli2 = cloneDeep(listaRuoli);
    listaRuoli2.push({
      id_RuoloProfilo: 0,
      id_Ruolo: 0,
      id_Dipartimento: 0,
      id_Ambito: 0,
    });
    checkAndFillDomainRuoliData();
    setListaRuoli(listaRuoli2);
  };

  const modifyRoleAmbito = (id_RuoloProfilo, idxOrig, value) => {
    const listaRuoli2 = cloneDeep(listaRuoli);
    let idx = idxOrig;
    if (id_RuoloProfilo > 0) {
      idx = listaRuoli2.findIndex((o) => o.id_RuoloProfilo === id_RuoloProfilo);
    }
    listaRuoli2[idx].id_Ambito = value;
    setListaRuoli(listaRuoli2);
    checkAndFillDomainRuoliData(listaRuoli2);
  };

  const modifyRoleDipartimento = (id_RuoloProfilo, idxOrig, value) => {
    // console.log(id_RuoloProfilo, idxOrig, value);
    const listaRuoli2 = cloneDeep(listaRuoli);
    let idx = idxOrig;
    if (id_RuoloProfilo > 0) {
      idx = listaRuoli2.findIndex((o) => o.id_RuoloProfilo === id_RuoloProfilo);
    }
    listaRuoli2[idx].id_Dipartimento = value;
    // console.log(listaRuoli2);
    checkAndFillDomainRuoliData(listaRuoli2);
    setListaRuoli(listaRuoli2);
  };

  const modifyRoleRuolo = (id_RuoloProfilo, idxOrig, value) => {
    const listaRuoli2 = cloneDeep(listaRuoli);
    let idx = idxOrig;
    if (id_RuoloProfilo > 0) {
      idx = listaRuoli2.findIndex((o) => o.id_RuoloProfilo === id_RuoloProfilo);
    }
    listaRuoli2[idx].id_Ruolo = value;
    setListaRuoli(listaRuoli2);
  };

  const currentDate = new Date();

  // Controllo per dataInizio
  const isDataInizioOggi =
    dataInizio && dataInizio.toDateString() === currentDate.toDateString();

  // Controllo per dataFine
  const isDataFineOggi =
    dataFine && dataFine.toDateString() === currentDate.toDateString();

  const disabledButton =
    dataFine === null ||
    dataInizio === null ||
    isDataInizioOggi ||
    isDataFineOggi
      ? true
      : false;
  useEffect(() => {
    console.log(disabledButton, dataInizio, dataFine);
  }, [disabledButton]);

  const translateSelectNation = (value) => {
    if (strings.getLanguage() === "en") {
      if (value === "Cipro") {
        return "Cyprus";
      }
      if (value === "Belgio") {
        return "Belgium";
      }
      if (value === "Croazia") {
        return "Croatia";
      }
      if (value === "Danimarca") {
        return "Denmark";
      }
      if (value === "Finlandia") {
        return "Finland";
      }
      if (value === "Francia") {
        return "France";
      }
      if (value === "Germania") {
        return "Germany";
      }
      if (value === "Grecia") {
        return "Greece";
      }
      if (value === "Irlanda") {
        return "Ireland";
      }
      if (value === "Italia") {
        return "Italy";
      }
      if (value === "Lettonia") {
        return "Latvia";
      }
      if (value === "Lituania") {
        return "Lithuania";
      }
      if (value === "Lussemburgo") {
        return "Luxembourg";
      }
      if (value === "Paesi Bassi") {
        return "Netherlands";
      }
      if (value === "Polonia") {
        return "Poland";
      }
      if (value === "Portogallo") {
        return "Portugal";
      }
      if (value === "Repubblica Ceca") {
        return "Czech Republic";
      }
      if (value === "Slovacchia") {
        return "Slovakia";
      }
      if (value === "Spagna") {
        return "Spain";
      }
      if (value === "Svezia") {
        return "Sweden";
      }
      if (value === "Ungheria") {
        return "Hungary";
      }
    }
    return value;
  };
  return (
    <>
      {results.length === 0 ? (
        <form component="form" sx={FORM_INTERNAL_SX} onSubmit={onSubmit}>
          <Stack
            className="muiStackFix"
            spacing={2.5}
            direction="column"
            sx={COMMON_SX}
          >
            <>
              <h2 style={{ textAlign: "center" }}>{strings.searchEngine}</h2>
              <br />
              {dominioNazioniResp?.respdata?.data ? (
                <Autocomplete
                  disabled={disabled}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={option.nome}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  value={
                    nazionerif1 !== -1
                      ? dominioNazioniResp?.respdata?.data.find(
                          (a) => a.id_Nazione === nazionerif1
                        )
                      : { id_Nazione: nazionerif1, nome_Nazione: "" }
                  }
                  isOptionEqualToValue={(opt, value) =>
                    value.id_Nazione === opt.id_Nazione
                  }
                  options={dominioNazioniResp?.respdata?.data}
                  getOptionLabel={(option) => translateSelectNation(option.nome_Nazione) }
                  onChange={(event, newValue) => {
                    newValue
                      ? setNazioneRif1(newValue?.id_Nazione)
                      : setNazioneRif1(-1);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label={strings.nazRif}
                      placeholder={strings.selectANation}
                    />
                  )}
                ></Autocomplete>
              ) : (
                ""
              )}

              {dominioNazioniResp?.respdata?.data?.find(
                (o) => o.id_Nazione === nazionerif1
              )?.regioni.length > 0 ? (
                <Autocomplete
                  disabled={disabled}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={option.nome}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  value={
                    regionerif1 !== 0
                      ? dominioNazioniResp?.respdata?.data
                          ?.find((o) => o.id_Nazione === nazionerif1)
                          .regioni.find((r) => r.id_Regione === regionerif1)
                      : { id_Regione: 0, nome_Regione: "" }
                  }
                  isOptionEqualToValue={(opt, value) =>
                    value.id_Regione === opt.id_Regione
                  }
                  options={
                    dominioNazioniResp?.respdata?.data?.find(
                      (o) => o.id_Nazione === nazionerif1
                    ).regioni
                  }
                  getOptionLabel={(option) => option.nome_Regione}
                  onChange={(event, newValue) => {
                    if (newValue) setRegioneRif1(newValue?.id_Regione);
                    else setRegioneRif1(0);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label={strings.region}
                      placeholder={strings.selectARegion}
                    />
                  )}
                ></Autocomplete>
              ) : (
                ""
              )}

              <h5 style={{ marginTop: "35px" }}>{strings.availability}</h5>
              <LocalizationProvider
                locale={dpLocale}
                dateAdapter={AdapterDateFns}
              >
                <Stack className="StackRow" direction="row" spacing={2}>
                  <DatePicker
                    disabled={disabled}
                    value={dataInizio}
                    label={strings.startDate + "*"}
                    minDate={new Date()}
                    maxDate={dataFine ? dataFine : null}
                    inputFormat={
                      strings.getLanguage() === "it"
                        ? "dd/MM/yyyy"
                        : "MM/dd/yyyy"
                    }
                    onChange={(val) => setDataInizio(val)}
                    renderInput={(params) => (
                      <TextField variant="standard" {...params} fullWidth />
                    )}
                  />

                  <DatePicker
                    disabled={disabled}
                    value={dataFine}
                    label={strings.endDate + "*"}
                    minDate={dataInizio ? dataInizio : new Date()}
                    inputFormat={
                      strings.getLanguage() === "it"
                        ? "dd/MM/yyyy"
                        : "MM/dd/yyyy"
                    }
                    onChange={(val) => setDataFine(val)}
                    renderInput={(params) => (
                      <TextField variant="standard" {...params} fullWidth />
                    )}
                  />
                </Stack>
              </LocalizationProvider>
              {isDataInizioOggi || isDataFineOggi ? (
                <p style={{ color: "#ef5350" }}>{strings.dateDay}</p>
              ) : (
                <></>
              )}

              {dominioRuoliResp?.respdata?.data?.length &&
                listaRuoli.length > 0 &&
                listaRuoli.map((role, idxRole) => (
                  <>
                    <br />
                    <Autocomplete
                      disabled={disabled}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            variant="outlined"
                            label={option.nome}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      value={dominioRuoliResp?.respdata?.data.find(
                        (a) => a.id_Ambito === role?.id_Ambito
                      )}
                      isOptionEqualToValue={(opt, value) =>
                        value.id_Ambito === opt.id_Ambito
                      }
                      options={dominioRuoliResp?.respdata?.data}
                      getOptionLabel={(option) => option.testo}
                      onChange={(event, newValue) => {
                        //console.log(newValue);
                        // setJobs(newValue);
                        if (newValue) {
                          modifyRoleAmbito(
                            role.id_RuoloProfilo,
                            idxRole,
                            newValue.id_Ambito
                          );
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label={strings.scope}
                          placeholder={strings.selectAScope}
                        />
                      )}
                    ></Autocomplete>

                    {listaDipartimentiDomain[idxRole]?.length > 0 ? (
                      <Autocomplete
                        disabled={disabled}
                        value={listaDipartimentiDomain[idxRole].find(
                          (a) => a.id_Dipartimento === role.id_Dipartimento
                        )}
                        isOptionEqualToValue={(opt, value) =>
                          value.id_Dipartimento === opt.id_Dipartimento
                        }
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip
                              variant="outlined"
                              label={option.nome}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        options={listaDipartimentiDomain[idxRole]}
                        getOptionLabel={(option) => option.testo}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            modifyRoleDipartimento(
                              role.id_RuoloProfilo,
                              idxRole,
                              newValue.id_Dipartimento
                            );
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            label={strings.department}
                            placeholder={strings.selectADepartment}
                          />
                        )}
                      ></Autocomplete>
                    ) : (
                      ""
                    )}

                    {listaRuoliDomain[idxRole]?.length > 0 ? (
                      <Autocomplete
                        disabled={disabled}
                        value={listaRuoliDomain[idxRole].find(
                          (a) => a.id_Ruolo === role.id_Ruolo
                        )}
                        isOptionEqualToValue={(opt, value) =>
                          value.id_Ruolo === opt.id_Ruolo
                        }
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip
                              variant="outlined"
                              label={option.nome}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        options={listaRuoliDomain[idxRole]}
                        getOptionLabel={(option) => option.testo}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            modifyRoleRuolo(
                              role.id_RuoloProfilo,
                              idxRole,
                              newValue.id_Ruolo
                            );
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            label={strings.role}
                            placeholder={strings.selectARole}
                          />
                        )}
                      ></Autocomplete>
                    ) : (
                      ""
                    )}
                  </>
                ))}

              <Stack className="StackRow" direction="row" spacing={2}>
                <TextField
                  style={{ width: "50%" }}
                  disabled={disabled}
                  label={strings.name}
                  variant="standard"
                  value={nome}
                  defaultValue={nome}
                  onChange={(event) => setNome(event.target.value)}
                />
                <TextField
                  style={{ width: "50%" }}
                  disabled={disabled}
                  label={strings.surname}
                  variant="standard"
                  value={cognome}
                  defaultValue={cognome}
                  onChange={(event) => setCognome(event.target.value)}
                />
              </Stack>
              <br />
              <Button
                type="submit"
                disabled={disabledButton}
                variant="contained"
              >
                {strings.search}
              </Button>
              <br />
              <ResponseAlertMessages response={resp}></ResponseAlertMessages>
            </>
          </Stack>
        </form>
      ) : (
        <form component="form" sx={FORM_INTERNAL_SX}>
          <Stack
            className="muiStackFix"
            spacing={2.5}
            direction="column"
            sx={COMMON_SX_LARGE}
          >
            <>
              <ResponseAlertMessages response={resp}></ResponseAlertMessages>

              {
                <Grid
                  container
                  rowSpacing={2}
                  columnSpacing={{ xs: 1, sm: 2, md: 2 }}
                >
                  {results?.map((o, i) => {
                    return (
                      <Grid item xs={12} sm={12} md={6}>
                        <SearchResult result={o} i={i + 1}></SearchResult>
                      </Grid>
                    );
                  })}
                </Grid>
              }

              <br />
              <div style={{ textAlign: "center" }}>
                <Button
                  sx={{
                    width: { sd: "26ch", md: "26ch" },
                  }}
                  type="button"
                  onClick={goBack}
                  disabled={disabled}
                  variant="contained"
                >
                  {strings.back}
                </Button>
              </div>

              <br />
            </>
          </Stack>
        </form>
      )}
      <br />
      <br />
    </>
  );
}
