import React from "react";
import { useSelector, useDispatch } from "react-redux";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Person from "@mui/icons-material/Person";
import { Link } from "react-router-dom";

import {
  deleteFavoriteAsync,
  selectRespDeleteFavorite,
  getCategoriesAsync,
} from "./favoriteSlice";
import Loader from "../commons/Loader";

export default function Favorite(props) {
  const respDeleteFavorite = useSelector(selectRespDeleteFavorite);

  const dispatch = useDispatch();

  const handleLocalDeleteFavorite = () => {
    props.handleDeleteFavorite(props.favorite.userId, props.category);
  };
  return (
    <>
      <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
        <List>
          <ListItem disablePadding>
            <ListItemButton
              component={Link}
              to={"/user/" + props.favorite.userId}
            >
              <ListItemIcon>
                <Person />
              </ListItemIcon>
              <ListItemText
                primary={props.favorite.nome + " " + props.favorite.cognome}
              />
            </ListItemButton>
            {props.canDelete ? (
              <Button onClick={handleLocalDeleteFavorite}>
                Rimuovi dai preferiti
              </Button>
            ) : (
              ""
            )}
          </ListItem>
        </List>
      </Box>
    </>
  );
}
