import * as Yup from "yup";

// PERSONA FISICA

export const validationSchemaPersonaFisicaRequired = Yup.object().shape({
  nome: Yup.string().required("Il nome è obbligatorio"),
  cognome: Yup.string().required("Il cognome è obbligatorio"),
  codfisciva: Yup.string().matches(
    /^([A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}|[0-9]{11})$/,
    "Codice fiscale o partita IVA non valido"
  ),
  pec: Yup.string().required("SDI o PEC obbligatoria!"),
  sdi: Yup.string().required("SDI o PEC obbligatoria!"),
  indirizzoResidenza: Yup.string().required("L'indirizzo è obbligatorio"),
  cittaResidenza: Yup.string().required("La città è obbligatoria"),
  provinciaResidenza: Yup.string().required("La provincia è obbligatoria"),
  nazioneResidenza: Yup.string().required("La nazione è obbligatoria"),
});

export const validationSchemaPersonaFisicaNotRequired = Yup.object().shape({
  nome: Yup.string().required("Il nome è obbligatorio"),
  cognome: Yup.string().required("Il cognome è obbligatorio"),
  codfisciva: Yup.string().matches(
    /^([A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}|[0-9]{11})$/,
    "Codice fiscale o partita IVA non valido"
  ),
  pec: Yup.string(),
  sdi: Yup.string(),
  indirizzoResidenza: Yup.string().required("L'indirizzo è obbligatorio"),
  cittaResidenza: Yup.string().required("La città è obbligatoria"),
  provinciaResidenza: Yup.string().required("La provincia è obbligatoria"),
  nazioneResidenza: Yup.string().required("La nazione è obbligatoria"),
});

// PERSONA GIURIDICA

export const validationSchemaPersonaGiuridicaRequired = Yup.object().shape({
  nome: Yup.string(),
  cognome: Yup.string(),
  ragioneSociale: Yup.string().required("Ragione Sociale obbligatoria!"),
  codfisciva: Yup.string().matches(
    /^([A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}|[0-9]{11})$/,
    "Codice fiscale o partita IVA non valido"
  ),
  pec: Yup.string().required("SDI o PEC obbligatoria!"),
  sdi: Yup.string().required("SDI o PEC obbligatoria!"),
  indirizzoResidenza: Yup.string().required("L'indirizzo è obbligatorio"),
  cittaResidenza: Yup.string().required("La città è obbligatoria"),
  provinciaResidenza: Yup.string().required("La provincia è obbligatoria"),
  nazioneResidenza: Yup.string().required("La nazione è obbligatoria"),
});

export const validationSchemaPersonaGiuridicaNotRequired = Yup.object().shape({
  nome: Yup.string(),
  cognome: Yup.string(),
  ragioneSociale: Yup.string().required("Ragione Sociale obbligatoria!"),
  codfisciva: Yup.string().matches(
    /^([A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}|[0-9]{11})$/,
    "Codice fiscale o partita IVA non valido"
  ),
  pec: Yup.string(),
  sdi: Yup.string(),
  indirizzoResidenza: Yup.string().required("L'indirizzo è obbligatorio"),
  cittaResidenza: Yup.string().required("La città è obbligatoria"),
  provinciaResidenza: Yup.string().required("La provincia è obbligatoria"),
  nazioneResidenza: Yup.string().required("La nazione è obbligatoria"),
});
