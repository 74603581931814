import React from 'react';
import { useState, useEffect } from 'react';
import  Button  from '@mui/material/Button';
import defaultAvatar from '../../default-avatar.png';
import './AvatarUploader.scss';


const AvatarUploader = props => {
  const hiddenFileInput = React.useRef(null);
  const [size, setSize] = useState(0);
  const [fileB64, setFileB64] = useState("");
  const [fileInfo, setFileInfo] = useState(null);
  const [skipFoto, setSkipFoto] = useState(false);

  
  useEffect(() => {
    if (props.b64){
      setFileInfo({
        type: "image/jpeg"
      });
      setFileB64(props.b64);
    }
  },[props]);

  const handleClick = event => {
    hiddenFileInput.current.click();
  };
  const handleChange = event => {
    const fileUploaded = event.target.files[0];
    if (props.handleRawFiles){
      props.handleRawFiles(Array.from(event.target.files));
    }
    //console.log(fileUploaded);
    setFileInfo(fileUploaded);
    setSkipFoto(true);
    try {
    setSize(fileUploaded.size/1024);
    if (fileUploaded) {
        const reader = new FileReader();
        reader.onload = _handleReaderLoaded;
        reader.readAsBinaryString(fileUploaded);
      }
    }catch(EmptyFIle){

    }
  };

  const _handleReaderLoaded = (readerEvt) => {
    let binaryString = readerEvt.target.result;
    setFileB64(btoa(binaryString));
    const fullDataStr = 'data:'+fileInfo?.type+';base64,'+fileB64;
    if (props.handleFile) {
    props.handleFile(fullDataStr, size);
    }
  }


  return (
    <>
    <div  className="avatarUploader">
      <Button onClick={handleClick}>
        {
            !skipFoto && props.foto ?
            <img alt='avatar' src={props.foto}></img>
            :
        typeof fileInfo?.type !== 'undefined'?
        <img alt='avatar' src={'data:'+fileInfo?.type+';base64,'+fileB64}></img>
        :  
        <>
             { typeof props.avatarUrl !== 'undefined' ?
             
<img alt='avatar' src={props.avatarUrl}></img>
:
<img alt='avatar'  src={defaultAvatar}></img>}
        </>
        }
        
      </Button>
      <input type="file"
             ref={hiddenFileInput}
             onChange={handleChange}
             style={{display:'none'}} 
      />
      </div>
 
    </>
  );
};
export default AvatarUploader;