import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
//import { store } from './../../app/store';
import { merge } from 'lodash';
import { strings } from './../../Constants';
import { doLogin, getUserData, registration, resetPassword, changePassword, deleteAccount } from './welcomeAPI';


const initialState = {
  loginData: JSON.parse(localStorage.getItem('loginData')),
  responseLogin:null,
  responseRegister: null,
  responseResetPassword: null,
  responseDeleteAccount: null,
  responseChangePassword:null,
  stauts: 'idle',
  ajaxSetup: false,
  ajaxError: null,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const doLoginAsync = createAsyncThunk(
  'welocome/doLogin',
  async (params) => {
    const {username,password} = params;
    const response = await doLogin(username, password);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);


export const changePasswordAsync = createAsyncThunk(
  'welocome/changePassword',
  async (params) => {
    const response = await changePassword(params);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);


export const deleteAccountAsync = createAsyncThunk(
  'welocome/deleteAccount',
  async (params) => {
    const response = await deleteAccount(params);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const getUserDataAsync = createAsyncThunk(
  'welocome/getUserData',
  async (params) => {
    const response = await getUserData();
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);


export const registrationAsync = createAsyncThunk(
  'welocome/registration',
  async (params) => {
    const {username,password,email} = params;
    //console.log(username, password,email);
    const response = await registration(username, password, email);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const resetPasswordAsync = createAsyncThunk(
  'welocome/resetPassword',
  async (params) => {
    const {email} = params;
   // console.log(username, password,email);
    const response = await resetPassword(email);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const welcomeSlice = createSlice({
  name: 'welcome',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    logout: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
     // delete jQuery.ajaxSettings.headers["x-custom"];
     localStorage.removeItem("loginData");

      state.loginData = null;
      state.response = null;
    },

    ajaxError: (state, action) => {
      console.log(action);
      state.ajaxError = strings.ajaxError;
      if (action?.payload){
        state.ajaxError = action.payload;
      }
    },

    ajaxOk: (state) => {
      state.ajaxError = null;
    }

  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(doLoginAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getUserDataAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(registrationAsync.pending, (state) => {
        state.status = 'loading';
      })

      .addCase(resetPasswordAsync.pending, (state) => {
        state.status = 'loading';
      })

      .addCase(changePasswordAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteAccountAsync.pending, (state) => {
        state.status = 'loading';
      })


      .addCase(doLoginAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.responseLogin = action.payload;
        //console.log(action.payload);
        if (action.payload?.respdata?.success){
          localStorage.setItem("loginData", JSON.stringify(action.payload.respdata.data));
          state.loginData = action.payload.respdata.data;
          //window.location.href="/home";
        }
      })
      .addCase(getUserDataAsync.fulfilled, (state, action) => {
        state.status = 'idle';
       // state.responseLogin = action.payload;
       // console.log(action.payload);
        if (action.payload?.respdata?.success){
          const dataRespUser = action.payload.respdata.data;
          const json = JSON.parse(localStorage.getItem("loginData"));
          const data = merge(json,dataRespUser);
          localStorage.setItem("loginData", JSON.stringify(data));
          //console.log(data);
          state.loginData = data;
          //window.location.href="/home";
        }else{
          state.loginData = null;
        }
      })
      .addCase(registrationAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.responseRegister = action.payload;
      })
      .addCase(changePasswordAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.responseChangePassword = action.payload;
      })
      .addCase(deleteAccountAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.responseDeleteAccount = action.payload;
      })
      .addCase(resetPasswordAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.responseResetPassword = action.payload;
      });
  },
});

export const { logout, ajaxError, ajaxOk } = welcomeSlice.actions;


export const selectAjaxError = (state) => state.welcome.ajaxError;
export const selectResponseLogin = (state) => state.welcome.responseLogin;
export const selectResponseRegister = (state) => state.welcome.responseRegister;
export const selectResponseChangePassword = (state) => state.welcome.responseChangePassword;
export const selectResponseDeleteAccount = (state) => state.welcome.responseDeleteAccount;
export const selectResponseResetPassword = (state) => state.welcome.responseResetPassword;
export const selectLoginData = (state) => state.welcome.loginData;



export default welcomeSlice.reducer;
