/* eslint-disable react-hooks/exhaustive-deps */
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectLoginData } from "../welcome/welcomeSlice";
import { COMMON_SX, FORM_INTERNAL_SX, strings } from "./../../Constants";
import ResponseAlertMessages from "./../commons/ResponseAlertMessages";
import {
  getDatiFisiciAsync,
  putDatiFisiciAsync,
  selectRespDatiFisici,
  selectRespSaveDatiFisici,
} from "./profileSlice";

export default function DatiFisici(props) {
  const dispatch = useDispatch();
  const loggedUser = useSelector(selectLoginData);
  const profileResponse = useSelector(selectRespDatiFisici);
  const profileSaveResponse = useSelector(selectRespSaveDatiFisici);
  const [disabled, setDisabled] = useState(true);
  const [tagliaVestiti, setTagliaVestiti] = useState(-1);
  const [tagliaPantaloni, setTagliaPantaloni] = useState(-1);
  const [altezza, setAltezza] = useState(0);
  const [numeroScarpe, setNumeroScarpe] = useState(0);
  const [peso, setPeso] = useState(0);

  useEffect(() => {
    resetFields();
    //console.log(loggedUser);
    if (loggedUser) {
      dispatch(getDatiFisiciAsync());
    }
  }, []);

  useEffect(() => {
    if (profileSaveResponse != null) {
      setDisabled(false);
      //console.log(profileSaveResponse);
      if (profileSaveResponse.respdata?.success) {
        props.onTabNext();
        /*
        const url = profileStepMap.get(loggedUser.workflow);
        dispatch(resetRespSaveDatiFisici());
        navigate(url, {replace: true});
        console.log('navigiated', url);
        */
      }
    }
  }, [profileSaveResponse]);

  const resetFields = () => {
    setDisabled(true);
    setTagliaVestiti(-1);
    setTagliaPantaloni(-1);
    setAltezza(0);
    setPeso(0);
    setNumeroScarpe(0);
  };

  useEffect(() => {
    if (profileResponse?.respdata?.success) {
      //console.log(profileResponse?.respdata)
      setDisabled(false);
      setTagliaVestiti(profileResponse?.respdata.data.tagliaVestiti);
      setTagliaPantaloni(profileResponse?.respdata.data.tagliaPantaloni);
      setAltezza(profileResponse?.respdata.data.altezza);
      setPeso(profileResponse?.respdata.data.peso);
      setNumeroScarpe(profileResponse?.respdata.data.numeroScarpe);
    }
  }, [profileResponse]);

  const onSubmit = (e) => {
    e.preventDefault();
    if (!disabled) {
      setDisabled(true);
      dispatch(
        putDatiFisiciAsync({
          tagliaVestiti,
          tagliaPantaloni,
          altezza: altezza ?  parseInt(altezza) : 0,
          peso: peso ? parseInt(peso) : 0,
          numeroScarpe: numeroScarpe ? parseInt(numeroScarpe) : 0,
        })
      );
    }
  };

  return (
    <div>
      <ResponseAlertMessages
        loadResponse={profileResponse}
        response={profileSaveResponse}
      ></ResponseAlertMessages>

      <br />
      {profileResponse?.respdata ? (
        <form component='form' sx={FORM_INTERNAL_SX} onSubmit={onSubmit}>
          <Stack
            className='muiStackFix'
            spacing={2}
            direction='column'
            sx={COMMON_SX}
          >
            <TextField
              type='number'
              placeholder='180'
              disabled={disabled}
              label={
                strings.getLanguage() !== "en" ? "Altezza (cm)" : "Height (cm)"
              }
              variant='standard'
              value={altezza}
              defaultValue={altezza}
              onChange={(event) => setAltezza(event.target.value)}
            />

            <TextField
              type='number'
              placeholder='70'
              disabled={disabled}
              label={
                strings.getLanguage() !== "en" ? "Peso (kg)" : "Weight (lbs)"
              }
              variant='standard'
              value={peso}
              defaultValue={peso}
              onChange={(event) => setPeso(event.target.value)}
            />

            <FormControl fullWidth>
              <InputLabel id='tv-utente-label' style={{ marginLeft: -15 }}>
                {strings.getLanguage() !== "en"
                  ? "Taglia vestiti"
                  : "Clothes size"}
              </InputLabel>
              <Select
                variant='standard'
                disabled={disabled}
                labelId='tv-utente-label'
                value={tagliaVestiti}
                label={
                  strings.getLanguage() !== "en"
                    ? "Taglia vestiti"
                    : "Clothes size"
                }
                onChange={(event) => setTagliaVestiti(event.target.value)}
              >
                {profileResponse?.respdata?.data?.listaTaglie?.map((o, i) => {
                  return <MenuItem value={o.chiave}>{o.valore}</MenuItem>;
                })}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel id='tv-utente-label' style={{ marginLeft: -15 }}>
                {strings.getLanguage() !== "en"
                  ? "Taglia pantaloni"
                  : "Trousers size"}
              </InputLabel>
              <Select
                variant='standard'
                disabled={disabled}
                labelId='tv-utente-label'
                value={tagliaPantaloni}
                label='Taglia pantaloni'
                onChange={(event) => setTagliaPantaloni(event.target.value)}
              >
                {profileResponse?.respdata?.data?.listaTaglie?.map((o, i) => {
                  return <MenuItem value={o.chiave}>{o.valore}</MenuItem>;
                })}
              </Select>
            </FormControl>

            <TextField
              type='number'
              placeholder='42'
              disabled={disabled}
              label={
                strings.getLanguage() !== "en"
                  ? "Numero scarpe"
                  : "Shoes number"
              }
              variant='standard'
              value={numeroScarpe}
              defaultValue={numeroScarpe}
              onChange={(event) => setNumeroScarpe(event.target.value)}
            />

            <Button type='submit' disabled={disabled} variant='contained'>
              {strings.getLanguage() !== "en" ? "Salva" : "Save"}
            </Button>
          </Stack>
        </form>
      ) : (
        <div className='loaderDiv'>
          <CircularProgress></CircularProgress>
        </div>
      )}
    </div>
  );
}
