import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { sendTimesheet, postTimesheet, putTimesheet, getTimesheets } from './timesheetAPI';

const initialState = {
    respPostTimesheet: null,
    respGetTimesheets: null,
    respPutTimesheet: null,
    respSendTimesheet: null,
    status: 'idle'
  };
  
  export const sendTimesheetAsync = createAsyncThunk(
    'timesheet/send',
    async (params) => {
      const response = await sendTimesheet(params);
      // The value we return becomes the `fulfilled` action payload
      return response;
    }
  );

  export const postTimesheetAsync = createAsyncThunk(
    'timesheet/post',
    async (params) => {
      const response = await postTimesheet(params);
      // The value we return becomes the `fulfilled` action payload
      return response;
    }
  );
  
  export const putTimesheetAsync = createAsyncThunk(
    'timesheet/put',
    async (params) => {
      const response = await putTimesheet(params);
      // The value we return becomes the `fulfilled` action payload
      return response;
    }
  );

  export const getTimesheetsAsync = createAsyncThunk(
    'timesheet/get',
    async (params) => {
      const response = await getTimesheets(params);
      // The value we return becomes the `fulfilled` action payload
      return response;
    }
  );

  export const timesheetSlice = createSlice({
    name: 'timesheet',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
     
    },
    extraReducers: (builder) => {
      builder
       
      .addCase(postTimesheetAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(postTimesheetAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.respPostTimesheet = action.payload;
      })
  
      .addCase(putTimesheetAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(putTimesheetAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.respPutTimesheet = action.payload;
      })

      .addCase(getTimesheetsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getTimesheetsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.respGetTimesheets = action.payload;
      })

      .addCase(sendTimesheetAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(sendTimesheetAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.respSendTimesheet = action.payload;
      });
    }, 
  });
  
  export const selectRespSendTimesheet = (state) => state.timesheet.respSendTimesheet;
  export const selectRespPostTimesheet = (state) => state.timesheet.respPostTimesheet;
  export const selectRespPutTimesheet = (state) => state.timesheet.respPutTimesheet;
  export const selectRespGetTimesheets = (state) => state.timesheet.respGetTimesheets;
  
  
  
  export default timesheetSlice.reducer;
  